<template>
  <div>
    <i-long-form-text>
      <h1 tabindex="0">Review Your Information</h1>
    </i-long-form-text>
    <i-fieldset style="padding-left: 15px; padding-right: 10px;">
      <i-grid container="fluid" style="padding-left: 5px; padding-right: 5px;">
        <div class="indi-col-xs-3 review_label" style="padding-left: 50px;">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-weight: lighter;">Full Legal Name :</p>
          </div>
        </div>
        <div class="indi-col-xs-9 review_value" style="padding-left: 0px;">
          <p>{{firstName}} {{lastName}} </p>
        </div>
      </i-grid>
      <i-grid container="fluid" style="padding-left: 5px; padding-right: 5px;">
        <div class="indi-col-xs-3 review_label" style="padding-left: 110px;">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-weight: lighter">Address :</p>
          </div>
        </div>
        <div class="indi-col-xs-9 review_value" style="padding-left: 0px;">
          <p>{{address1}}<br>{{address2}}<br>{{city}}, {{state}} {{zipCode}}<br>{{urbanCode}} </p>
        </div>
      </i-grid>
      <i-grid container="fluid" style="padding-left: 5px; padding-right: 5px;">
      <div class="indi-col-xs-3 review_label" style="padding-left:80px;">
        <div class="indi-long-form-text">
          <p class="indi-long-form-text__p" style="font-weight: lighter">Date of Birth :</p>
        </div>
      </div>
      <div class="indi-col-xs-9 review_value" style="padding-left: 0px;">
        <p>{{dob}}</p>
      </div>
    </i-grid>
      <i-grid container="fluid" style="padding-left: 5px; padding-right: 5px;">
        <div class="indi-col-xs-3 review_label" style="padding-left: 40px;">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-weight: lighter">Last 4 digits of SSN :</p>
          </div>
        </div>
        <div class="indi-col-xs-9 review_value" style="padding-left: 0px;">
          <p  v-if="this.l4ssn !== ''">{{l4ssn}}</p>
          <p  v-else>Not provided</p>
        </div>
      </i-grid>
      <i-grid container="fluid" style="padding-left: 5px; padding-right: 5px;">
        <div class="indi-col-xs-3 review_label" style="padding-left: 125px;">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-weight: lighter">Email :</p>
          </div>
        </div>
        <div class="indi-col-xs-9 review_value" style="padding-left: 0px;">
          <p>{{email}}</p>
        </div>
      </i-grid>
      <i-grid container="fluid" style="padding-left: 5px; padding-right: 5px;">
        <div class="indi-col-xs-3 review_label" style="padding-left: 35px;">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-weight: lighter">Security Question 1 :</p>
          </div>
        </div>
        <div class="indi-col-xs-9 review_value"  style="padding-left: 0px;">
          <p>{{securityQ1}}</p>
        </div>
      </i-grid>
      <i-grid container="fluid" style="padding-left: 5px; padding-right: 5px;">
        <div class="indi-col-xs-3 review_label" style="padding-left: 80px;">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-weight: lighter">Your Answer :</p>
          </div>
        </div>
        <div class="indi-col-xs-9 review_value" style="padding-left: 0px;">
          <p>{{securityA1}}</p>
        </div>
      </i-grid>
      <i-grid container="fluid" style="padding-left: 5px; padding-right: 5px;">
        <div class="indi-col-xs-3 review_label" style="padding-left: 35px;">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-weight: lighter">Security Question 2 :</p>
          </div>
        </div>
        <div class="indi-col-xs-9 review_value" style="padding-left: 0px;">
          <p>{{securityQ2}}</p>
        </div>
      </i-grid>
      <i-grid container="fluid" style="padding-left: 5px; padding-right: 5px;">
        <div class="indi-col-xs-3 review_label" style="padding-left: 80px;">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-weight: lighter">Your Answer :</p>
          </div>
        </div>
        <div class="indi-col-xs-9 review_value" style="padding-left: 0px;">
          <p>{{securityA2}}</p>
        </div>
      </i-grid>
      <i-grid container="fluid" style="padding-left: 5px; padding-right: 5px;">
        <div class="indi-col-xs-3 review_label" style="padding-left: 35px;">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-weight: lighter">Security Question 3 :</p>
          </div>
        </div>
        <div class="indi-col-xs-9 review_value" style="padding-left: 0px;">
          <p>{{securityQ3}}</p>
        </div>
      </i-grid>
      <i-grid container="fluid" style="padding-left: 5px; padding-right: 5px;">
        <div class="indi-col-xs-3 review_label" style="padding-left: 80px;">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-weight: lighter">Your Answer :</p>
          </div>
        </div>
        <div class="indi-col-xs-9 review_value" style="padding-left: 0px;">
          <p>{{securityA3}}</p>
        </div>
      </i-grid>
    </i-fieldset>
    <i-grid>
      <i-button class="indi-col-xs-offset-9" label="Edit"  variant="tertiary" @click="$router.go(-2)"/>
      <i-button class="indi-col-xs-offset-3" label="Submit"  variant="primary" @click="submitRepresentative"/>
    </i-grid>
    <i-loader variant="overlay" v-if="loading"/>
  </div>
</template>
<script>
  import router from '../router'
  import {HTTP} from '../http-common'
  import {
    ILongFormText, IFieldset, IInputGroup,IInput,IGrid, ILabel,ILoader, IButton
  } from 'indigo-component-library';
  export default {
    name: "ReviewRegistration",

    created: function () {
      this.loading = false;
    },
    data: function () {
      return {
        showReview:false,
        loading: false,
        state: '',
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        zipCode: '',
        urbanCode: '',
        dob: '',
        l4ssn:'',
        email:'',
        securityQ1: '',
        securityQ2: '',
        securityQ3: '',
        securityA1: '',
        securityA2: '',
        securityA3: '',
        monthNames : ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ],
        questions :[]
      }
    },
    components: {
      ILongFormText, IFieldset, IInputGroup,IInput,IGrid, ILabel,ILoader, IButton
    },
    methods: {
      submitRepresentative() {
        this.loading = true;
        let rc = this.$router.currentRoute.query.rc
        //let data = JSON.parse(sessionStorage.getItem(rc))
        HTTP.post('/rad-svc/representative/register', sessionStorage.getItem(rc)).then(response => {
          if( response.data.status === 400 ) {
              this.loading = false;
              sessionStorage.setItem('errorCode', response.data.code);
              router.go(-2);
          } else if( response.data.code === 'SUBMITTED_EMAIL' ) {
            this.loading = false;
            router.push({ name: "verify",  query:{re: response.data.re, rc: response.data.rc  }, params :{ isRouted: true}});
           } else if( response.data.code === 'EMAIL_GENERATION_SUCCESS' ) {
            this.loading = false;
            router.push({ name: "success",  query:{re: response.data.re, rc: response.data.rc  }, params :{ isRouted: true, annualAgreement: false}});
           } else {
              this.loading = false;
              console.log('Got Error while : '+window.location.href);
              router.push({name: "error"});
          }
            if( response.data.status === 200 ) {
                sessionStorage.clear();
            }
        }).catch(err => {
          this.loading = false;
          console.log('Got Error while : '+window.location.href);
            console.log(err);
          router.push({name: "error"});
          sessionStorage.clear();
        });
        this.$gtm.trackEvent({category: 'SubmitRepresentative', action: 'click', label: 'Registration Form Submit Representative'});
      }
    },
    beforeMount() {
      if( sessionStorage.length === 0  ) {
        HTTP.get('/rad-svc/representative/verify',{params :{ rc:this.$router.currentRoute.query.rc, re: this.$router.currentRoute.query.re}}
        ).then(response => {
          if ( response.data.pass === false && response.code === 'EMAIL_GENERATION_SUCCESS' ) {
            router.push({ name: "success",  query:{re: response.data.re, rc: response.data.rc  }, params :{ isRouted: true}});
          } else {
            router.push({ name: "verify",  query:{re: response.data.re, rc: response.data.rc }});
          }
        }).catch(err => {
            console.log('Got Error while : '+window.location.href);
          router.push({name: "error"});
        });
      }
      let rc = this.$router.currentRoute.query.rc
      let data = JSON.parse(sessionStorage.getItem(rc))
      if( data ) {
        this.showReview = true
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.address1 = data.address1
        this.address2 = data.address2
        this.urbanCode = data.urbanizationCode
        this.state = data.state
        this.city = data.city
        this.zipCode = data.zipCode
        let d = new Date(data.dob);
        this.dob = this.monthNames[d.getMonth()] +' '+d.getDate()+', '+ d.getFullYear()
        this.l4ssn = data.l4ssn
        this.email = data.email
        this.questions = JSON.parse(sessionStorage.allQuestions)
        this.securityQ1 = (this.questions.filter(function(jsonObject) {return (jsonObject['id'] == data.question_answer[0].qid)})[0]).question
        this.securityQ2 = (this.questions.filter(function(jsonObject) {return (jsonObject['id'] == data.question_answer[1].qid)})[0]).question
        this.securityQ3 = (this.questions.filter(function(jsonObject) {return (jsonObject['id'] == data.question_answer[2].qid)})[0]).question
        this.securityA1 = data.question_answer[0].answer
        this.securityA2 = data.question_answer[1].answer
        this.securityA3 = data.question_answer[2].answer
      }
    },
    mounted () {
    	this.$gtm.trackView('ReviewRegistrationForm');
        document.title = "Review Registration Information - Representative Accountability Database (RAD)";
    }
  }
</script>
