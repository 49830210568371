<template>
  <div class="indi-long-form-text">
  <div v-show="isSuccessDeactivateId">
    <i-long-form-text>
      <h1 tabindex="0">Success!</h1>
      <br><br>
      <i-notification tabindex="0" variant="success" message="You have successfully deactivated your Representative ID."/>
      <i-fieldset>
        <p class="indi-long-form-text">You have successfully deactivated your Representative ID. If you need a Representative ID in the future, you will be required to complete a new RAD registration.
          Confirmation will also be sent to your RAD email address. You may now close out this window.</p>
      </i-fieldset>
    </i-long-form-text>
  </div>
  <div v-show="!isSuccessDeactivateId">
        <i-long-form-text>
            <h1 tabindex="0">Update Information or Deactivate ID</h1>
        </i-long-form-text>
       <div v-show="!showDeactiveError">
        <i-notification variant="success" message="Your Identity Verification is complete." />
       </div>
        <p class="indi-long-form-text__p--small" tabindex="0">You can update your email address, address, or security questions. To submit new information, select what you want to update and enter your new information in the fields provided. You can update one or more of these items, it is not necessary to update all three. You can also deactivate your Representative ID.</p>
      <div v-show="showError">
        <i-notification variant="error" >Attention: you cannot proceed if you do not update your information. If you don't need to update your information, you can close this window.</i-notification>
      </div>
      <div v-show="showDeactiveError">
        <i-notification variant="error" >The Representative ID cannot be deactivated because it is locked.</i-notification>
      </div>
        <div>
          <i-radio-button-group>
            <i-radio-button id="updateInfoOption" label="Update Information" name="update-info-group" value="true" checked :checked="showUpdateInfo === 'true'" @change="showUpdateInfoE()"/>
            <i-radio-button id="deactivateIdOption" label="Deactivate ID" name="update-info-group" value="false" :checked="showUpdateInfo === 'false'" @change="showUpdateInfoE()"/>
          </i-radio-button-group>
        </div>
        <div v-if="showUpdateInfo === 'true'">
        <i-accordion variant="faq" heading="Address">
            <i-grid>
                <i-input-group label="Street Address" label-for="Street Address" class="indi-col-lg-8">
                    <i-input id="address1" name="address1" type="text" v-model="streetInput" aria-label="Street Address" v-on:input="onChange"
                             :invalid="errors.has('address1')" v-validate="{required: this.addressProvided,max:50 }" maxlength="50" />
                    <i-input-notification v-bind:text="errors.first('address1')"  aria-live="assertive" aria-atomic="true"/>
                </i-input-group>
                <i-input-group class="indi-col-lg-4">
                    <i-label text="Apt., Unit, etc." label-for="Appartment number, Unit, etc." optional tabindex="0"/>
                    <i-input id="address2" name="address2" type="text" v-model="aptUnitInput" aria-label="Appartment number, Unit, etc." v-on:input="onChange"
                             :invalid="errors.has('address2')" v-validate="{max:50 }" maxlength="50"/>
                    <i-input-notification v-bind:text="errors.first('address2')"  aria-live="assertive" aria-atomic="true"/>
                </i-input-group>
            </i-grid>
            <i-grid>
                <i-input-group label="City" label-for="City" class="indi-col-lg-4">
                    <i-input id="city" name="city" type="text" v-model="cityInput" aria-label="City" v-on:input="onChange"
                             :invalid="errors.has('city')" v-validate="{ required: this.addressProvided,max:50}" maxlength="50"/>
                    <i-input-notification v-bind:text="errors.first('city')" aria-live="assertive" aria-atomic="true"/>
                </i-input-group>
                <i-input-group label="State" label-for="State" class="indi-col-lg-4" required>
                    <i-select v-bind:options="states" v-on:input="onChange" v-model="stateSelect" id="state" name="state" aria-label="State" v-validate="{required: addressProvided}" :invalid="errors.has('state')"/>
                    <i-input-notification v-bind:text="errors.first('state')"  aria-live="assertive" aria-atomic="true"/>
                </i-input-group>
                <!-- Zip Code -->
                <i-input-group label="Zip Code" label-for="Zip Code" class="indi-col-lg-4" required>
                    <i-input id="zipCode" name="zipCode" type="text" v-model="zipCodeInput" aria-label="Zip Code" v-on:input="onChange"
                             :invalid="errors.has('zipCode')" v-validate="{required: this.addressProvided,regex:/^(\d{5}(-\d{4})?)$/}" v-mask="'#####-####'" />
                    <i-input-notification v-bind:text="errors.first('zipCode')"  aria-live="assertive" aria-atomic="true"/>
                </i-input-group>
            </i-grid>
            <!-- Urbanization Code -->
            <i-grid v-if="isPrState">
                <i-input-group class="indi-col-lg-5" tabindex="0">
                    <i-label text="Urbanization Code" label-for="Urbanization Code" optional/>
                    <i-input id="urbanizationCode" name="urbanizationCode" optional type="text" v-model="urbanCodeInput" aria-label="Urbanization Code" v-on:input="onChange"
                             :invalid="errors.has('urbanizationCode')"  v-validate="{regex:  /^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.'`-s]*$/,max:50 }" maxlength="50" />
                    <i-input-notification v-bind:text="errors.first('urbanizationCode')"  aria-live="assertive" aria-atomic="true"/>
                </i-input-group>
            </i-grid>

        </i-accordion>
        <i-accordion variant="faq" heading="Email">
            <i-grid>
                <i-input-group label="Email" label-for="Email" class="indi-col-lg-8">
                    <i-input id="email" name="email" type="email" v-model="emailInput" aria-label="Email" v-on:input="onChange"
                             :invalid="errors.has('email')"  v-validate="{required: this.emailProvided, regex: /^[A-Z-a-z0-9._%-+]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/, max:240 }" maxlength="240" />
                    <i-help-text text="Ex. name@email.com" />
                    <i-input-notification v-bind:text="errors.first('email')"  aria-live="assertive" aria-atomic="true"/>
                </i-input-group>
            </i-grid>

        </i-accordion>
        <i-accordion variant="faq" heading="Security Questions">
            <i-input-group label="Security Question 1" label-for="Security Question 1" class="indi-col-lg-4">
                <i-select v-bind:options="questions1" :invalid="errors.has('securityQ1')" id="securityQ1" name="securityQ1" v-model="securityQ1Select" v-validate="{required: this.securityQuestionsProvided}"
                          v-on:input="onQuestionChange(),onChange()" aria-label="Security Question 1" />
                <i-input-notification v-bind:text="errors.first('securityQ1')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
            <i-input-group label="Your Answer to Security Question 1" label-for="Your Answer to Security Question 1" class="indi-col-lg-4">
                <i-input ype="text" id="securityA1" name="securityA1" v-model="securityA1Input" v-validate="{required: this.securityQuestionsProvided,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}" v-on:input="onChange"
                         aria-label="Your Answer to Security Question 1" :invalid="errors.has('securityA1')" />
                <i-input-notification v-bind:text="errors.first('securityA1')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
            <i-input-group label="Security Question 2" label-for="Security Question 2" class="indi-col-lg-4">
                 <i-select v-bind:options="questions2" :invalid="errors.has('securityQ2')" id="securityQ2" name="securityQ2" v-model="securityQ2Select" v-validate="{required: this.securityQuestionsProvided}"
                          v-on:input="onQuestionChange(),onChange()" aria-label="Security Question 2" />
                 <i-input-notification v-bind:text="errors.first('securityQ2')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
            <i-input-group label="Your Answer to Security Question 2" label-for="Your Answer to Security Question 2" class="indi-col-lg-4" >
                 <i-input ype="text" id="securityA2" name="securityA2" v-model="securityA2Input" v-validate="{required: this.securityQuestionsProvided,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}" v-on:input="onChange"
                          aria-label="Your Answer to Security Question 2" :invalid="errors.has('securityA2')" />
                 <i-input-notification v-bind:text="errors.first('securityA2')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
            <i-input-group label="Security Question 3" label-for="Security Question 3" class="indi-col-lg-4">
                 <i-select v-bind:options="questions3" :invalid="errors.has('securityQ3')" id="securityQ3" name="securityQ3" v-model="securityQ3Select" v-validate="{required: this.securityQuestionsProvided}"
                           v-on:input="onQuestionChange(),onChange()" aria-label="Security Question 3" />
                 <i-input-notification v-bind:text="errors.first('securityQ3')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
            <i-input-group label="Your Answer to Security Question 3" label-for="Your Answer to Security Question 3" class="indi-col-lg-4">
                 <i-input ype="text" id="securityA3" name="securityA3" v-model="securityA3Input" v-validate="{required: this.securityQuestionsProvided,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}" v-on:input="onChange"
                         aria-label="Your Answer to Security Question 3" :invalid="errors.has('securityA3')" />
                 <i-input-notification v-bind:text="errors.first('securityA3')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
        </i-accordion>
        </div>
        <br>
        <i-modal header="Deactivate Representative" tertiary-button-label="Cancel"  primary-button-label="Deactivate"
                 body="Please confirm Deactivation. Deactivating your Representative ID will remove access to the National Verifier. You must re-register if you need an ID in the future."
                 ref="deactivateModal"
                 aria-label="Close Modal" v-on:on-primary-button-click="deactivateRepresentative('confirmedDeactivate')">
        </i-modal>
        <i-grid>
            <i-button v-if="showUpdateInfo === 'true'" class="indi-col-xs-offset-10 right-side-button" label="Next"  variant="primary" @click="submitReview"/>
            <i-button v-else class="indi-col-xs-offset-10 right-side-button" label="Deactivate" :disabled="deactiveDisabled==='true'" @click="deactivateRepresentative('deactivate')"/>
        </i-grid>
        <i-loader variant="overlay" v-if="loading" />
    </div>
  </div>
</template>
<script>
    import {
        ILongFormText, IFieldset, IInputGroup,IInput,IGrid, ILabel, IRadioButtonGroup, IRadioButton,
        IButton,IInputNotification, IHelpText,ILoader, IAccordion, INotification, ISelect, IModal } from 'indigo-component-library';
    import axios from 'axios'
    import router from '../router'
    import Recaptcha from "./Recaptcha"
    import {HTTP} from '../http-common'
    import { EventBus } from "../event-bus.js";
    import {mask} from 'vue-the-mask';
    export default {
        data: function () {
            return {
                loading : false,
                isPrState: false,
                states: [],
                stateSelect: null,
                streetInput: '',
                aptUnitInput: '',
                cityInput: '',
                zipCodeInput: '',
                urbanCodeInput: '',
                emailInput: '',
                inputError: '',
                email:'',
                allQuestions:[],
                questions1: [],
                questions2: [],
                questions3: [],
                selectedQuestions: {
                    1: '',
                    2: '',
                    3: ''
                },
                securityQ1Select: '',
                securityQ2Select: '',
                securityQ3Select: '',
                securityQ1: '',
                securityQ2:'',
                securityQ3:'',
                securityA1: '',
                securityA2:'',
                securityA3:'',
                securityA1Input: '',
                securityA2Input:'',
                securityA3Input:'',
                showError : false,
                repId:'',
                addressProvided : true,
                emailProvided : true,
                securityQuestionsProvided : true,
                isModalVisible:false,
                repStatus : '',
                showUpdateInfo : 'true',
                showDeactiveError: false,
                deactiveDisabled : 'false',
                isSuccessDeactivateId: false,
            }
        },
        name: "UpdateInformationForm",
        components: {
            Recaptcha, ILoader, ILabel, IRadioButtonGroup, IRadioButton,
            ILongFormText, IFieldset, IInputGroup,IInput,IGrid,
            IButton,IInputNotification, IHelpText, INotification, IAccordion, ISelect, IModal
        },
        directives: {mask},

        beforeMount() {
            HTTP.get('/rad-svc/representative/securityQuestion').then(response => {
                this.allQuestions = Object.assign(response.data, this.allQuestions);
                this.allQuestions.unshift({id: "-1", question: "", label: "Select one", value:""})
                this.questions1 = this.allQuestions;
                this.questions2 = this.allQuestions;
                this.questions3 = this.allQuestions;
            })
            HTTP.get('/rad-svc/representative/states').then(response => {

                this.states = Object.assign(response.data, this.states)
                this.states.unshift({id: "", name: "", label: ""})
            })

        },
        mounted() {
            if (!this.$route.params.isRouted) {
                this.loading = true;
                HTTP.get('/rad-svc/representative/verifyUpdateInformation', {
                        params: {
                            rc: this.$router.currentRoute.query.rc,
                            re: this.$router.currentRoute.query.re
                        }
                    }
                ).then(response => {
                    this.loading = false
                    if (response.data.pass === false) {
                        router.push({
                            name: "verifyUpdateInformation",
                            query: {rc: this.$router.currentRoute.query.rc, re: this.$router.currentRoute.query.re}
                        });
                    } else {
                        this.repId = response.data.repId;
                        this.email = response.data.email;
                        this.repStatus = response.data.repStatus;
                        EventBus.$emit('email-updated', response.data.email );
                    }
                }).catch(err => {
                    this.loading = false;
                    console.log('Got Error while : '+window.location.href);
                    router.push({name: "error"});
                });
            } else{
                this.repId = this.$route.params.repId;
                this.email = this.$route.params.email;
                EventBus.$emit('email-updated', this.$route.params.email );
            }

            let rc = this.$router.currentRoute.query.rc
            if (sessionStorage.getItem(rc)) {
                let data = JSON.parse(sessionStorage.getItem(rc));

                if (data.address1) {
                    this.streetInput = data.address1
                }
                if (data.address2) {
                    this.aptUnitInput = data.address2
                }
                if (data.state) {
                    this.stateSelect = data.state
                }
                if (data.city) {
                    this.cityInput = data.city
                }
                if (data.zipCode) {
                    this.zipCodeInput = data.zipCode
                }
                if( this.stateSelect === 'PR') {
                    this.isPrState = true;
                    this.urbanCodeInput = data.urbanizationCode
                }

                if (data.emailInput) {
                    this.emailInput = data.emailInput
                }
                if( data.question_answer !== undefined) {
                    if (data.question_answer[0]) {
                        this.securityQ1Select = data.question_answer[0].qid
                        this.securityA1Input = data.question_answer[0].answer
                    }
                    if (data.question_answer[1]) {
                        this.securityQ2Select = data.question_answer[1].qid
                        this.securityA2Input = data.question_answer[1].answer
                    }
                    if (data.question_answer[2]) {
                        this.securityQ3Select = data.question_answer[2].qid
                        this.securityA3Input = data.question_answer[2].answer
                    }
                }
                console.log(data.repId);
            }

            if( this.streetInput === '' && this.aptUnitInput === '' && this.cityInput === '' && (this.stateSelect == null || this.stateSelect === '') && this.zipCodeInput === ''){
                this.addressProvided = false;
            } else{
                this.addressProvided = true;
            }

            if(this.emailInput === ''){
                this.emailProvided = false;
            } else{
                this.emailProvided = true;
            }
            if(this.securityQ1Select === ''  && this.securityA1Input === '' && this.securityQ2Select === '' && this.securityA2Input === ''
                && this.securityQ3Select === '' && this.securityA3Input === '')  {
                this.securityQuestionsProvided = false;
            } else{
                this.securityQuestionsProvided = true;
            }

            sessionStorage.clear();
        },
        computed: {
          showUpdateInfo: {
            get() {
              this.showDeactiveError = false;
              this.showError = false;
              return this.showUpdateInfo === 'true' ? 'false': 'true';
            }
          },
        },
        methods: {
            onChange(val) {
                this.isPrState = (this.stateSelect === 'PR') ? true :false;

                if( this.streetInput === '' && this.aptUnitInput === '' && this.cityInput === '' && (this.stateSelect == null || this.stateSelect === '') && this.zipCodeInput === ''){
                    this.addressProvided = false;
                } else{
                    this.addressProvided = true;
                }

                if(this.emailInput === ''){
                    this.emailProvided = false;
                } else{
                    this.emailProvided = true;
                }
                if(this.securityQ1Select === ''  && this.securityA1Input === '' && this.securityQ2Select === '' && this.securityA2Input === ''
                    && this.securityQ3Select === '' && this.securityA3Input === '')  {
                    this.securityQuestionsProvided = false;
                } else{
                    this.securityQuestionsProvided = true;
                }
                this.showDeactiveError = false;
                this.showError = false;

            },
            showUpdateInfoE() {

              if(this.showUpdateInfo === 'false') {
                  this.showUpdateInfo = 'true';
                  this.showDeactiveError = false;
              }else {
                if ("LOCKED" === this.repStatus) {
                  this.showDeactiveError = true;
                  this.deactiveDisabled = 'true';
                }
                this.showUpdateInfo = 'false';
                this.showError = false;
              }
            },

            onQuestionChange() {
                this.questions1 = this.allQuestions.filter( question => question.id != this.securityQ2Select);
                this.questions1 = this.questions1.filter( question => question.id != this.securityQ3Select);

                this.questions2 = this.allQuestions.filter( question => question.id != this.securityQ1Select);
                this.questions2 = this.questions2.filter( question => question.id != this.securityQ3Select);

                this.questions3 = this.allQuestions.filter( question => question.id != this.securityQ2Select);
                this.questions3 = this.questions3.filter( question => question.id != this.securityQ1Select);
            },


            submitReview() {
                if (this.showUpdateInfo === 'false') {
                  let rc = this.$router.currentRoute.query.rc;
                  sessionStorage.setItem(rc, JSON.stringify(params));
                  sessionStorage.allQuestions = JSON.stringify(this.allQuestions);

                  router.push({
                    name: "updateInformationReview",
                    query: {rc: this.$router.currentRoute.query.rc, re: this.$router.currentRoute.query.re}
                  });
                  EventBus.$emit('email-updated', this.email);
                } else {
                  if (this.streetInput === '' && this.aptUnitInput === '' && this.cityInput === '' && (this.stateSelect == null || this.stateSelect === '') && this.zipCodeInput === '') {
                    this.addressProvided = false;
                  }

                  if (this.emailInput === '') {
                    this.emailProvided = false;
                  }
                  if (this.securityQ1Select === '' && this.securityA1Input === '' && this.securityQ2Select === '' && this.securityA2Input === ''
                      && this.securityQ3Select === '' && this.securityA3Input === '') {
                    this.securityQuestionsProvided = false;
                  }

                  if (!this.addressProvided && !this.emailProvided && !this.securityQuestionsProvided) {
                    this.showError = true;
                    this.loading = false;

                  } else {
                    this.showError = false;
                    this.$validator.errors.clear();
                    this.$validator.validateAll().then((result) => {
                      if (result) {
                        let params = {
                          rc: this.$router.currentRoute.query.rc,
                          re: this.$router.currentRoute.query.re,
                          repId: this.repId,
                          address1: this.streetInput,
                          address2: this.aptUnitInput,
                          city: this.cityInput,
                          state: this.stateSelect,
                          zipCode: this.zipCodeInput,
                          urbanizationCode: this.stateSelect === 'PR' ? this.urbanCodeInput : '',
                          emailInput: this.emailInput,
                          signEmail: this.email,
                        };

                        if (this.securityQuestionsProvided) {
                          params.question_answer =
                              [
                                {
                                  qnum: 1,
                                  qid: this.securityQ1Select,
                                  answer: this.securityA1Input
                                },
                                {
                                  qnum: 2,
                                  qid: this.securityQ2Select,
                                  answer: this.securityA2Input
                                },
                                {
                                  qnum: 3,
                                  qid: this.securityQ3Select,
                                  answer: this.securityA3Input
                                }];
                        }

                        let rc = this.$router.currentRoute.query.rc;
                        sessionStorage.setItem(rc, JSON.stringify(params));
                        sessionStorage.allQuestions = JSON.stringify(this.allQuestions);

                        router.push({
                          name: "updateInformationReview",
                          query: {rc: this.$router.currentRoute.query.rc, re: this.$router.currentRoute.query.re}
                        });
                        EventBus.$emit('email-updated', this.email);


                      }
                    });
                  }
                }

            },
            deactivateRepresentative(action){
              if(action!=='confirmedDeactivate'){
                if ("LOCKED" === this.repStatus) {
                  this.showDeactiveError = true;
                  this.$parent.loading = false;
                  this.deactiveDisabled = 'true';
                } else {
                  this.$refs.deactivateModal.toggleOpen();
                }
              } else {
                axios({
                  method: 'post',
                  url: '/rad-svc/representative/deactivateByRepAgent',
                  params: {
                    'rc': this.$router.currentRoute.query.rc,
                    're': this.$router.currentRoute.query.re
                  }
                }).then(response => {
                  if (response.data.status === 200) {
                    if (response.data.code === 'SUCCESSFUL_DEACTIVATED_REPRESENTATIVE') {
                      this.$parent.loading = false;
                      this.isSuccessDeactivateId = true;
                      this.successMessage = 'Representative ID ' + this.representativeId + ' is successfully deactivated.'
                      document.title = "Deactivate Rep Id - Success"
                      EventBus.$emit('email-updated', this.email );
                      sessionStorage.clear();
                    }
                  } else {
                    this.failureMessage = 'Representative ID ' + this.representativeId + ' has transaction errors, please try after some time.';
                    this.$parent.loading = false;
                    console.log('Got Error while : '+window.location.href);
                    router.push({name: "error"});
                  }
                }).catch(err => {
                  this.$parent.loading = false;
                  console.log('Got Error while : ' + window.location.href);
                  router.push({name: "error"});
                });
              }
            },

        }
    }
</script>

