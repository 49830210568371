<template>
    <div>
        <div v-show="load">
        <i-fieldset >
            <i-grid container="fluid">
                <div class="indi-col-xs-4" >
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Representative ID :</p>
                    </div>
                </div>
                <div class="indi-col-xs-8">
                    <p style="font-size:18px"><b>{{representativeId}}</b></p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-4">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Status :</p>
                    </div>
                </div>
                <div class="indi-col-xs-8">
                    <p style="font-size:18px"><b>{{repStatus}}</b></p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-4">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Annual Agreement Signed :</p>
                    </div>
                </div>
                <div class="indi-col-xs-8">
                    <p style="font-size:18px"><b>{{annualAgreementSigned}}</b></p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-4">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Annual Agreement Renewal :</p>
                    </div>
                </div>
                <div class="indi-col-xs-8">
                    <p style="font-size:18px"><b>{{annualAgreementRenewal}}</b></p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-4">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Full Legal Name :</p>
                    </div>
                </div>
                <div class="indi-col-xs-8" >
                    <p style="font-size:18px"><b>{{legalName}}</b></p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-4">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Date of Birth :</p>
                    </div>
                </div>
                <div class="indi-col-xs-8">
                    <p style="font-size:18px"><b>{{dob}}</b></p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-4" >
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Last 4 Numbers of SSN :</p>
                    </div>
                </div>
                <div class="indi-col-xs-8" >
                    <p style="font-size:18px"><b>{{l4ssn}}</b></p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-4" >
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Address :</p>
                    </div>
                </div>
                <div class="indi-col-xs-8" >
                    <p style="font-size:18px"><b>{{address1}} {{address2}}<br/> {{city}}, {{state}} {{zipCode}}</b></p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-4" >
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Email :</p>
                    </div>
                </div>
                <div class="indi-col-xs-8" >
                    <p style="font-size:18px" v-if="email !==''"><b>{{email}}</b></p>
                    <p style="font-size:18px" v-else><b>Not provided</b></p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-long-form-text indi-col-xs-4" >
                    <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Duplicate ID(s) :</p>
                </div>
                <div class="indi-col-xs-8" >
                    <p style="font-size:18px"   v-if="duplicate_rep !==''"><b>{{duplicate_rep}}</b></p>
                    <p style="font-size:18px" v-else></p>
                </div>
            </i-grid>
            <i-grid>
              <div class="indi-long-form-text indi-col-xs-4" >
                <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Linked Account(s) Information :</p>
              </div>
              <div class="indi-col-xs-8" >
                <i-button  label="View Linked Account(s) Information" id="showSpinBtn"  @click="toggleRefIdModalOpen()" variant="tertiary"></i-button>
              </div>
            </i-grid>
            <div v-for="(failureType, index) in this.failureTypes">
                <i-grid container="fluid">
                    <div class="indi-long-form-text indi-col-xs-4" >
                        <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Failure Type {{ failureTypes.length > 1 ? '#'+(index+1): ''}}:</p>
                    </div>
                    <div class="indi-col-xs-8" >
                        <p style="font-size:18px"><b>{{failureType.name}}</b></p>
                    </div>
                </i-grid>
                <i-grid>
                    <div class="indi-long-form-text indi-col-xs-4" >
                        <p class="indi-long-form-text__p" style="padding-top: 15px;font-size:18px;text-align: right">Type of Documentation :</p>
                    </div>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'TPIV_FAIL_IDENTITY_NOT_FOUND'">
                        <i-multi-select v-if="!$parent.loading" :disabled="!isAdmin" v-model="identityFailureTypeValue"  placeholder="Select All that Apply" v-bind:options="failureType.resolutions" @input="onTouchIdentityFailureTypes"></i-multi-select>
                        <i-input-notification v-show="identityFailureTypeHasErrors" text="Missing required field."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'TPIV_FAIL_DECEASED'">
                        <i-multi-select v-if="!$parent.loading" :disabled="!isAdmin" v-model="deceasedFailureTypeValue" placeholder="Select All that Apply" v-bind:options="failureType.resolutions" @input="onTouchDeceasedFailureTypes"></i-multi-select>
                        <i-input-notification v-show="deceasedFailureTypeHasErrors" text="Missing required field."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'TPIV_FAIL_NAME_SSN4'">
                        <i-multi-select v-if="!$parent.loading" v-model="ssnFailureTypeValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="failureType.resolutions" @input="onTouchSSNFailureTypes"></i-multi-select>
                        <i-input-notification v-show="ssnFailureTypeHasErrors" text="Missing required field."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'DUPLICATE'">
                        <i-multi-select v-if="!$parent.loading" v-model="duplicateFailureTypeValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="failureType.resolutions" @input="onTouchDuplicateFailureTypes"></i-multi-select>
                        <i-input-notification v-show="duplicateFailureTypeHasErrors" text="Missing required field."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'TPIV_FAIL_DOB'">
                        <i-multi-select v-if="!$parent.loading" v-model="dobFailureTypeValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="failureType.resolutions" @input="onTouchDobFailureTypes"></i-multi-select>
                        <i-input-notification v-show="dobFailureTypeHasErrors" text="Missing required field."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'NOSSN4_INTL_FAIL'">
                        <i-multi-select v-if="!$parent.loading" v-model="ssn4OptionalIntFailureTypeValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="failureType.resolutions" @input="onTouchSsn4OptionalIntFailureTypes"></i-multi-select>
                        <i-input-notification v-show="ssn4OptionalIntFailureTypeHasErrors" text="Missing required field."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'NOSSN4_DUP_INTL_FAIL'">
                        <i-multi-select v-if="!$parent.loading" v-model="ssn4OptionalDupIntFailureTypeValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="failureType.resolutions" @input="onTouchSsn4OptionalDupIntFailureTypes"></i-multi-select>
                        <i-input-notification v-show="ssn4OptionalDupIntFailureTypeHasErrors" text="Missing required field."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'NOSSN4_US_FAIL'">
                        <i-multi-select v-if="!$parent.loading" v-model="ssn4OptionalUsaFailureTypeValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="failureType.resolutions" @input="onTouchSsn4OptionalUsaFailureTypes"></i-multi-select>
                        <i-input-notification v-show="ssn4OptionalUsaFailureTypeHasErrors" text="Missing required field."/>
                    </i-input-group>
                </i-grid>
                <i-grid>
                    <div class="indi-long-form-text indi-col-xs-4" >
                        <p class="indi-long-form-text__p" style="padding-top: 15px;font-size:18px;text-align: right">Reject Reason:</p>
                    </div>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'TPIV_FAIL_IDENTITY_NOT_FOUND'">
                        <i-multi-select v-if="!$parent.loading" :disabled="!isAdmin" v-model="identityRejectReasonValue"  placeholder="Select All that Apply" v-bind:options="rejectReasonsOptions" @input="onTouchIdentityRejectReasons"></i-multi-select>
                        <i-input-notification v-show="identityRejectReasonHasErrors" text="Missing required field."/>
                        <i-input-notification v-show="identityRejectReasonApproveErrors" text="Cannot select a reject reason when approving a Representative ID."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'TPIV_FAIL_DECEASED'">
                        <i-multi-select v-if="!$parent.loading" :disabled="!isAdmin" v-model="deceasedRejectReasonValue" placeholder="Select All that Apply" v-bind:options="rejectReasonsOptions" @input="onTouchDeceasedRejectReasons"></i-multi-select>
                        <i-input-notification v-show="deceasedRejectReasonHasErrors" text="Missing required field."/>
                        <i-input-notification v-show="deceasedRejectReasonApproveErrors" text="Cannot select a reject reason when approving a Representative ID."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'TPIV_FAIL_NAME_SSN4'">
                        <i-multi-select v-if="!$parent.loading" v-model="ssnRejectReasonValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="rejectReasonsOptions" @input="onTouchSSNRejectReasons"></i-multi-select>
                        <i-input-notification v-show="ssnRejectReasonHasErrors" text="Missing required field."/>
                        <i-input-notification v-show="ssnRejectReasonApproveErrors" text="Cannot select a reject reason when approving a Representative ID."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'DUPLICATE'">
                        <i-multi-select v-if="!$parent.loading" v-model="duplicateRejectReasonValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="rejectReasonsOptions" @input="onTouchDuplicateRejectReasons"></i-multi-select>
                        <i-input-notification v-show="duplicateRejectReasonHasErrors" text="Missing required field."/>
                        <i-input-notification v-show="duplicateRejectReasonApproveErrors" text="Cannot select a reject reason when approving a Representative ID."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'TPIV_FAIL_DOB'">
                        <i-multi-select v-if="!$parent.loading" v-model="dobRejectReasonValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="rejectReasonsOptions" @input="onTouchDobRejectReasons"></i-multi-select>
                        <i-input-notification v-show="dobRejectReasonHasErrors" text="Missing required field."/>
                        <i-input-notification v-show="dobRejectReasonApproveErrors" text="Cannot select a reject reason when approving a Representative ID."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'NOSSN4_INTL_FAIL'">
                        <i-multi-select v-if="!$parent.loading" v-model="ssn4OptionalIntRejectReasonValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="rejectReasonsOptions" @input="onTouchSsn4OptionalIntRejectReasons"></i-multi-select>
                        <i-input-notification v-show="ssn4OptionalIntRejectReasonHasErrors" text="Missing required field."/>
                        <i-input-notification v-show="ssn4OptionalIntRejectReasonApproveErrors" text="Cannot select a reject reason when approving a Representative ID."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'NOSSN4_DUP_INTL_FAIL'">
                        <i-multi-select v-if="!$parent.loading" v-model="ssn4OptionalDupIntRejectReasonValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="rejectReasonsOptions" @input="onTouchSsn4OptionalDupIntRejectReasons"></i-multi-select>
                        <i-input-notification v-show="ssn4OptionalDupIntRejectReasonHasErrors" text="Missing required field."/>
                        <i-input-notification v-show="ssn4OptionalDupIntRejectReasonApproveErrors" text="Cannot select a reject reason when approving a Representative ID."/>
                    </i-input-group>
                    <i-input-group class="indi-col-xs-8" v-if="failureType.failureTypesCode === 'NOSSN4_US_FAIL'">
                        <i-multi-select v-if="!$parent.loading" v-model="ssn4OptionalUsaRejectReasonValue" :disabled="!isAdmin" placeholder="Select All that Apply" v-bind:options="rejectReasonsOptions" @input="onTouchSsn4OptionalUsaRejectReasons"></i-multi-select>
                        <i-input-notification v-show="ssn4OptionalUsaRejectReasonHasErrors" text="Missing required field."/>
                        <i-input-notification v-show="ssn4OptionalUsaRejectReasonApproveErrors" text="Cannot select a reject reason when approving a Representative ID."/>
                    </i-input-group>
                </i-grid>
            </div>
            <p/>
            <i-grid container="fluid">
                <div class="indi-col-xs-4" >
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="padding-top: 25px;font-size:18px;text-align: right">Notes/Comments :</p>
                    </div>
                </div>
                <div class="indi-col-xs-8" >
                    <i-textarea v-model="comments"  maxlength="250" v-bind:value="comments" aria-label="Notes/Comments" :disabled ="!isAdmin"/>
                    <!--<i-input-notification v-bind:text="comments"/>-->
                </div>
            </i-grid>
        </i-fieldset>
        <i-grid>
            <i-button class="indi-col-xs-3 indi-col-xs-trail-4" label="Deactivate" id="deactivateBtn" :disabled ="!isAdmin " @click="deactivateRepresentative('deactivate')" variant="tertiary"></i-button>
            <i-button id="approveBtn"  variant="primary" label="Approve" @click="submitRepResolutuion('approve')" :disabled ="!isAdmin "></i-button>
            <i-button id="rejectBtn"  @click="submitRepResolutuion('reject')" label="Reject" :disabled ="!isAdmin "></i-button>
        </i-grid>
        <br>
    </div>
    <i-notification v-show="this.approvedRejected != ''" v-bind:message="approvedRejected"/>
    <i-notification v-show="this.resolutionSuccess != ''" variant="success" v-bind:message="resolutionSuccess"/>


    <i-modal header="Deactivate Representative" tertiary-button-label="Cancel"  primary-button-label="Deactivate"
             body="Please confirm Deactivation. Deactivating the representative will unlink their account and require them to re-register."
             ref="deactivateModal"
             aria-label="Close Modal" v-on:on-primary-button-click="deactivateRepresentative('confirmedDeactivate')">
    </i-modal>
      <i-modal header="Linked Account Information"   primary-button-label="Okay"
               ref="refIdModal" class="indi-modal--wide &"
               aria-label="Close Modal" v-on:on-primary-button-click="toggleRefIdModalOpen()">
              <slot>
                <i-data-table :table-data="repIdSpinData" fixedHeader fitContent max-height='300' />
              </slot>
      </i-modal>

    <i-notification v-if="this.successMessage != ''" variant="success" v-bind:message="successMessage"/>
    <i-notification v-if="this.failureMessage != ''" variant="error" v-bind:message="failureMessage"/>

 </div>
</template>

<script>INotification
   import { ILongFormText, IFieldset, ILoader, IButton, ILabel, ITextarea, IDatePicker, IModal,INotification ,
        IInputGroup, IGrid, IInput, IInputNotification, IMultiSelect, IDataTable } from 'indigo-component-library';
  import {HTTP} from '../http-common'
  import router from '../router'
  export default {
    props:['load'],
    data: function () {
      return {
      successMessage: '',
      failureMessage:'',
        failedRejected:false,
        approvedRejected:'',
        resolutionSuccess:'',
        failureTypes: [],
        representativeId:'',
        loading:false,
        address1:'',
        address2:'',
        city:'',
        dob:'',
        state:'',
        zipCode:'',
        l4ssn:'',
        email:'',
        duplicate_rep:'',
        repStatus:'Failed',
        annualAgreementSigned:'',
        annualAgreementRenewal:'',
        monthNames : ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ],
        legalName:'',
        comments:'',
        failureType:0,

        dobFailureTypes:[],
        ssnFailureTypes:[],
        ssn4OptionalIntFailureTypes:[],
        ssn4OptionalDupIntFailureTypes:[],
        ssn4OptionalUsaFailureTypes:[],
        duplicateFailureTypes:[],
        deceasedFailureTypes:[],
        identityFailureTypes:[],

        dobFailureTypeValue:[],
        ssnFailureTypeValue:[],
        ssn4OptionalIntFailureTypeValue:[],
        ssn4OptionalDupIntFailureTypeValue:[],
        ssn4OptionalUsaFailureTypeValue:[],
        duplicateFailureTypeValue:[],
        deceasedFailureTypeValue:[],
        identityFailureTypeValue:[],
        isTouched: false,

        identityFailureTypeHasErrors:false,
        deceasedFailureTypeHasErrors:false,
        ssnFailureTypeHasErrors:false,
        ssn4OptionalIntFailureTypeHasErrors:false,
        ssn4OptionalDupIntFailureTypeHasErrors:false,
        ssn4OptionalUsaFailureTypeHasErrors:false,
        duplicateFailureTypeHasErrors:false,
        dobFailureTypeHasErrors:false,
          dobRejectReasonValue:[],
          ssnRejectReasonValue:[],
          ssn4OptionalIntRejectReasonValue:[],
          ssn4OptionalDupIntRejectReasonValue:[],
          ssn4OptionalUsaRejectReasonValue:[],
          duplicateRejectReasonValue:[],
          deceasedRejectReasonValue:[],
          identityRejectReasonValue:[],
          isTouched: false,

          identityRejectReasonHasErrors:false,
          deceasedRejectReasonHasErrors:false,
          ssnRejectReasonHasErrors:false,
          ssn4OptionalIntRejectReasonHasErrors:false,
          ssn4OptionalDupIntRejectReasonHasErrors:false,
          ssn4OptionalUsaRejectReasonHasErrors:false,
          duplicateRejectReasonHasErrors:false,
          dobRejectReasonHasErrors:false,

          identityRejectReasonApproveErrors:false,
          deceasedRejectReasonApproveErrors:false,
          ssnRejectReasonApproveErrors:false,
          ssn4OptionalIntRejectReasonApproveErrors:false,
          ssn4OptionalDupIntRejectReasonApproveErrors:false,
          ssn4OptionalUsaRejectReasonApproveErrors:false,
          duplicateRejectReasonApproveErrors:false,
          dobRejectReasonApproveErrors:false,
          rejectReasonsOptions:["Document is expired", "Document is invalid", "Document missing agent name",
              "Document includes incorrect/misspelt agent name", "Document includes incorrect date of birth", "Document includes incorrect SSN4/Tribal ID",
              "Unreadable document", "No documentation provided to resolve date of birth error", "No document provided to resolve SSN4/Tribal ID error",
              "No document provided", "Duplicate ID requested and not allowed", "Deceased proof of life documentation required."],
          isAdmin:false,
        oktaEntitlementsForRep:[],
        spinCompanyNameListForRep:[]
      }
    },
    name: "SearchRepresentative",
    components: {
        ILongFormText, IFieldset, ILoader, IButton, ILabel, ITextarea, IDatePicker,INotification,
        IInputGroup, IGrid, IInput, IInputNotification, IMultiSelect, IModal,IDataTable
    },
    beforeMount() {
      this.$parent.loading = true;
      HTTP.get('/rad-svc/resolution/resolutionCodes'
      ).then(response => {
        if (response.status === 200) {
          this.dobFailureTypes = [];
          this.ssnFailureTypes = [];
          this.identityFailureTypes = [];
          this.deceasedFailureTypes = [];
          this.dobRejectReasonValue = [];
          this.ssnRejectReasonValue=[];
          this.ssn4OptionalIntRejectReasonValue=[];
          this.ssn4OptionalDupIntRejectReasonValue=[];
          this.ssn4OptionalUsaRejectReasonValue=[];
          this.duplicateRejectReasonValue=[];
          this.deceasedRejectReasonValue=[];
          this.identityRejectReasonValue=[];
          for (let j = 0; j < response.data.length; j++) {
            if (response.data[j].tpivFailDob) {
              this.dobFailureTypes.push(response.data[j]);
            }
            if (response.data[j].tpivFailNameSsn4) {
              this.ssnFailureTypes.push(response.data[j]);
            }
            if (response.data[j].identtityNotFound) {
              this.identityFailureTypes.push(response.data[j]);
            }
            if (response.data[j].tpivFailDeceased) {
              this.deceasedFailureTypes.push(response.data[j]);
            }
            if (response.data[j].duplicate) {
              this.duplicateFailureTypes.push(response.data[j]);
            }
            if (response.data[j].international) {
              this.ssn4OptionalIntFailureTypes.push(response.data[j]);
            }
            if (response.data[j].international_duplicate) {
              this.ssn4OptionalDupIntFailureTypes.push(response.data[j]);
            }
            if (response.data[j].us_no_ssn4) {
              this.ssn4OptionalUsaFailureTypes.push(response.data[j]);
            }
          }
        } else {
          this.$parent.loading = false;
          console.log('Got Error while : '+window.location.href);
          router.push({name: "error"});
        }
        this.$parent.loading = false;
      }).catch(err => {
        this.$parent.loading = false;
        console.log('Got Error while : '+window.location.href);
        router.push({name: "error"});
      });
    },
    computed: {
      repIdSpinData() {
        return {
          head: [{ label: 'Date Linked' }, { label: 'SPIN' }, { label: 'Company Name'}, { label: 'User Role' }],
          body: this.loadRepIdSpinData(this.oktaEntitlementsForRep)
        };
      }
    },
    methods: {
        getResolutions : function( resolutions ) {
            let res =[]
            for (let [key, value] of Object.entries(response.data)) {
                res.push(value.reason)
            }
            return res
        },
      checkUser: function () {
        if(this.$cookie.get('userRole') === 'USA ADM' ) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      },
      onTouchDobFailureTypes() {
        if (this.dobFailureTypes.length > 0 && this.dobFailureTypeValue.length === 0) {
          this.dobFailureTypeHasErrors = true;
        } else {
          this.dobFailureTypeHasErrors = false;
        }
      },
      onTouchDuplicateFailureTypes() {
        if (this.duplicateFailureTypes.length > 0 && this.duplicateFailureTypeValue.length === 0) {
          this.duplicateFailureTypeHasErrors = true;
        } else {
          this.duplicateFailureTypeHasErrors = false;
        }
      },
      onTouchSSNFailureTypes() {
        if (this.ssnFailureTypes.length > 0 && this.ssnFailureTypeValue.length === 0) {
          this.ssnFailureTypeHasErrors = true;
        } else {
          this.ssnFailureTypeHasErrors = false;
        }
      },
      onTouchDeceasedFailureTypes() {
        if (this.deceasedFailureTypes.length > 0 && this.deceasedFailureTypeValue.length === 0) {
          this.deceasedFailureTypeHasErrors = true;
        } else {
          this.deceasedFailureTypeHasErrors = false;
        }
      },
      onTouchIdentityFailureTypes() {
        if (this.identityFailureTypes.length > 0 && this.identityFailureTypeValue.length === 0) {
          this.identityFailureTypeHasErrors = true;
        } else {
          this.identityFailureTypeHasErrors = false;
        }
      },
      onTouchSsn4OptionalIntFailureTypes() {
        if (this.ssn4OptionalIntFailureTypes.length > 0 && this.ssn4OptionalIntFailureTypeValue.length === 0) {
          this.ssn4OptionalIntFailureTypeHasErrors = true;
        } else {
          this.ssn4OptionalIntFailureTypeHasErrors = false;
        }
      },
      onTouchSsn4OptionalDupIntFailureTypes() {
        if (this.ssn4OptionalDupIntFailureTypes.length > 0 && this.ssn4OptionalDupIntFailureTypeValue.length === 0) {
          this.ssn4OptionalDupIntFailureTypeHasErrors = true;
        } else {
          this.ssn4OptionalDupIntFailureTypeHasErrors = false;
        }
      },
      onTouchSsn4OptionalUsaFailureTypes() {
        if (this.ssn4OptionalUsaFailureTypes.length > 0 && this.ssn4OptionalUsaFailureTypeValue.length === 0) {
          this.ssn4OptionalUsaFailureTypeHasErrors = true;
        } else {
          this.ssn4OptionalUsaFailureTypeHasErrors = false;
        }
      },
        onTouchDobRejectReasons() {
            if (this.dobFailureTypes.length > 0 && this.dobRejectReasonValue.length === 0 && this.dobRejectReasonApproveErrors) {
                this.dobRejectReasonApproveErrors = false;
            } else {
                this.dobRejectReasonHasErrors = false;
            }
        },
        onTouchDuplicateRejectReasons() {
            if (this.duplicateFailureTypes.length > 0 && this.duplicateRejectReasonValue.length === 0 && this.duplicateRejectReasonApproveErrors) {
                this.duplicateRejectReasonApproveErrors = false;
            } else {
                this.duplicateRejectReasonHasErrors = false;
            }
        },
        onTouchSSNRejectReasons() {
            if (this.ssnFailureTypes.length > 0 && this.ssnRejectReasonValue.length === 0 && this.ssnRejectReasonApproveErrors) {
                this.ssnRejectReasonApproveErrors = false;
            } else {
                this.ssnRejectReasonHasErrors = false;
            }
        },
        onTouchDeceasedRejectReasons() {
            if (this.deceasedFailureTypes.length > 0 && this.deceasedRejectReasonValue.length === 0 && this.deceasedRejectReasonApproveErrors) {
                this.deceasedRejectReasonApproveErrors = false;
            } else {
                this.deceasedRejectReasonHasErrors = false;
            }
        },
        onTouchIdentityRejectReasons() {
            if (this.identityFailureTypes.length > 0 && this.identityRejectReasonValue.length === 0 && this.identityRejectReasonApproveErrors) {
                this.identityRejectReasonApproveErrors = false;
            } else {
                this.identityRejectReasonHasErrors = false;
            }
        },
        onTouchSsn4OptionalIntRejectReasons() {
            if (this.ssn4OptionalIntFailureTypes.length > 0 && this.ssn4OptionalIntRejectReasonValue.length === 0 && this.ssn4OptionalIntRejectReasonApproveErrors) {
                this.ssn4OptionalIntRejectReasonApproveErrors = false;
            } else {
                this.ssn4OptionalIntRejectReasonHasErrors = false;
            }
        },
        onTouchSsn4OptionalDupIntRejectReasons() {
            if (this.ssn4OptionalDupIntFailureTypes.length > 0 && this.ssn4OptionalDupIntRejectReasonValue.length === 0 && this.ssn4OptionalDupIntRejectReasonApproveErrors) {
                this.ssn4OptionalDupIntRejectReasonApproveErrors = false;
            } else {
                this.ssn4OptionalDupIntRejectReasonHasErrors = false;
            }
        },
        onTouchSsn4OptionalUsaRejectReasons() {
            if (this.ssn4OptionalUsaFailureTypes.length > 0 && this.ssn4OptionalUsaRejectReasonValue.length === 0 && this.ssn4OptionalUsaRejectReasonApproveErrors) {
                this.ssn4OptionalUsaRejectReasonApproveErrors = false;
            } else {
                this.ssn4OptionalUsaRejectReasonHasErrors = false;
            }
        },
      clearErrors : function() {
        this.approvedRejected = '';
        this.resolutionSuccess = '';
        this.dobFailureTypeValue=[];
        this.ssnFailureTypeValue=[];
        this.ssn4OptionalIntFailureTypeValue=[];
        this.ssn4OptionalDupIntFailureTypeValue=[];
        this.ssn4OptionalUsaFailureTypeValue=[];
        this.duplicateFailureTypeValue=[];
        this.deceasedFailureTypeValue=[];
        this.identityFailureTypeValue=[];
        this.dobRejectReasonValue=[];
        this.ssnRejectReasonValue=[];
        this.ssn4OptionalIntRejectReasonValue=[];
        this.ssn4OptionalDupIntRejectReasonValue=[];
        this.ssn4OptionalUsaRejectReasonValue=[];
        this.duplicateRejectReasonValue=[];
        this.deceasedRejectReasonValue=[];
        this.identityRejectReasonValue=[];
      },
      submitRepResolutuion : function( resolutionType ) {

        if(!this.isAdmin) {
          return;
        }
        this.identityFailureTypeHasErrors= false;
        this.deceasedFailureTypeHasErrors= false;
        this.ssnFailureTypeHasErrors= false;
        this.duplicateFailureTypeHasErrors= false;
        this.dobFailureTypeHasErrors= false;
        let anyErrors = false;
        this.failureTypes.forEach((obj) => {
          if( obj.failureTypesCode === 'TPIV_FAIL_DOB' && !this.dobFailureTypeValue.length > 0) {
            this.dobFailureTypeHasErrors = true;
            anyErrors = true;
          }
          if(obj.failureTypesCode === 'TPIV_FAIL_DOB' && 'reject'=== resolutionType && !this.dobRejectReasonValue.length > 0) {
              this.dobRejectReasonHasErrors = true;
              anyErrors = true;
          }
          if(obj.failureTypesCode === 'TPIV_FAIL_DOB' && 'approve' === resolutionType && this.dobRejectReasonValue.length > 0) {
              this.dobRejectReasonApproveErrors = true;
              anyErrors = true;
          }
          if( obj.failureTypesCode === 'DUPLICATE' && !this.duplicateFailureTypeValue.length > 0 ) {
            this.duplicateFailureTypeHasErrors = true;
            anyErrors = true;
          }
          if(obj.failureTypesCode === 'DUPLICATE' && 'reject'=== resolutionType && !this.duplicateRejectReasonValue.length > 0) {
              this.duplicateRejectReasonHasErrors = true;
              anyErrors = true;
          }
          if(obj.failureTypesCode === 'DUPLICATE' && 'approve' === resolutionType && this.duplicateRejectReasonValue.length > 0) {
              this.duplicateRejectReasonApproveErrors = true;
              anyErrors = true;
          }
          if( obj.failureTypesCode === 'TPIV_FAIL_NAME_SSN4' && !this.ssnFailureTypeValue.length > 0) {
            this.ssnFailureTypeHasErrors = true;
            anyErrors = true;
          }
          if(obj.failureTypesCode === 'TPIV_FAIL_NAME_SSN4' && 'reject'=== resolutionType && !this.ssnRejectReasonValue.length > 0) {
              this.ssnRejectReasonHasErrors = true;
              anyErrors = true;
          }
          if(obj.failureTypesCode === 'TPIV_FAIL_NAME_SSN4' && 'approve' === resolutionType && this.ssnRejectReasonValue.length > 0) {
              this.ssnRejectReasonApproveErrors = true;
              anyErrors = true;
          }
          if( obj.failureTypesCode === 'TPIV_FAIL_DECEASED' && !this.deceasedFailureTypeValue.length > 0 ) {
            this.deceasedFailureTypeHasErrors = true;
            anyErrors = true;
          }
          if(obj.failureTypesCode === 'TPIV_FAIL_DECEASED' && 'reject'=== resolutionType && !this.deceasedRejectReasonValue.length > 0) {
              this.deceasedRejectReasonHasErrors = true;
              anyErrors = true;
          }
          if(obj.failureTypesCode === 'TPIV_FAIL_DECEASED' && 'approve' === resolutionType && this.deceasedRejectReasonValue.length > 0) {
              this.deceasedRejectReasonApproveErrors = true;
              anyErrors = true;
          }
          if( obj.failureTypesCode === 'TPIV_FAIL_IDENTITY_NOT_FOUND' && !this.identityFailureTypeValue.length > 0 ) {
            this.identityFailureTypeHasErrors = true;
            anyErrors = true;
          }
          if(obj.failureTypesCode === 'TPIV_FAIL_IDENTITY_NOT_FOUND' && 'reject'=== resolutionType && !this.identityRejectReasonValue.length > 0) {
              this.identityRejectReasonHasErrors = true;
              anyErrors = true;
          }
          if(obj.failureTypesCode === 'TPIV_FAIL_IDENTITY_NOT_FOUND' && 'approve' === resolutionType && this.identityRejectReasonValue.length > 0) {
              this.identityRejectReasonApproveErrors = true;
              anyErrors = true;
          }
          if( obj.failureTypesCode === 'NOSSN4_INTL_FAIL' && !this.ssn4OptionalIntFailureTypeValue.length > 0 ) {
            this.ssn4OptionalIntFailureTypeHasErrors = true;
            anyErrors = true;
          }
          if(obj.failureTypesCode === 'NOSSN4_INTL_FAIL' && 'reject'=== resolutionType && !this.ssn4OptionalIntRejectReasonValue.length > 0) {
              this.ssn4OptionalIntRejectReasonHasErrors = true;
              anyErrors = true;
          }
          if(obj.failureTypesCode === 'NOSSN4_INTL_FAIL' && 'approve' === resolutionType && this.ssn4OptionalIntRejectReasonValue.length > 0) {
              this.ssn4OptionalIntRejectReasonApproveErrors = true;
              anyErrors = true;
          }
          if( obj.failureTypesCode === 'NOSSN4_DUP_INTL_FAIL' && !this.ssn4OptionalDupIntFailureTypeValue.length > 0 ) {
            this.ssn4OptionalDupIntFailureTypeHasErrors = true;
            anyErrors = true;
          }
          if(obj.failureTypesCode === 'NOSSN4_DUP_INTL_FAIL' && 'reject'=== resolutionType && !this.ssn4OptionalDupIntRejectReasonValue.length > 0) {
              this.ssn4OptionalDupIntRejectReasonHasErrors = true;
              anyErrors = true;
          }
          if(obj.failureTypesCode === 'NOSSN4_DUP_INTL_FAIL' && 'approve' === resolutionType && this.ssn4OptionalDupIntRejectReasonValue.length > 0) {
              this.ssn4OptionalDupIntRejectReasonApproveErrors = true;
              anyErrors = true;
          }
          if( obj.failureTypesCode === 'NOSSN4_US_FAIL' && !this.ssn4OptionalUsaFailureTypeValue.length > 0 ) {
            this.ssn4OptionalUsaFailureTypeHasErrors = true;
            anyErrors = true;
          }
          if(obj.failureTypesCode === 'NOSSN4_US_FAIL' && 'reject'=== resolutionType && !this.ssn4OptionalUsaRejectReasonValue.length > 0) {
              this.ssn4OptionalUsaRejectReasonHasErrors = true;
              anyErrors = true;
          }
          if(obj.failureTypesCode === 'NOSSN4_US_FAIL' && 'approve' === resolutionType && this.ssn4OptionalUsaRejectReasonValue.length > 0) {
              this.ssn4OptionalUsaRejectReasonApproveErrors = true;
              anyErrors = true;
          }
        });
        if( !anyErrors ) {
          let failureDoc = []
          if(this.dobFailureTypes.length > 0 && this.dobFailureTypeValue.length > 0 ) {
            failureDoc.push( { failureType:"TPIV_FAIL_DOB", resolutionCodeId:this.dobFailureTypeValue.map(function (obj) {return obj.id;}), rejectionReasons:this.dobRejectReasonValue})
          }
          if(this.duplicateFailureTypes.length > 0 && this.duplicateFailureTypeValue.length > 0 ) {
            failureDoc.push( { failureType:"DUPLICATE", resolutionCodeId:this.duplicateFailureTypeValue.map(function (obj) {return obj.id;}), rejectionReasons:this.duplicateRejectReasonValue})
          }
          if(this.ssnFailureTypes.length > 0 && this.ssnFailureTypeValue.length > 0 ) {
            failureDoc.push( { failureType:"TPIV_FAIL_NAME_SSN4", resolutionCodeId:this.ssnFailureTypeValue.map(function (obj) {return obj.id;}), rejectionReasons: this.ssnRejectReasonValue})
          }
          if(this.deceasedFailureTypes.length > 0 && this.deceasedFailureTypeValue.length > 0 ) {
            failureDoc.push( { failureType:"TPIV_FAIL_DECEASED", resolutionCodeId:this.deceasedFailureTypeValue.map(function (obj) {return obj.id;}), rejectionReasons: this.deceasedRejectReasonValue})
          }
          if(this.identityFailureTypes.length > 0 && this.identityFailureTypeValue.length > 0 ) {
            failureDoc.push( { failureType:"TPIV_FAIL_IDENTITY_NOT_FOUND", resolutionCodeId:this.identityFailureTypeValue.map(function (obj) {return obj.id;}), rejectionReasons: this.identityRejectReasonValue})
          }
          if(this.ssn4OptionalIntFailureTypes.length > 0 && this.ssn4OptionalIntFailureTypeValue.length > 0 ) {
            failureDoc.push( { failureType:"NOSSN4_INTL_FAIL", resolutionCodeId:this.ssn4OptionalIntFailureTypeValue.map(function (obj) {return obj.id;}), rejectionReasons: this.ssn4OptionalIntRejectReasonValue})
          }
          if(this.ssn4OptionalDupIntFailureTypes.length > 0 && this.ssn4OptionalDupIntFailureTypeValue.length > 0 ) {
            failureDoc.push( { failureType:"NOSSN4_DUP_INTL_FAIL", resolutionCodeId:this.ssn4OptionalDupIntFailureTypeValue.map(function (obj) {return obj.id;}), rejectionReasons: this.ssn4OptionalDupIntRejectReasonValue})
          }
          if(this.ssn4OptionalUsaFailureTypes.length > 0 && this.ssn4OptionalUsaFailureTypeValue.length > 0 ) {
            failureDoc.push( { failureType:"NOSSN4_US_FAIL", resolutionCodeId:this.ssn4OptionalUsaFailureTypeValue.map(function (obj) {return obj.id;}), rejectionReasons: this.ssn4OptionalUsaRejectReasonValue})
          }
          let params = {
            repId:this.representativeId,
            action:resolutionType,
            comments: this.comments,
            failureDoc: failureDoc
          }
          this.$parent.loading = true;
          HTTP.post('/rad-svc/resolution/resolve', params).then(response => {
            this.$parent.loading = false;
            if( response.data.code === 'ACTIVE' ) {
              this.resolutionSuccess = 'Representative ID '+this.representativeId+' is approved.';
              this.$parent.representativeId = "";
              this.$parent.showLockOrUnLock = false;
              this.$parent.showRepResolution = false;
            } else if( response.data.code === 'APPROVED_REJECTED' ) {
              this.approvedRejected = 'Representative ID '+this.representativeId+' cannot be approved due to a duplicate found in the database.';
              this.$parent.representativeId = "";
              this.$parent.showLockOrUnLock = false;
              this.$parent.showRepResolution = false;
            } else if( response.data.code === 'FAILED_REJECTED' ) {
              this.resolutionSuccess = 'Representative ID '+this.representativeId+' is successfully rejected. The Representative ID is not active.';
              this.$parent.representativeId = "";
              this.$parent.showLockOrUnLock = false;
              this.$parent.showRepResolution = false;
            } else {
                console.log('Got Error while : '+window.location.href);
              router.push({name: "error"});
              if(resolutionType === 'approve'){
              		//this.$ua.trackException('Error at Approve button on Representative Resoultion', false);
                  this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on Representative Resolution'});
              }
              if(resolutionType === 'reject'){
                //this.$ua.trackException('Error at Reject button on Representative Resoultion', false);
                this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on Representative Resolution'});
              }
            }
            sessionStorage.clear();
          }).catch(err => {
            this.$parent.loading = false;
              console.log('Got Error while : '+window.location.href);
            router.push({name: "error"});
            if(resolutionType === 'approve'){
                 //this.$ua.trackException('Error at Approve button on Representative Resoultion', false);
                 this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on Representative Resolution'});
            }
            if(resolutionType === 'reject'){
                 //this.$ua.trackException('Error at Reject button on Representative Resoultion', false);
                 this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on Representative Resolution'});
            }
            sessionStorage.clear();
          });
        }
		if(resolutionType === 'approve'){
      this.$gtm.trackEvent({category: 'ApproveRepResoultion', action: 'click', label: 'Approve Representative Resolution'});
		}
		if(resolutionType === 'reject'){
      this.$gtm.trackEvent({category: 'RejectRepResoultion', action: 'click', label: 'Reject Representative Resolution'});
		}
      },

        deactivateRepresentative(action){
            if(action!=='confirmedDeactivate'){
                this.$refs.deactivateModal.toggleOpen();
            } else {
                let params = {
                    comment: this.comments
                };
                HTTP.post('/rad-svc/representative/deactivate/repid/' + this.representativeId, JSON.stringify(params)).then(response => {
                    this.$parent.loading = false;
                    this.$parent.showLockOrUnLock = false;
                    this.$parent.showRepResolution = false;

                    this.$refs.deactivateModal.toggleOpen();

                    if (response.data.status === 200) {
                        if (response.data.code === 'SUCCESSFUL_DEACTIVATED_REPRESENTATIVE') {
                            this.successMessage = 'Representative ID ' + this.representativeId + ' is successfully deactivated.'
                        }
                    } else {
                        this.failureMessage = 'Representative ID ' + this.representativeId + ' has transaction errors, please try after some time.';
                    }
                }).catch(err => {
                    this.$parent.loading = false;
                    console.log('Got Error while : ' + window.location.href);
                    router.push({name: "error"});
                    //this.$ua.trackException('Error on button UpdateRepresentative', false);
                    this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on Representative Resolution'});
                });
            }
        },

      toggleRefIdModalOpen(){
        this.$refs.refIdModal.toggleOpen();
      },

	  mounted () {
		this.$gtm.trackView('submitRepResolutuion');
	  },
      loadRepData: function (responseData) {
        let repData = responseData.representative;
        this.annualAgreementSigned ='';

        this.checkUser();
        this.representativeId = repData.repId;

        if(repData.annualAgreementsDate != null || repData.annualAgreementsDate != undefined) {
           this.annualAgreementSigned = repData.annualAgreementsDate;
        }
        if(repData.annualAgreementsDeadlineDate != null || repData.annualAgreementsDeadlineDate != undefined) {
           this.annualAgreementRenewal = repData.annualAgreementsDeadlineDate;
        }

        this.legalName = repData.firstName +" " + repData.middleName + " " + repData.lastName
        if(repData.suffix ) {
          this.legalName = this.legalName + ", " + repData.suffix
        }

        this.address1 = repData.address1;
        this.address2 = repData.address2;
        this.city = repData.city;
        let sp = repData.dob.split(" ")[0].split("-");
        let d = new Date(sp[0], sp[1]-1, sp[2]);
        this.dob = this.monthNames[d.getMonth()] +' '+d.getDate()+', '+ d.getFullYear()
        this.l4ssn = repData.l4ssn;
        this.email = repData.email;
        this.duplicate_rep = repData.duplicate_rep != null ? repData.duplicate_rep.join(', ') : '';
        this.state = repData.state;
        this.zipCode = repData.zipCode;
        this.loadFailureTypes(repData.failures);
        if(repData.oktaEntitlements) {
          this.oktaEntitlementsForRep = repData.oktaEntitlements;
        }
        if(responseData.spinCompanyNameList){
          this.spinCompanyNameListForRep = responseData.spinCompanyNameList;
        } else {
          this.spinCompanyNameListForRep =[];
        }
                this.clearErrors();
      },
      loadFailureTypes : function(failureTypesCodes) {
        this.failureTypes =[];
        this.identityFailureTypeHasErrors= false;
        this.deceasedFailureTypeHasErrors= false;
        this.ssnFailureTypeHasErrors= false;
        this.duplicateFailureTypeHasErrors= false;
        this.dobFailureTypeHasErrors= false;

        this.identityFailureTypeValue= [];
        this.deceasedFailureTypeValue= [];
        this.ssnFailureTypeValue= [];
        this.ssn4OptionalIntFailureTypeValue= [];
        this.ssn4OptionalDupIntFailureTypeValue= [];
        this.ssn4OptionalUsaFailureTypeValue= [];
        this.duplicateFailureTypeValue= [];
        this.dobFailureTypeValue= [];
        this.comments = "";
        const failureTypesCodesObject = String(failureTypesCodes);
        let failureTypesCodesArr = failureTypesCodesObject.split(",");
        for ( let i = 0; i < failureTypesCodesArr.length; i++) {
          if (failureTypesCodesArr[i] === 'TPIV_FAIL_DOB') {
            let val = {
              name: "Date of Birth cannot be verified",
              options: this.dobFailureTypes,
              failureTypesCode: "TPIV_FAIL_DOB"
            }
            this.failureTypes.push(val)
          }
          if (failureTypesCodesArr[i] === 'TPIV_FAIL_NAME_SSN4') {
            let val = {
              name: "SSN4 cannot be verified",
              options: this.ssnFailureTypes,
              failureTypesCode: "TPIV_FAIL_NAME_SSN4"
            }
            this.failureTypes.push(val)
          }
          if (failureTypesCodesArr[i] === 'TPIV_FAIL_IDENTITY_NOT_FOUND') {
            let val = {
              name: "Identity not found",
              options: this.identityFailureTypes,
              failureTypesCode: "TPIV_FAIL_IDENTITY_NOT_FOUND"
            }
            this.failureTypes.push(val)
          }
          if (failureTypesCodesArr[i] === 'TPIV_FAIL_DECEASED') {
            let val = {
              name: "Identified as deceased",
              options: this.deceasedFailureTypes,
              failureTypesCode: "TPIV_FAIL_DECEASED"
            }
            this.failureTypes.push(val)
          }
          if (failureTypesCodesArr[i] === 'DUPLICATE') {
            let val = {
              name: "Duplicate",
              options: this.duplicateFailureTypes,
              model: "duplicateFailureType",
              failureTypesCode: "DUPLICATE"
            }
            this.failureTypes.push(val)
          }
          if (failureTypesCodesArr[i] === 'NOSSN4_INTL_FAIL') {
              let val = {
                  name: "International representative",
                  options: this.ssn4OptionalIntFailureTypes,
                  failureTypesCode: "NOSSN4_INTL_FAIL"
              }
              this.failureTypes.push(val)
          }
          if (failureTypesCodesArr[i] === 'NOSSN4_DUP_INTL_FAIL') {
              let val = {
                  name: "Duplicate international representative",
                  options: this.ssn4OptionalDupIntFailureTypes,
                  failureTypesCode: "NOSSN4_DUP_INTL_FAIL"
              }
              this.failureTypes.push(val)
          }
          if (failureTypesCodesArr[i] === 'NOSSN4_US_FAIL') {
                let val = {
                    name: "US representative without SSN4",
                    options: this.ssn4OptionalUsaFailureTypes,
                    failureTypesCode: "NOSSN4_US_FAIL"
                }
                this.failureTypes.push(val)
          }
        }
        this.total = this.failureTypes.length;
        for ( let i = 0; i < this.failureTypes.length; i++) {
            let resolutions =[];
             for (let [key, value] of Object.entries(this.failureTypes[i].options)) {
                 resolutions.push(value.resolution)
          }
          this.failureTypes[i].resolutions = resolutions;
        }
      },
      loadRepIdSpinData(oktaEntitlementsArr) {
        let repIdSpinInfo = [];
        for ( let i = 0; i < oktaEntitlementsArr.length; i++) {
          let repIdSpinRecrd =[];
          if(oktaEntitlementsArr[i].updatedOn) {
            const currentDateWithoutFormatArr = oktaEntitlementsArr[i].updatedOn.split("T")[0].split("-");
            const currentDateWithFormat = currentDateWithoutFormatArr[1]+'/'+
                currentDateWithoutFormatArr[2]+'/'+currentDateWithoutFormatArr[0];
            repIdSpinRecrd.push(currentDateWithFormat);
          } else {
            repIdSpinRecrd.push('');
          }
          if(oktaEntitlementsArr[i].uniqueKey.spin){
            repIdSpinRecrd.push(oktaEntitlementsArr[i].uniqueKey.spin.toString());
          } else {
            repIdSpinRecrd.push('');
          }
          if(oktaEntitlementsArr[i].uniqueKey.spin){
            const result  = this.spinCompanyNameListForRep.find(item => item.spin === oktaEntitlementsArr[i].uniqueKey.spin.toString())
            if(result && result.companyName) {
              repIdSpinRecrd.push(result.companyName);
            } else {
              repIdSpinRecrd.push('');
            }
          }else {
            repIdSpinRecrd.push('');
          }
          repIdSpinRecrd.push(oktaEntitlementsArr[i].uniqueKey.userRole);
          repIdSpinInfo.push(repIdSpinRecrd);
        }
        repIdSpinInfo.sort((a,b)=> new Date(b[0]) - new Date(a[0]));
        return repIdSpinInfo;
      }
    }
  }
</script>
