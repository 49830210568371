var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"indi-container indi-margin-top-xl"},[_c('div',{staticClass:"indi-row header-row"},[_c('i-app-header',{attrs:{"slot":"app-header","id":"header","name":"header"},slot:"app-header"},[_c('div',{staticClass:"header-font",attrs:{"slot":"title"},slot:"title"},[_vm._v(" Representative Accountability Database ")])]),_c('router-view'),_c('i-app-footer',{attrs:{"legal-links":[
                {
                  id:  '/paperworkReductionActNotice',
                  href: '#/paperworkReductionActNotice',
                  text: 'Paperwork Reduction Act Notice'
                },
                {
                  id: '/termsAndConditions',
                  href: '#/termsAndConditions',
                  text: 'Terms & Conditions'
                },
                {
                  id: '/privacyStatement',
                  href: '#/privacyStatement',
                  text: 'Privacy Statement'
                }
              ],"utility-links":[
                {
                   text: 'OMB Control Number: 3060-0819'
                }
              ],"logos":[
                {
                  src: 'static/img/fcc-logo_black.png',
                  alt: 'FCC Logo'
                },
                {
                  src: 'static/img/usac_logo.png',
                  alt: 'USAC Logo'
                } ]}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }