<template>
    <div>
        <i-long-form-text>
            <h1 tabindex="0">Representative Registration</h1>
            <p class="indi-long-form-text__p--small" tabindex="0">Please submit your personal email address below to begin the process of obtaining a Representative ID. A Representative ID is needed if you work for an eligible telecommunications carrier (ETC) that provides Lifeline.  You will receive additional  information by email after selecting the Submit button. For more information on the Representative Accountability Database and who should register for a Representative ID, please visit USAC's
                <span>
                    <a href="https://www.usac.org/lifeline/additional-requirements/rad/">website.</a>
                  </span>
            </p>
        </i-long-form-text>
        <i-fieldset>
            <i-grid>
                <i-input-group label="Email Address" label-for="Email Address" class="indi-col-lg-8">
                    <i-input id="emailAddress" name="emailAddress" type="email" v-model="emailInput" aria-label="Email Address"
                             @change="isEmailValid" :invalid="inputError !== ''"/>
                    <i-help-text text="example@email.com" />
                    <i-input-notification v-bind:text="inputError" aria-atomic="true" aria-live="assertive"/>
                </i-input-group>
                <i-button label="Forgot Representative ID"  variant="tertiary" @click="clickForgotRepresentativeId"></i-button>
                <i-tooltip name="frepTT" id="frepTT" text="Select this link if you already registered for a Representative ID but do not know what it is." style="margin-left:5px"
                           aria-hidden="false" aria-label="Select this link if you already registered for a Representative ID but do not know what it is."/>

                <i-button label="Annual Agreements"  variant="tertiary" @click="clickAnnualAgreement"></i-button>

            </i-grid>
            <i-long-form-text>
                <p class="indi-long-form-text__p--small" tabindex="0"> By submitting your email address, you agree to the Representative Accountability Database
                    <a v-bind:href="termsAndConditions">Terms & Conditions</a>.</p>
            </i-long-form-text>
        </i-fieldset>
        <i-grid>
            <div class="indi-col-xs-3">
                <Recaptcha ref="recaptchaRef" @AxiosReturned = 'AxiosReturned'></Recaptcha>
            </div>
            <div id="" :class="(recaptchaActive) ? 'recaptcha-button' : ''" class="indi-col-xs-offset-10 right-side-button">
                <i-button label="Submit"  variant="primary" @click="checkSubmit"></i-button>
            </div>
        </i-grid>
        <br>
        <i-modal header="Email Already Exists" primary-button-label="Ok"  ref="modal" aria-label="Close Modal" v-on:on-primary-button-click="closeModal">
            <slot>
                <i-long-form-text>
                    <p class="indi-long-form-text__p--small">
                        The email address that you provided is already associated with a Representative ID. Please click <a v-bind:href="recoverRepresentative">here</a> to request your Representative ID.
                    </p>
                </i-long-form-text>
            </slot>
        </i-modal>
    </div>
</template>
<script>
    import {
        ILongFormText, IFieldset, IInputGroup,IInput,IGrid,IModal,
        IButton,ITooltip,IInputNotification, IHelpText } from 'indigo-component-library';
    import axios from 'axios'
    import router from '../router'
    import Recaptcha from './Recaptcha'
    export default {
        name: "Register",
        beforeMount : function() {
            this.termsAndConditions = document.location.origin+ document.location.pathname+'#/termsAndConditions'
            this.recoverRepresentative = document.location.origin+ document.location.pathname+'#/recoverRepresentative'
            this.annualAgreement = document.location.origin+ document.location.pathname+'#/annualAgreement'
        },
        data: function () {
            return {
                recoverRepresentative:'',
                annualAgreement:'',
                termsAndConditions:'',
                recaptchaActive: false,
                loading: false,
                isForgotRepVisible: true,
                emailInput: '',
                email: '',
                isError: false,
                isRecaptchaError: false,
                inputError: '',
                inputBoxClass: {
                    inputBox: 'indi-form__input',
                    inputBoxNormal: 'indi-form__input',
                    inputBoxError: 'indi-form__input indi-form__input--has-error',
                },
                reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,240}))$/,
            }
        },
        methods: {
            AxiosReturned: function(isRecaptchaActive){
                if(isRecaptchaActive) {
                    this.loading = true;
                }
                this.recaptchaActive = isRecaptchaActive;
            },
            clickForgotRepresentativeId:function () {
                router.push({
                    name: "forgotRepresentative"
                });
                this.$gtm.trackEvent({category: 'ForgotRepresentativeId', action: 'click', label: 'Forgot Representative ID'});
            },
            clickAnnualAgreement:function () {
                router.push({
                    name: "annualAgreement"
                });
                this.$gtm.trackEvent({category: 'AnnualAgreement', action: 'click', label: 'Annual Agreement'});
            },
            isEmailValid: function () {
                if (this.emailInput === "") {
                    this.inputError = 'Missing required field.';
                    this.isError = true;
                } else if (!this.reg.test(this.emailInput) || this.emailInput.length > 240) {
                    this.inputError = 'Invalid email format.';
                    this.isError = true;
                } else {
                    this.inputError = '';
                    this.isError = false;
                }
            },
            closeModal : function() {
                this.$refs.modal.toggleOpen();
            },
            checkSubmit: function () {
                let recaptchatoken;
                if (this.$refs.recaptchaRef.isRecaptchaActive && !this.emailInput.endsWith('smoketest@smoketest.org')) {
                    this.$refs.recaptchaRef.checkIfRecaptchaVerified();
                    recaptchatoken = this.$refs.recaptchaRef.recaptchaToken;
                    this.isRecaptchaError = this.$refs.recaptchaRef.isRecaptchaActive && !this.$refs.recaptchaRef.recaptchaVerified;
                }
                this.isEmailValid();
                if ( (!this.isError && !this.isRecaptchaError) || this.emailInput.endsWith('smoketest@smoketest.org')) {
                    this.loading = true;
                    axios({
                        method: 'post',
                        url: '/rad-svc/representative/generateLink',
                        params: {
                            'email': this.emailInput,
                            'g-recaptcha-response': recaptchatoken
                        }
                    }).then(response => {
                        this.loading = false;
                        if (response.data.status === 200 && response.data.pass === true) {
                            router.push({
                                name: "success",
                                query: {re: response.data.re, rc: response.data.rc},
                                params: {isRouted: true, annualAgreement: false}
                            });
                        } else {
                            this.$refs.modal.toggleOpen();
                        }
                    }).catch(err => {
                        this.loading = false;
                        let responseError = err.response == undefined ? null : err.response.data;
                        if (responseError !== null && responseError.status === 400) {
                            let emailerror = responseError.errors.filter(el => el.field === 'email')
                            let recaptchaerror = responseError.errors.filter(el => el.field === 'recaptcha')
                            if (emailerror.length > 0) {
                                this.inputError = emailerror[0].description;
                                this.isError = true;
                            }
                            if (recaptchaerror.length > 0) {
                                this.$refs.recaptchaRef.setRecaptchaError(recaptchaerror[0].description)
                            }
                        } else {
                            console.log('Got Error while : '+window.location.href);
                            router.push({name: "error"});
                        }
                        this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on Landing page'});
                    });
                }
            }
        },
        components: {
            ILongFormText, IFieldset, IInputGroup, IInput, IGrid, IModal,
            IButton, IInputNotification, ITooltip, IHelpText, Recaptcha
        }
    }
</script>
<style scoped>
  /deep/ .indi-modal__content {
      width: 75%;
  }
</style>