<template>
  <div>
    <i-long-form-text tabindex="0">
      <h1>Paperwork Reduction Act Notice</h1>
      <p class="indi-long-form-text__p--intro">This Paperwork Reduction Act Notice describes the paperwork burden and provides notices associated with this information collection.</p>
      <p class="indi-long-form-text__p--small">Section 54.410 of the Federal Communications Commission's rules requires all Lifeline subscribers to demonstrate their eligibility to receive Lifeline services. This collection of information stems from the Commission's authority under Section 254 of the Communications Act of 1934, as amended, 47 U.S.C. §254. Using this authority, the FCC has designated USAC as the permanent Lifeline Administrator. The FCC has published rules detailing how consumers can qualify for Lifeline services and what Lifeline services they may receive (47 C.F.R. §54.400 et seq.). The data provided in response to this information collection will be used by USAC to verify the applicant's eligibility for Lifeline services.
      </p>
      <p class="indi-long-form-text__p--small">
        <B>We have estimated that each response to this collection of information will take, on average, between 0.25 and 0.50 hours.</B> Our estimate includes the time to read the questions, look through existing records, gather the required data, and actually complete and review the form or response. If you have any comments on this estimate, or how we can improve the collection and reduce the burden it causes you, please write to the Federal Communications Commission, OMD-PERM, Paperwork Reduction Project (3060-0819), Washington, D.C. 20554. We also will accept your comments via the Internet if you send them to <a href="mailto:PRA@fcc.gov">PRA@fcc.gov</a>. Please DO NOT SEND COMPLETED DATA COLLECTION FORMS TO THIS ADDRESS.
      </p>
      <p class="indi-long-form-text__p--small">
        <B>Remember --</B> You are not required to respond to a collection of information sponsored by the Federal government, and the government may not conduct or sponsor this collection, unless it displays a currently valid Office of Management and Budget (OMB) control number. This collection has been assigned an OMB control number of 3060-0819.
      </p>
      <p class="indi-long-form-text__p--small">
        The Commission is authorized under the Communications Act of 1934, as amended, to collect the information we request on this form. If we believe there may be a violation or potential violation of a statute or a Commission regulation, rule, or order, your response may be referred to the Federal, state, or local agency responsible for investigating, prosecuting, enforcing, or implementing the statute, rule, regulation, or order.
      </p>
      If you do not provide the information we request on this form, you will not be eligible to receive Lifeline services under the Lifeline Program rules, 47 C.F.R. §§54.400-54.423.
      <p class="indi-long-form-text__p--small">
        The foregoing Notice is required by the Paperwork Reduction Act of 1995, P.L. No. 104-13, 44 U.S.C. §3501, et seq.
      </p>
    </i-long-form-text>
  </div>
</template>
<script>
  import { ILongFormText } from 'indigo-component-library';
  export default {
    name: "PaperworkReductionActNotice",
    components: { ILongFormText }
  }
</script>
