<template>
  <div>
    <div v-if="showSuccess">
      <i-long-form-text >
        <h1 tabindex="0">Next Steps ...</h1>
      </i-long-form-text>
      <i-notification tabindex="0" v-show="resentEmail">Email sent. Please check your email again, and be sure to check your spam folder. If you have not received the email within 10 minutes, please contact USAC at <a style="color:#005bdf" href="mailto:LifelineSupport@usac.org">LifelineSupport@usac.org</a></i-notification>
      <i-fieldset>
        <i-long-form-text tabindex="0">
          <p class="indi-long-form-text__p--small">Thank you for submitting your information. Please check your email for additional information and next steps.</p>
          <p class="indi-long-form-text__p--small">Didn't get an email? Click<span v-if="resentEmail"> here </span>
            <span v-else><a href="javascript:return false" v-on:click="resendConformationLink"> here </a></span>
            to resend.</p>
        </i-long-form-text>
      </i-fieldset>
    </div>
    <div v-show="issuedRepId">
      <i-long-form-text>
        <h1 tabindex="0">Representative ID Already Exists</h1>
      </i-long-form-text>
      <i-fieldset>
        <i-long-form-text tabindex="0">
          <p class="indi-long-form-text__p--small">You have already provided your personal information and have either been issued a Representative ID or have been provided instruction on how to resolve errors associated with the registration process.
          </p>
          <br/>
          <p class="indi-long-form-text__p--small">Please click <a href="javascript:return false" @click="forgotRepresentativeLink">here</a> to request your Representative ID.</p>
        </i-long-form-text>
      </i-fieldset>
    </div>
    <div v-show="linkExpired">
      <i-long-form-text>
        <h1 tabindex="0">Expired/Invalid Link</h1>
      </i-long-form-text>
      <i-fieldset>
        <i-long-form-text tabindex="0">
          <p class="indi-long-form-text__p--small">Your unique link to continue the representative registration process is expired or invalid. Please begin the registration process by providing your email address on the
            <span><a v-bind:href="registrationUrl" style="color: #006bef !important;">representative registration page</a></span> again.
          </p>
        </i-long-form-text>
      </i-fieldset>
    </div>
    <div v-show="annualAgreementLinkExpired">
      <i-long-form-text>
        <h1 tabindex="0">Expired/Invalid Link</h1>
      </i-long-form-text>
      <i-fieldset>
        <i-long-form-text tabindex="0">
          <p class="indi-long-form-text__p--small">Your link to complete the RAD Annual Agreements is expired or invalid.
            If you have not completed the Annual Agreements yet, please visit <span><a v-bind:href="domainUrl" style="color: #006bef !important;">lifelinerad.org</a></span>
            and select the “Annual Agreements” option to receive a new link.
          </p>
        </i-long-form-text>
      </i-fieldset>
    </div>
    <div v-show="showError">
      <i-long-form-text>
        <h1 tabindex="0">Oops ...</h1>
      </i-long-form-text>
      <i-fieldset>
        <p class="indi-long-form-text__p--small"tabindex="0">Looks like we are having trouble. Please refresh the page and try again.</p>
      </i-fieldset>
    </div>
    <i-loader variant="overlay" v-if="loading" />
  </div>
</template>
<script>
  import {HTTP} from '../http-common'
  import router from '../router'
  import axios from 'axios'
  import {
    ILoader, ILongFormText, IFieldset, INotification
  } from 'indigo-component-library'

  export default {
    components: {ILoader, ILongFormText, IFieldset, INotification},
    data: function () {
      return {
        loading : false,
        showSuccess: false,
        resentEmail:false,
        showError:false,
        issuedRepId:false,
        linkExpired:false,
        annualAgreementLinkExpired:false,
        domainUrl:'',
        registrationUrl:''
      }
    },
    name: 'success',
    beforeMount :function () {
      this.domainUrl=document.location.origin;
      this.registrationUrl = this.domainUrl + document.location.pathname + "#/verifyRepresentative";
      document.title = "Status - Representative Accountability Database (RAD)";
      if('/error' === this.$router.currentRoute.path) {
        this.showError = true;
        this.showSuccess = false;
        document.title = "Error - Representative Accountability Database (RAD)";
      } else if('/verify' === this.$router.currentRoute.path) {
        if(this.$route.params.isRouted) {
          this.showSuccess = false;
          this.issuedRepId = true;
          return;
        }
        this.loading = true;
        HTTP.get('/rad-svc/representative/verify',{params :{ rc:this.$router.currentRoute.query.rc, re: this.$router.currentRoute.query.re}}
        ).then(response => {
          this.loading = false;
          if (response.data.pass === true) {
            //clear session
            sessionStorage.clear()
            router.push({name: "registration", query:{rc:response.data.rc, re: response.data.re}, params:{ email : response.data.email}});
          } else if( response.data.code === 'INVALID_EXPIRED_LINK') {
            this.showSuccess = false;
            this.linkExpired = true;
            document.title = "Expired/Invalid Link - Representative Accountability Database (RAD)";
          } else if( response.data.code === 'SUBMITTED_EMAIL') {
            this.showSuccess = false;
            this.issuedRepId = true;
          } else {
            this.showSuccess = false;
            this.issuedRepId = true;
          }
        }).catch(err => {
          console.log(err);
          this.loading = false;
          this.showError = true;
          this.showSuccess = false;
          this.issuedRepId = false;
        });
      }

      else if('/verifyAnnualAgreement' === this.$router.currentRoute.path) {
        if(this.$route.params.isRouted) {
          this.showSuccess = false;
          this.issuedRepId = true;
          return;
        }
        this.loading = true;
        HTTP.get('/rad-svc/representative/verifyAnnualAgreement',{params :{ rc:this.$router.currentRoute.query.rc, re: this.$router.currentRoute.query.re}}
        ).then(response => {
          this.loading = false;
          if (response.data.pass === true) {
            //clear session
            sessionStorage.clear()
            router.push({name: "certifyAnnualAgreement", query:{rc:response.data.rc, re: response.data.re}, params:{ email : response.data.email}});
          } else if( response.data.code === 'INVALID_EXPIRED_LINK') {
            this.showSuccess = false;
            this.annualAgreementLinkExpired = true;
            document.title = "Expired/Invalid Link - Representative Accountability Database (RAD)";
          } else if( response.data.code === 'SUBMITTED_EMAIL') {
            this.showSuccess = false;
            this.issuedRepId = true;
          } else {
            this.showSuccess = false;
            this.issuedRepId = true;
          }
        }).catch(err => {
          console.log(err);
          this.loading = false;
          this.showError = true;
          this.showSuccess = false;
          this.issuedRepId = false;
        });
      }

      else if('/verifyUpdateInformation' === this.$router.currentRoute.path) {

        this.loading = true;
        HTTP.get('/rad-svc/representative/verifyUpdateInformation',{params :{ rc:this.$router.currentRoute.query.rc, re: this.$router.currentRoute.query.re}}
        ).then(response => {
          this.loading = false;
          if (response.data.pass === true) {
            //clear session
            sessionStorage.clear()
            router.push({name: "updateInformationForm", query:{rc:response.data.rc, re: response.data.re}, params:{ repId: response.data.repId}});
          } else if( response.data.code === 'INVALID_EXPIRED_LINK') {
            this.showSuccess = false;
            this.annualAgreementLinkExpired = true;
            document.title = "Expired/Invalid Link - Representative Accountability Database (RAD)";
          } else if( response.data.code === 'SUBMITTED_EMAIL') {
            this.showSuccess = false;
            this.issuedRepId = true;
          } else {
            this.showSuccess = false;
            this.issuedRepId = true;
          }
        }).catch(err => {
          console.log(err);
          this.loading = false;
          this.showError = true;
          this.showSuccess = false;
          this.issuedRepId = false;
        });
      }

      else if( '/success' === this.$router.currentRoute.path && !this.$route.params.isRouted ) {
        HTTP.get('/rad-svc/representative/verifyEmail',{params :{ rc:this.$router.currentRoute.query.rc, re: this.$router.currentRoute.query.re}}
        ).then(response => {
          if(response.data.code === 'EMAIL_GENERATION_SUCCESS' ) {
            this.showSuccess = true;
            this.resentEmail = false;
          } else if( response.data.code === 'EMAIL_RESENT_SUCCESS') {
            this.showSuccess = true;
            this.resentEmail = true;
            document.title = "Thank You - Representative Accountability Database(RAD)";
          } else {
            router.push({name: "landing"});
          }
        }).catch(err => {
          console.log(err);
          router.push({name: "landing"});
        });
      } else {
        this.showSuccess = true;
      }
    },
    methods : {
      resendConformationLink:function () {
      let url='';
      if(this.$route.params.annualAgreement){
         url = '/rad-svc/representative/resendAnnualAgreementEmail';
      } else if(this.$route.params.updateInformation){
          url = '/rad-svc/representative/resendUpdateInformationEmail';
      } else{
         url= '/rad-svc/representative/resendEmail';
      }
        axios({
                  method: 'post',
                  url,
                  params :{ re:this.$router.currentRoute.query.re,rc:this.$router.currentRoute.query.rc},
                }
        ).then(() => {
          this.resentEmail = true;
        }).catch(err => {
          console.log(err);
          router.push({name: "landing"});
        });
      },
      forgotRepresentativeLink:function () {
        router.push({name: "forgotRepresentative"});
      }
    }
  }
</script>
