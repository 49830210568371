<template>
  <div>
  <div v-show="load">
    <i-fieldset>
      <i-grid container="fluid">
        <div class="indi-col-xs-4" >
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Representative ID :</p>
          </div>
        </div>
        <div class="indi-col-xs-8">
          <p style="font-size:18px" tabindex="0"><b>{{representativeId}}</b></p>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Status :</p>
          </div>
        </div>
        <div class="indi-col-xs-8">
          <p style="font-size:18px" tabindex="0"><b>{{repStatus}}</b></p>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Annual Agreement Signed :</p>
          </div>
        </div>
        <div class="indi-col-xs-8">
          <p style="font-size:18px" tabindex="0"><b>{{annualAgreementSigned}}</b></p>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Annual Agreement Renewal :</p>
          </div>
        </div>
        <div class="indi-col-xs-8">
          <p style="font-size:18px" tabindex="0"><b>{{annualAgreementRenewal}}</b></p>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Full Legal Name :</p>
          </div>
        </div>
        <div class="indi-col-xs-8" >
          <p style="font-size:18px" tabindex="0"><b>{{legalName}}</b></p>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4">
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Date of Birth :</p>
          </div>
        </div>
        <div class="indi-col-xs-8">
          <p style="font-size:18px" tabindex="0"><b>{{dob}}</b></p>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4" >
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Last 4 Numbers of SSN :</p>
          </div>
        </div>
        <div class="indi-col-xs-8" >
          <p style="font-size:18px" tabindex="0"><b>{{l4ssn}}</b></p>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4" >
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Address :</p>
          </div>
        </div>
        <div class="indi-col-xs-8" >
          <p style="font-size:18px" tabindex="0"><b>{{address1}} {{address2}}<br/> {{city}}, {{state}} {{zipCode}}</b></p>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4" >
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Email :</p>
          </div>
        </div>
        <div class="indi-col-xs-8" >
          <p style="font-size:18px" v-if="email !==''" tabindex="0"><b>{{email}}</b></p>
          <p style="font-size:18px" v-else><b>Not provided</b></p>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4" >
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Duplicate ID(s) :</p>
          </div>
        </div>
        <div class="indi-col-xs-8" >
          <p style="font-size:18px" v-if="duplicate_rep !==''" tabindex="0"><b>{{duplicate_rep}}</b></p>
          <p style="font-size:18px" v-else></p>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4" >
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Type of Linked Account(s) :</p>
          </div>
        </div>
        <div class="indi-col-xs-8" >
          <p style="font-size:18px" tabindex="0"><b>{{repRoles.toString()}}</b></p>
        </div>
      </i-grid>
      <i-grid>
        <div class="indi-long-form-text indi-col-xs-4" >
          <p class="indi-long-form-text__p" style="font-size:18px;text-align: right">Linked Account(s) Information :</p>
        </div>
        <div class="indi-col-xs-8" >
          <i-button  label="View Linked Account(s) Information" id="showSpinBtn"  @click="toggleRefIdModalOpen()" variant="tertiary"></i-button>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4" >
          <div class="indi-long-form-text" style="padding-top: 15px;">
            <p class="indi-long-form-text__p" style="font-size:18px;text-align: right" tabindex="0">Lockout Reason :</p>
          </div>
        </div>
        <i-input-group class="indi-col-xs-8">
          <MultiSelect v-bind:options="lockReasons" placeholder="Select All that Apply" inputId="lockReason" :value="lockReasonValue"
                          :disabled ="!isAdmin" v-model="lockReasonValue" ref="lockReasonType" @input="onTouchLockedOutReasons" />

          <i-input-notification v-show="lockReasonHasErrors" text="Missing required field." aria-live="assertive" aria-atomic="true"/>
        </i-input-group>
      </i-grid>
      <p/>
      <i-grid container="fluid">
        <div class="indi-col-xs-4" >
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="padding-top: 25px;font-size:18px;text-align: right" tabindex="0">Notes/Comments :</p>
          </div>
        </div>
        <div class="indi-col-xs-8" >
          <i-textarea v-model="comment" maxlength="5000" :disabled ="!isAdmin" v-bind:value="comment" aria-label="Notes/Comments" />
        </div>
      </i-grid>
      <p/>
      <i-grid container="fluid">
        <div class="indi-col-xs-4" >
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="padding-top:15px;font-size:18px;text-align: right" tabindex="0">Lockout Start Date :</p>
          </div>
        </div>
        <div class="indi-col-xs-8" >
          <DatePicker size="small" :disabled ="!isAdmin || isAdminOrPastDate || lockstateDisable" id="lockOutStartDate" v-model="lockStart" tabindex="0"/>
          <i-input-notification v-bind:text="lockStartError" aria-live="assertive" aria-atomic="true"/>
        </div>
      </i-grid>
      <i-grid container="fluid">
        <div class="indi-col-xs-4" >
          <div class="indi-long-form-text">
            <p class="indi-long-form-text__p" style="padding-top:15px;font-size:18px;text-align: right" tabindex="0">Lockout End Date :</p>
          </div>
        </div>
        <div class="indi-col-xs-8" >
          <DatePicker size="small" :disabled ="!isAdmin" id="lockOutEndDate" v-model="lockEnd" tabindex="0" ref="lockEnd" />
          <i-input-notification v-bind:text="lockEndError" aria-live="assertive" aria-atomic="true"/>
        </div>
      </i-grid>
    </i-fieldset>
    <i-grid>
      <i-button variant="tertiary" class="indi-col-xs-3 indi-col-xs-trail-4" label="Cancel" id="cancelBtn" @click="goBackToSearch()"
                v-if="(this.repStatus === 'Active' || this.repStatus === 'Inactive') && this.initialLockEnd === null && this.initialLockStart === null && this.initialLockStart < new Date()"></i-button>
      <i-button  label=" Lock " id="lockBtn" :disabled ="!isAdmin " @click="updateRepresentative('lock')" variant="primary"
                 v-if="(this.repStatus === 'Active' || this.repStatus === 'Inactive') && this.initialLockEnd === null && this.initialLockStart === null && this.initialLockStart < new Date()"></i-button>
      <i-button  label=" Deactivate " id="deactivateBtn" :disabled ="!isAdmin " @click="deactivateRepresentative('deactivate')" variant="secondary"
                 v-if="(this.repStatus === 'Active' || this.repStatus === 'Inactive') && this.initialLockEnd === null && this.initialLockStart === null && this.initialLockStart < new Date()"></i-button>


      <i-button class="indi-col-xs-offset-8" label="Update" id="updateBtn" :disabled ="!isAdmin" @click="updateRepresentative('update')" variant="primary"
                v-if="this.repStatus === 'Locked' || (this.initialLockStart !== null && this.initialLockEnd !== null) || ( this.initialLockStart > new Date())" ></i-button>
      <i-button variant="secondary" label="Cancel" id="cancelBtn2" @click="goBackToSearch()"
                v-if="this.repStatus === 'Locked' || (this.initialLockStart !== null && this.initialLockEnd !== null) || ( this.initialLockStart > new Date())"></i-button>


    </i-grid>
      <br>
      <i-modal v-if="isModalVisible" v-bind:header="modalHeader" tertiary-button-label="Cancel"  primary-button-label="Continue" ref="modal" v-bind:body="modalData"
               aria-label="Close Modal" v-on:on-primary-button-click="updateRepresentative('continue')">
          <slot>
              <i-long-form-text>
                  <p class="indi-long-form-text__p--small">{{modalData}}</p>
              </i-long-form-text>
          </slot>
      </i-modal>

      <br>
      <i-modal header="Deactivate Representative" tertiary-button-label="Cancel"  primary-button-label="Deactivate"
               body="Please confirm Deactivation. Deactivating the representative will unlink their account and require them to re-register."
               ref="deactivateModal"
               aria-label="Close Modal" v-on:on-primary-button-click="deactivateRepresentative('confirmedDeactivate')">
      </i-modal>
    <br>
    <i-modal header="Linked Account Information"   primary-button-label="Okay"
             ref="refIdModal" class="indi-modal--wide &"
             aria-label="Close Modal" v-on:on-primary-button-click="toggleRefIdModalOpen()">
      <slot>
        <i-data-table :table-data="repIdSpinData" fixedHeader fitContent max-height='300' />
      </slot>
    </i-modal>

  </div>
  <i-notification v-if="this.successMessage != ''" variant="success" v-bind:message="successMessage"/>
  <i-notification v-if="this.failureMessage != ''" variant="error" v-bind:message="failureMessage"/>
  </div>
</template>
<script>
  import {
    ILongFormText, IFieldset, ILoader, IButton, ILabel, ITextarea, IModal,
    IInputGroup, IGrid, IInput, IInputNotification, INotification, IDataTable } from 'indigo-component-library';
  import {HTTP} from '../http-common'
  import MultiSelect from "./MultiSelect/MultiSelect";
  import DatePicker from "./DatePicker/DatePicker";
  import router from '../router'
  export default {
    name: "LockOrUnLock",
    props:['load'],
    data: function () {
      return {
        successMessage: '',
        failureMessage:'',
        isModalVisible:false,
        disableUpdateButton:false,
        disableLockButton:false,
        disableCancelButton:false,
        lockstateDisable:false,
        representativeId:'',
        address1:'',
        address2:'',
        city:'',
        dob:'',
        state:'',
        zipCode:'',
        l4ssn:'',
        duplicate_rep:'',
        email:'',
        repRoles:[],
        repStatus:'',
        annualAgreementSigned:'',
        annualAgreementRenewal:'',
        legalName:'',
        comment:'',
        failureType:0,
        disableApproveAndRejectButton:false,
        monthNames : ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ],
        lockStart:null,
        lockEnd:'',
        lockReasons:[],
        lockReasonValue:[],
        lockReasonHasErrors:false,
        lockStartHasErrors:false,
        lockStartError:'',
        lockEndError:'',
        lockEndHasErrors:false,
        isAdmin:false,
        successfullyUpdated:false,
        successfullyLocked:false,
        successfullyActivated:false,
        failure:false,
        action:'',
        //hasErrors:false,
        msg1:'',
        msg2:'',
        initialLockStart:null,
        initialLockEnd:null,
        modalHeader:'',
        modalData:'',
        isAdminOrPastDate:false,
        oktaEntitlementsForRep:[],
        spinCompanyNameListForRep:[]
      }
    },
    mounted() {
      HTTP.get('/rad-svc/representative/lock/reasons').then(response => {
        for (let [key, value] of Object.entries(response.data)) {
          this.lockReasons.push(value.reason)
        }
      });
    },
    computed: {
      repIdSpinData() {
        return {
          head: [{ label: 'Date Linked' }, { label: 'SPIN' }, { label: 'Company Name'}, { label: 'User Role' }],
          body: this.loadRepIdSpinData(this.oktaEntitlementsForRep)
        };
      }
    },
    methods: {
      initialState() {
        //this.reasonTypes= [];
        this.representativeId='';
        this.address1='';
        this.address2='';
        this.city='';
        this.dob='';
        this.state='';
        this.zipCode='';
        this.l4ssn='';
        this.repRoles=[];
        this.repStatus='';
        this.legalName='';
        this.comment='';
        this.failureType=0;
        this.lockStart='';
        this.lockEnd='';
        this.annualAgreementSigned='';
        this.annualAgreementRenewal='';
        this.initialLockStart=null;
        this.initialLockEnd=null;
        this.lockReasonValue=[];
        this.isAdminOrPastDate=false;
      },
      loadRepData: function(responseData) {
        this.initialState();
        this.clearErrors();
        this.checkUser();
        this.annualAgreementSigned='';
        let repData = responseData.representative;
        if( repData === null || repData === undefined ) {
          //goBackToSearch();
        }

        this.representativeId = repData.repId;
        if( repData.middleName ) {
          this.legalName = repData.firstName + " " + repData.middleName + " " + repData.lastName
        } else {
          this.legalName = repData.firstName + " " + repData.lastName
        }

        if (repData.suffix) {
          this.legalName = this.legalName + ", " + repData.suffix
        }
        if(repData.status ==='ACTIVE'){
          this.repStatus= 'Active';
        } else if (repData.status ==='INACTIVE') {
          this.repStatus= 'Inactive';
        } else {
          this.repStatus= 'Locked';
        }
        if(repData.annualAgreementsDate != null || repData.annualAgreementsDate != undefined) {
          this.annualAgreementSigned = repData.annualAgreementsDate;
        }
        if(repData.annualAgreementsDeadlineDate != null || repData.annualAgreementsDeadlineDate != undefined) {
          this.annualAgreementRenewal = repData.annualAgreementsDeadlineDate;
        }
        this.address1 = repData.address1;
        this.address2 = repData.address2;
        this.city = repData.city;
        let sp = repData.dob.split(" ")[0].split("-");
        let d = new Date(sp[0], sp[1]-1, sp[2]);
        this.dob = this.monthNames[d.getMonth()] +' '+d.getDate()+', '+ d.getFullYear()
        this.l4ssn = repData.l4ssn;
        this.state = repData.state;
        this.email = repData.email;
        this.zipCode = repData.zipCode;
        this.duplicate_rep = repData.duplicate_rep != null ? repData.duplicate_rep.join(',') : '';
        if( repData.representativeLock != null || repData.representativeLock != undefined ) {
          let lockStartDate = repData.representativeLock.lockStart ? new Date(repData.representativeLock.lockStart) : null;
          let lockEndDate = repData.representativeLock.lockEnd ? new Date(repData.representativeLock.lockEnd) : null;
          if( repData.representativeLock.reasons &&
          ( (this.repStatus === 'Active' && lockEndDate &&
              lockStartDate > new Date() && lockEndDate > new Date()) ||
            (this.repStatus === 'Active' && lockStartDate > new Date() )
           ||  this.repStatus === 'Locked')) {
            let reasons =[];
            repData.representativeLock.reasons.forEach( function( obj) {
              reasons.push(obj.reason)
            })
            this.lockReasonValue = reasons
          }
          if( repData.representativeLock.comment &&
            ( (this.repStatus === 'Active' && lockStartDate && lockStartDate > new Date()
                && repData.representativeLock.lockEnd && lockEndDate > new Date() ) ||
              (this.repStatus === 'Active' && lockStartDate > new Date() )
              ||  this.repStatus === 'Locked')) {
            this.comment = repData.representativeLock.comment
          }
          if( lockStartDate && lockStartDate > new Date() || this.repStatus === 'Locked') {
            this.lockStart = ''+lockStartDate;
            this.initialLockStart = lockStartDate;
          } else {
            this.lockStart = ''+new Date();
          }
          if( this.repStatus === 'Locked' && lockStartDate && this.createDateWithoutTime(this.initialLockStart) < new Date()) {
            this.isAdminOrPastDate = true;
          }
          if( repData.representativeLock.lockEnd && lockEndDate > new Date() ) {
            this.lockEnd = ''+lockEndDate;
            this.initialLockEnd = lockEndDate;
          }
        } else {
          this.lockReasonValue = [];
          this.comment = "";
          if(this.isAdmin) {
            this.lockStart = ''+new Date();
          }
          this.lockEnd = '';
        }
        if( !this.isAdmin) {
          this.isAdminOrPastDate = true;
          if(this.initialLockStart) {
            this.lockStart =this.initialLockStart
          } else {
            this.lockStart = null;
          }
          this.lockstateDisable = true;
        }
        if( repData.oktaEntitlements != null || repData.oktaEntitlements != undefined ) {
          this.oktaEntitlementsForRep = repData.oktaEntitlements;
          repData.oktaEntitlements.forEach((oktaEnt) =>{if (this.repRoles.indexOf(oktaEnt.uniqueKey.userRole)==-1) this.repRoles.push(oktaEnt.uniqueKey.userRole);})
        } else {
          this.repRoles =[];
        }
        if(responseData.spinCompanyNameList){
          this.spinCompanyNameListForRep = responseData.spinCompanyNameList;
        } else {
          this.spinCompanyNameListForRep =[];
        }
        this.isModalVisible = true;
      },
      onTouchLockedOutReasons() {
        if (this.lockReasons.length > 0 && this.lockReasonValue.length === 0) {
          this.lockReasonHasErrors = true;
          document.getElementsByClassName('indi-multi-select')[0].children[0].setAttribute('style', 'border-color:#B80014')
        } else {
          document.getElementsByClassName('indi-multi-select')[0].children[0].setAttribute('style', 'border-color:')
          this.lockReasonHasErrors = false;
        }
      },
      clearErrors : function() {
        this.isModalVisible = false;
        this.successfullyUpdated = false;
        this.successfullyLocked = false;
        this.successfullyActivated = false;
        this.lockStartHasErrors = false;
        this.lockReasonHasErrors = false;
        this.lockEndHasErrors = false;
        this.lockEndError='';
        this.lockStartError='';
        this.successMessage='';
        this.failureMessage='';
        document.getElementsByClassName('indi-multi-select')[0].children[0].setAttribute('style', 'border-color:')
        document.getElementById("lockOutStartDate").setAttribute("style","");
        document.getElementById("lockOutEndDate").setAttribute("style","");
      },
      closeModal() {
        this.$refs.modal.toggleOpen();
      },
      goBackToSearch() {
        this.$parent.representativeId = "";
        this.$parent.showLockOrUnLock = false;
        this.$parent.showRepResolution = false;
      },
      validateRepData(action) {
        this.clearErrors();
        let hasErrors = false;
        this.isModalVisible = true;
        if (this.lockReasons.length > 0 && this.lockReasonValue.length === 0 ) {
          this.lockReasonHasErrors = true;
          document.getElementsByClassName('indi-multi-select')[0].children[0].setAttribute('style', 'border-color:#B80014')
          hasErrors = true;
        }
        if(document.getElementById("lockOutStartDate").value != '' && (document.getElementById("lockOutStartDate").value).match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/) === null ) {
          document.getElementById("lockOutStartDate").setAttribute("style","border-color: #B80014!important;");
          this.lockStartError="Lockout Start Date must be in mm/dd/yyyy.";
          this.lockStartHasErrors = true;
          hasErrors = true;
        } else if( this.validateDate(document.getElementById("lockOutStartDate").value) === 0 ) {
          document.getElementById("lockOutStartDate").setAttribute("style","border-color: #B80014!important;");
          //this.lockStartError="Lockout Start Date must be in mm/dd/yyyy.";
          this.lockStartHasErrors = true;
          hasErrors = true;
        }
        if(document.getElementById("lockOutEndDate").value != '' && (document.getElementById("lockOutEndDate").value).match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/) === null ) {
          document.getElementById("lockOutEndDate").setAttribute("style","border-color: #B80014!important;");
          this.lockEndError="Lockout End Date must be in mm/dd/yyyy.";
          this.lockEndHasErrors = true;
          hasErrors = true;
        } else if( this.validateDate(document.getElementById("lockOutEndDate").value) === 0  ) {
          document.getElementById("lockOutStartDate").setAttribute("style","border-color: #B80014!important;");
          this.lockStartError="Lockout Start Date must be in mm/dd/yyyy.";
          this.lockStartHasErrors = true;
          hasErrors = true;
        }
        if( hasErrors){
          return false;
        }
        let lockStart;
        let initialLockStart;
        let initialLockEnd;
        let lockEnd ;
        if( document.getElementById('lockOutStartDate').value != null || document.getElementById('lockOutStartDate').value != '') {
          lockStart = new Date(document.getElementById('lockOutStartDate').value);
        }
        if( document.getElementById('lockOutEndDate').value != null || document.getElementById('lockOutEndDate').value != '' ) {
          lockEnd = new Date(document.getElementById('lockOutEndDate').value);
        }
        if( this.initialLockStart != null || this.initialLockStart != '') {
          initialLockStart = new Date(this.initialLockStart);
        }
        if( this.initialLockEnd != null || this.initialLockEnd != '') {
          initialLockEnd = new Date(this.initialLockEnd);
        }
        if( this.lockStart === null || this.lockStart === '') {
          this.lockStartHasErrors = true;
          this.lockStartError="Missing required field."
          document.getElementById("lockOutStartDate").setAttribute("style","border-color: #B80014!important;");
          hasErrors = true;
        } else if( ( ( this.initialLockStart == null || ( this.initialLockStart != null && lockStart.getTime() !== initialLockStart.getTime()))
            && this.createDateAsUTC(this.lockStart) < this.createDateAsUTC(new Date()))) {
          this.lockStartHasErrors = true;
          this.lockStartError="Lockout Start Date cannot be in the past.";
          document.getElementById("lockOutStartDate").setAttribute("style","border-color: #B80014!important;");
          hasErrors = true;
        }
        if( this.lockEnd != '' && this.lockEnd != null
            && this.createDateWithoutTime(this.lockEnd) < this.createDateWithoutTime(new Date()) ) {
          this.lockEndHasErrors = true;
          this.lockEndError="Lockout End Date cannot be in the past.";
          document.getElementById("lockOutEndDate").setAttribute("style","border-color: #B80014!important;");
          hasErrors = true;
        } else if( this.repStatus !== 'Locked' && lockStart != '' && lockStart != null && lockEnd != '' && lockEnd != null && lockStart > lockEnd ) {
          this.lockEndHasErrors = true;
          this.lockEndError="Lockout End Date must be after Lockout Start Date.";
          hasErrors = true;
          document.getElementById("lockOutEndDate").setAttribute("style","border-color: #B80014!important;");
        } else if( this.lockStart !== null && this.lockEnd !== null &&
                new Date(lockStart.getFullYear(),lockStart.getMonth(),lockStart.getDate()).getTime() === new Date(lockEnd.getFullYear(),lockEnd.getMonth(),lockEnd.getDate()).getTime()
                && this.repStatus !== 'Locked' ) {
          this.lockEndHasErrors = true;
          this.lockEndError="Lockout Start Date and Lockout End Date cannot be the same.";
          document.getElementById("lockOutEndDate").setAttribute("style","border-color: #B80014!important;");
          hasErrors = true;
        }

        if( !hasErrors && (this.repStatus === 'Active' || this.repStatus === 'Inactive') && action != 'continue') {
          this.modalHeader = "Lock?";
          this.modalData="Locking this representative will lock their Representative ID at the start date you provided. Would you like to continue with locking?"
          this.$refs.modal.toggleOpen();
        } else if( !hasErrors && this.repStatus === 'Locked' && action != 'continue'
          && ( (initialLockStart != null && lockStart != null && initialLockStart.getTime() != lockStart.getTime())
            || (initialLockEnd === null && lockEnd != null ) || (initialLockStart === null && lockStart != null )
            || (initialLockEnd != null && lockEnd != null && (initialLockEnd.getTime() != lockEnd.getTime())))) {
          this.modalHeader = "Unlock?";
          this.modalData="Unlocking this representative will unlock their Representative ID at the end date you provided. Would you like to continue with unlocking?"
          this.$refs.modal.toggleOpen();
        } else {
          this.isModalVisible = false;
        }
        if( hasErrors){
          return false;
        } else {
          return true;
        }
      },
      deactivateRepresentative(action){
          if(action!=='confirmedDeactivate'){
              this.$refs.deactivateModal.toggleOpen();
          } else {
              let params = {
                  comment: this.comment
              };
              HTTP.post('/rad-svc/representative/deactivate/repid/' + this.representativeId, JSON.stringify(params)).then(response => {
                  this.$parent.loading = false;
                  this.$parent.showLockOrUnLock = false;
                  this.$parent.showRepResolution = false;

                  this.$refs.deactivateModal.toggleOpen();

                  if (response.data.status === 200) {
                      this.successfullyUpdated = true;
                      if (response.data.code === 'SUCCESSFUL_DEACTIVATED_REPRESENTATIVE') {
                          this.successMessage = 'Representative ID ' + this.representativeId + ' is successfully deactivated.'
                      }
                  } else {
                      this.failureMessage = 'Representative ID ' + this.representativeId + ' has transaction errors, please try after some time.';
                  }
              }).catch(err => {
                  this.$parent.loading = false;
                  console.log('Got Error while : ' + window.location.href);
                  router.push({name: "error"});
                  this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on button UpdateRepresentative'});
              });
          }
      },
      updateRepresentative(action) {
        this.isModalVisible = false;
        if( this.validateRepData(action) && !this.isModalVisible ) {
          this.$parent.loading = true;
          this.isModalVisible = false;
          let reasons = [];
          this.lockReasonValue.forEach(function( reason ) {
           reasons.push({reason:reason})
          })
          let lockStart, lockEnd
          if( document.getElementById('lockOutStartDate').value != null || document.getElementById('lockOutStartDate').value != '') {
            lockStart = new Date(document.getElementById('lockOutStartDate').value);
          }
          if( document.getElementById('lockOutEndDate').value != null || document.getElementById('lockOutEndDate').value != '' ) {
            lockEnd = new Date(document.getElementById('lockOutEndDate').value);
          }
          let params = {
            lockStart: this.createDateAsUTC(lockStart),
            lockEnd: lockEnd != null ? this.createDateAsUTC(lockEnd): null,
            reasons: reasons,
            comment: this.comment
          }
          HTTP.post('/rad-svc/representative/lock/repid/'+this.representativeId, JSON.stringify(params)).then(response => {
            this.$parent.loading = false;
            this.isModalVisible = false;
            this.$parent.showLockOrUnLock = false;
            this.$parent.showRepResolution = false;

            if( response.data.status === 200) {
              this.successfullyUpdated =true
              this.representativeId = response.data.repId;
              if (this.repStatus.toUpperCase() === response.data.repStatus.toUpperCase()) {
                //this.msg1 = 'updated'
                this.successMessage = 'Representative ID '+ this.representativeId +' is successfully updated. '
                  if( response.data.repStatus === 'ACTIVE') {
                      this.successMessage = this.successMessage + 'The Representative ID is now active.'
                  } else {
                      this.successMessage = this.successMessage + 'The Representative ID is not active.'
                  }
              } else if (response.data.repStatus === 'ACTIVE') {
                this.successMessage = 'Representative ID ' + this.representativeId +' is successfully unlocked. The Representative ID is now active.'
              } else if (response.data.repStatus === 'INACTIVE') {
                this.successMessage = 'Representative ID ' + this.representativeId +' is successfully unlocked. The Representative ID is now Inactive.'
              } else if (response.data.repStatus === 'LOCKED') {
                this.successMessage = 'Representative ID '+ this.representativeId +' is successfully locked. The Representative ID is not active.'
              }
            } else  {
              //this.failure =true;
              this.failureMessage = 'Representative ID '+this.representativeId+' has transaction errors, please try after some time.';
            }
          }).catch(err => {
            this.$parent.loading = false;
              console.log('Got Error while : '+window.location.href);
            router.push({name: "error"});
            //this.$ua.trackException('Error on button UpdateRepresentative', false);
            this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on UpdateRepresentative'});
          });
        }
        this.$gtm.trackEvent({category: 'UpdateRepresentative', action: 'click', label: 'Update Representative'});
      },

      mounted () {
      	this.$gtm.trackView('updateRepresentative')
      },
      checkUser: function () {
        if(this.$cookie.get('userRole') === 'USA ADM' ) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      },
      createDateAsUTC(date) {
        if( date === '' || date === null) {
          return null;
        }
        date= new Date(date)
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      },
      createDateWithoutTime(date) {
        if( date === '' || date === null) {
          return null;
        }
        date= new Date(date)
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
      },
      validateDate(dateStr) {
        const regExp = /^(\d\d?)\/(\d\d?)\/(\d{4})$/;
        let matches = dateStr.match(regExp);
        let isValid = matches;
        let maxDate = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if (matches) {
          const month = parseInt(matches[1]);
          const date = parseInt(matches[2]);
          const year = parseInt(matches[3]);

          isValid = month <= 12 && month > 0;
          isValid &= date <= maxDate[month] && date > 0;

          const leapYear = (year % 400 == 0)
            || (year % 4 == 0 && year % 100 != 0);
          isValid &= month != 2 || leapYear || date <= 28;
        }

        return isValid
      },
      loadRepIdSpinData(oktaEntitlementsArr) {
        let repIdSpinInfo = [];
        for ( let i = 0; i < oktaEntitlementsArr.length; i++) {
          let repIdSpinRecrd =[];
          if(oktaEntitlementsArr[i].updatedOn) {
            const currentDateWithoutFormatArr = oktaEntitlementsArr[i].updatedOn.split("T")[0].split("-");
            const currentDateWithFormat = currentDateWithoutFormatArr[1]+'/'+
                currentDateWithoutFormatArr[2]+'/'+currentDateWithoutFormatArr[0];
            repIdSpinRecrd.push(currentDateWithFormat);
          } else {
            repIdSpinRecrd.push('');
          }
          if(oktaEntitlementsArr[i].uniqueKey.spin){
            repIdSpinRecrd.push(oktaEntitlementsArr[i].uniqueKey.spin.toString());
          } else {
            repIdSpinRecrd.push('');
          }
          if(oktaEntitlementsArr[i].uniqueKey.spin){
            const result  = this.spinCompanyNameListForRep.find(item => item.spin === oktaEntitlementsArr[i].uniqueKey.spin.toString())
            if(result && result.companyName) {
              repIdSpinRecrd.push(result.companyName);
            } else {
              repIdSpinRecrd.push('');
            }
          }else {
            repIdSpinRecrd.push('');
          }
          repIdSpinRecrd.push(oktaEntitlementsArr[i].uniqueKey.userRole);
          repIdSpinInfo.push(repIdSpinRecrd);
        }
        repIdSpinInfo.sort((a,b)=> new Date(b[0]) - new Date(a[0]));
        return repIdSpinInfo;
      },
      toggleRefIdModalOpen(){
        this.$refs.refIdModal.toggleOpen();
      }
    },
    components: {
      ILongFormText, IFieldset, ILoader, IButton, ILabel, ITextarea, DatePicker,
      IInputGroup, IGrid, IInput, IInputNotification, IModal, INotification, MultiSelect, IDataTable
    },
  }

</script>
<style>
  input, select {
    min-height: 40px;
    display: block;
    padding: 8px 110px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }
  invalid {
    border-color: #B80014!important;
  }
</style>

