<template>
  <div>
    <i-long-form-text tabindex="0">
      <h1>USAC Representative Accountability Database - Terms and Conditions from lifelinerad.org</h1>
      <p class="indi-long-form-text__p--small">Last Modified: November 23, 2022</p>
      <p class="indi-long-form-text__p--small">This RAD Access Agreement (“Agreement”) applies only to the Representative Accountability Database (“RAD”) and all RAD-related systems and networks (including without limitation the RAD web portal contained in the National Lifeline Accountability Database (“NLAD”)) (collectively the “Database”) that is provided by the Universal Service Administrative Company (“USAC”). The Database was created in support of the federal Universal Service Lifeline Program to validate the identities of service provider representatives performing transactions in NLAD and the Lifeline National Eligibility Verifier (“National Verifier”) database system. It also supports and serves a similar purpose for other programs that use income or consumer participation in certain government benefit programs as eligibility criteria, such as the Affordable Connectivity Program ("ACP")<sup>1</sup>.</p>
      <p class="indi-long-form-text__p--small">USAC allows six types of users to access and use the Database: (1) individuals engaged in the sale of devices and services eligible for support (“Enrollment Representative”); (2) employees of State, territory, or District of Columbia agencies (“State Representative”) who are assisting applicants; (3) employees of United States federal government agencies (the “Agency” or “Agencies”); (4) representatives from Tribal Nation agencies ("Tribal Representative"); (5) representatives of entities approved by the Federal Communications Commission ("FCC") for the "Your Home, Your Internet" Pilot Program; and (6) representatives of entities approved by the FCC for the "Affordable Connectivity Program Navigator Pilot Program."</p>
      <p class="indi-long-form-text__p--small">“State, territory, or District of Columbia agencies” include State public utility commissions, State departments of health and human services, social service agencies, and other third parties approved by USAC for purposes of assisting individuals in applying for benefits.</p>
      <p class="indi-long-form-text__p--small">"Entities approved by the FCC for the 'Your Home, Your Internet' Pilot Program" include government entities and their partner third-party organizations serving federal housing assistance recipients, who have been approved by the FCC for the purpose of assisting federal housing assistance recipients with completing and submitting an application for the ACP<sup>2</sup>."</p>
      <p class="indi-long-form-text__p--small">"Entities approved by the FCC for the Affordable Connectivity Program Navigator Pilot Program" include neutral, trusted third party entities such as schools and school districts, or other local or state government entities, for purposes of assisting consumers with completing and submitting an application for the ACP<sup>3</sup>.</p>
      <p class="indi-long-form-text__p--small">This Agreement defines the relationships between both USAC and (i) Enrollment Representatives, (ii) State Representatives, (iii) each person who accesses the Database as authorized by and on behalf of the Enrollment Representatives and State Representatives, (iv) Tribal Representatives, and (v) employees of approved Agencies or Entities. Enrollment Representatives, State Representatives, Tribal Representatives, employees of approved Agencies or Entities, and each person who accesses the Database are referred to as “you” (and variations such as “your”) in this Agreement.</p>
      <p class="indi-long-form-text__p--small">BY ACCESSING OR USING OR REGISTERING FOR ACCESS TO THE DATABASE AS AN ENROLLMENT REPRESENTATIVE, STATE REPRESENTATIVE, TRIBAL REPRESENTATIVE, OR REPRESENTATIVE OR EMPLOYEE OF AN APPROVED AGENCY OR ENTITY, YOU CONFIRM THAT (1) YOU ARE AT LEAST 18 YEARS OLD AND (2) YOU HAVE READ THIS AGREEMENT AND (3) BOTH YOU AND THE AGENCY OR ENTITY ON WHOSE BEHALF YOU ARE ACCEPTING THIS AGREEMENT AND/OR ACCESSING OR USING THE DATABASE, ACCEPT THIS AGREEMENT. IF YOU CANNOT CONFIRM EACH OF (1) THROUGH (3) ABOVE, THEN YOU MUST NOT ACCEPT THIS AGREEMENT AND YOU MAY NOT ACCESS OR USE THE DATABASE.</p>
      <p class="indi-long-form-text__p--small">USAC may amend this Agreement at any time, with or without notice to you, by posting revised terms. You can see when this Agreement was last revised by referring to the “last modified” legend at the top of this Agreement. Your continued access to or use of the Database after USAC has posted revised terms signifies your acceptance of such revised terms. No other amendment to or modification of this Agreement will be binding unless in writing and signed by USAC’s duly authorized representative or posted to the site by USAC’s duly authorized representative.</p>

      <p class="indi-long-form-text__p--small">1. Access and Use; Restrictions and Rights.</p>

      <p class="indi-long-form-text__p--small">1.1 Right to Access and Use. Subject to the terms and conditions of this Agreement, USAC will make the Database available to you for access solely for the Authorized Purpose, defined below.</p>
      <p class="indi-long-form-text__p--small">(a) For Enrollment Representatives, State Representatives, and Tribal Representatives, the “Authorized Purpose” means use of the Database by your Authorized Users (defined in Section 1.2) in accordance with the applicable provisions of 47 C.F.R. §§ 54.406 and 54.1607 and solely to (i) enroll a current or prospective Enrollment Representative, State Representative, or Tribal Representative into the RAD system, (ii) update an existing Enrollment Representative’s, State Representative’s, or Tribal Representative’s information; and (iii) de-enroll an existing Enrollment Representative, State Representative, or Tribal Representative.</p>
      <p class="indi-long-form-text__p--small">(b) For approved Agencies or Entities, the “Authorized Purpose” means using the Database to oversee the administration of the Lifeline Program and similar programs that use income or consumer participation in certain government benefit programs as eligibility criteria, such as the Affordable Connectivity Program (see, e.g., 47 C.F.R. Part 54, Subparts E and P) (including, but not limited to prevention and detection of waste, fraud and abuse) and as required by other federal law and regulations.</p>

      <p class="indi-long-form-text__p--small">1.2 User Accounts.</p>
      <p class="indi-long-form-text__p--small">USAC will assign each person a unique user identification (“RepID”) to use the Database in furtherance of the Authorized Purpose, each of whom shall be, for purposes of this Agreement, an “Authorized User.” No one else may use the RepID to access the Database or undertake actions in NLAD or the National Verifier. You represent and warrant that all information you submit when you create a user account is accurate, current and complete, and that you will keep your account information accurate, current and complete. You are entirely responsible for maintaining the confidentiality of the information you hold for your account, including passwords, and for any and all activity that occurs under your account. You may not use the user identification or password of another account at any time, and you shall notify USAC immediately at <a href = "mailto: incident@usac.org">incident@usac.org</a> if you suspect any unauthorized use of a user identification or password. Authorized Users may access and use the Database solely in furtherance of their Authorized Purpose.</p>

      <p class="indi-long-form-text__p--small">1.3 Data and Privacy. Certain features of the Database may allow you to post, submit, store and manage personally identifiable information (“PII”). By submitting PII to the Database, you represent and warrant to USAC that you have all necessary rights, permissions, licenses, and/or consents to do so. You acknowledge that your access to and use of the Database and PII depends on compliance with applicable federal and state privacy laws and regulations and that the laws can and will change over time. You acknowledge that USAC will monitor and track your activity with respect to the Database, and you consent to such monitoring and tracking. The RAD portal is part of federal systems of records and all uses of your data are limited to the uses authorized by the Privacy Act, 5 U.S.C. § 552a(b)(1)-(12), the  systems of records notices (“SORNs”), and the Privacy Act Statement. USAC will treat such information, along with PII, all in accordance with the Privacy Act, the SORNs, and USAC’s privacy notice available on its website. You acknowledge that USAC may disclose information about you for purposes of administering the Lifeline Program and similar programs that use income or consumer participation in certain government benefit programs as eligibility criteria (such as the Affordable Connectivity Program), operating the Database, as permitted by the Privacy Act, 5 U.S.C. § 552a(b)(1)-(12), and for any other routine uses as described in the SORNs. You waive any claim you may have against USAC relating to its use of the foregoing information, except to the extent that such claim is caused by USAC’s willful misconduct.</p>
      <p class="indi-long-form-text__p--small">You must notify USAC immediately if you know or have a reasonable suspicion that any person or entity has accessed or used the Database without authorization, or has used the Database other than for an Authorized Purpose. If a security incident occurs with respect to the Database, you shall cooperate with USAC in its investigation of such incident and provide USAC with all relevant information.</p>

      <p class="indi-long-form-text__p--small">1.4 Restrictions. You may not use, or allow others to use, the Database in any manner other than as expressly allowed in this Agreement. You may not: (i) reverse engineer, decompile, disassemble, re-engineer or otherwise create or attempt to create or permit, allow, or assist others to create the source code of the Database; (ii) copy, modify or create derivative works of the Database; (iii) attempt to gain unauthorized access to the Database; or (iv) use the Database in whole or in part for any purpose except as expressly allowed under this Agreement (including without limitation allowing any distribution or sublicense of the Database or other access to the Database by any person or entity that is not an Authorized User in furtherance of the Authorized Purpose). In using the Database, you may not (a) falsely state or otherwise misrepresent information; (b) use or provide any fraudulent, misleading or inaccurate information; (c) defame, abuse, harass, or violate the rights of others, including without limitation others’ privacy rights or rights of publicity; (d) access or use (or attempt to access or use) another user’s account; (e) transmit any viruses, worms, trojan horses, malware, or other items of a destructive nature; (f) “frame” or “mirror” any portion of the Database; (g) use any robot, spider, site search/retrieval application or other manual or automatic device or process to retrieve, index, “data mine” or in any way reproduce or circumvent the navigational structure or presentation of the Database; (h) use the Database for illegal activity; or (i) probe, scan or test the vulnerability of the Database, breach the security or authentication measures on the Database, or take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Database.</p>

      <p class="indi-long-form-text__p--small">1.5 Reservation of Rights. As between the parties, USAC will at all times solely and exclusively own all right, title, and interest in and to the Database, all content therein, and all intellectual property or other rights in the foregoing, including but not limited to any and all modifications and derivative works. No licenses are granted by implication or estoppel, and all rights not expressly granted by USAC herein are expressly reserved by USAC.</p>

      <p class="indi-long-form-text__p--small">2. Term and Termination. The term of this Agreement commences upon your acceptance of this Agreement and terminates upon de-enrollment of an Enrollment Representative, State Representative, Tribal Representative, or Agency user by either party as provided herein. USAC may terminate this Agreement and/or suspend or terminate your use of or access to the Database at any time, for any reason or no reason, with or without notice to you. An Enrollment Representative, State Representative, Tribal Representative, or Agency user may terminate this Agreement or any Authorized User account at any time in accordance with USAC’s processes. Upon termination of this Agreement: (i) USAC will cease providing you and all related Authorized Users with access to the Database; and (ii) all issued passwords and RepIDs will be deactivated. Sections 1.5, 4, and 5 will survive termination of this Agreement.</p>

      <p class="indi-long-form-text__p--small">3. Communications Protocols. You must deliver and receive data to and from the Database via protocols and standards determined by USAC. You are responsible for procuring, installing, and maintaining all applications, equipment, telephone lines, communications interfaces, software (including web browser) and other hardware and software necessary to access the Database, and USAC is not responsible for any costs or expenses incurred by you in any such activities. You will comply with all laws, rules and regulations applicable to your use of the Database, including without limitation all applicable privacy laws, rules and regulations. Except as allowed by your Authorized Purpose, you will keep all information disclosed or made available by USAC to you hereunder, including without limitation the information received from the Database, in strict confidence, and shall not disclose, transmit, inform or make available such information to any entity or person, except to Authorized Users. You may use such information only in furtherance of an Authorized Purpose.</p>
      <p class="indi-long-form-text__p--small">You agree that USAC may provide you with any Communications in electronic format including by electronic mail (i.e., email) and that USAC may discontinue sending Communications it has formerly sent in paper format by First Class mail. “Communications” mean any written correspondence, notice, disclosure, document or other information that USAC provides to you, or that you sign or submit or agree to. The date of an electronic Communication for the purpose of determining any deadline by which you must respond is the later of the date on which the Communication was posted in NLAD or the date it was emailed to you. You agree to provide USAC with an accurate and complete email address, and all other contact information related to your User Account and to update this information as soon as it changes. You may request that USAC provide you with a duplicate paper copy of a specific electronic Communication by contacting USAC at (800) 234-9473. If there is a response deadline associated with this Communication, the response deadline will be calculated from the date it was posted in RAD or emailed to you. USAC reserves the right, but assumes no obligation, to provide a paper (instead of electronic) copy of any Communication. In that event, if there is a response deadline associated with this Communication, the deadline will be calculated from the date that appears on the Communication (i.e., letter date).</p>

      <p class="indi-long-form-text__p--small">4. Disclaimer. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, BY ACCEPTING THIS AGREEMENT, YOU ACKNOWLEDGE AND AGREE THAT THE DATABASE IS PROVIDED “AS IS” and “AS AVAILABLE” WITH NO WARRANTIES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. USAC DOES NOT WARRANT THE DATABASE WILL BE ERROR-FREE OR PROVIDED OR BE AVAILABLE WITHOUT INTERRUPTION OR WITH CONTINUOUS ACCESS, OR SECURE, OR THAT ERRORS OR DEFECTS CAN OR WILL BE FIXED, AND USAC ASSUMES NO LIABILITY WHATSOEVER FOR THE DATABASE, ITS CONTENTS OR YOUR USE OF THE DATABASE. THE FOREGOING DISCLAIMERS ALSO APPLY TO USAC’S SUPPLIERS AND LICENSORS OF PRODUCTS AND SERVICES. USAC has no obligation to provide updates, support and/or maintenance with respect to the Database. USAC makes no representations or warranties as to the accuracy or completeness of the Database, and you accept responsibility for, and acknowledge that you exercise your own independent judgment in, your use of the Database and any results obtained therefrom.</p>

      <p class="indi-long-form-text__p--small">5. Limitations of Liability. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT SHALL USAC OR ITS SUPPLIERS OR LICENSORS BE LIABLE FOR LOSS OR DAMAGE OF LOST PROFITS OR REVENUES OR SIMILAR ECONOMIC LOSS, FOR THE COST OF SUBSTITUTE GOODS OR SERVICES, OR FOR ANY CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT OR PUNITIVE DAMAGES, WHETHER IN CONTRACT, TORT OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH THE PERFORMANCE OR NON-PERFORMANCE OF THIS AGREEMENT OR THE DATABASE, EVEN IF USAC HAS BEEN ADVISED OF SUCH CLAIM. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, USAC’S TOTAL AGGREGATE LIABILITY FOR ANY CLAIMS ARISING UNDER THIS AGREEMENT OR OTHERWISE ARISING FROM THE TRANSACTIONS CONTEMPLATED HEREIN, REGARDLESS OF THE FORM OF ACTION (INCLUDING, BUT NOT LIMITED TO, ACTIONS FOR BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY, RESCISSION AND BREACH OF WARRANTY), SHALL NOT EXCEED $100.</p>

      <p class="indi-long-form-text__p--small">6. Miscellaneous. Each party is an independent contractor, and nothing herein will be deemed to constitute the parties as partners, agents or joint ventures. Any disputes or claims that you may bring against USAC under or related to this Agreement must be brought consistent with the requirements of 47 C.F.R Part 54, Subpart I. This Agreement (including without limitation any other USAC documentation referenced herein) embodies the entire agreement between the parties and supersedes all previous and contemporaneous agreements, understandings and arrangements, with respect to the subject matter hereof.</p>

      <p class="indi-long-form-text__p--small">Please print this Agreement for your records. </p>
      <p class="indi-long-form-text__p--small" style="border-top: 1px solid #E0E0E0;">
        <br/>
        <sup>1</sup> The Affordable Connectivity Program is the successor program to the Emergency Broadband Benefit Program.
        <br/>
        <sup>2</sup><i> See Affordable Connectivity Program et. al.,</i> WC Docket No. 21-450, Third Report and Order, FCC 22-65, at 12, para. 31-33; 39 (Aug. 8, 2022) (<i>Your Home, Your Internet Pilot Order</i>).
        <br/>
         <sup>3</sup><i> FCC Reforms, Modernizes Lifeline Program for Low-Income Americans,</i> Further Notice of Proposed Rulemaking, 27 FCC Rcd 6656 (2012); <i>Emergency Broadband Benefit Program</i>, WC Docket No. 20-445, Report and Order, 36 FCC Rcd 4612 (2021) at para. 39.</p>
    </i-long-form-text>
  </div>
</template>
<script>
  import { ILongFormText } from 'indigo-component-library';
  export default {
    name: "PrivacyStatement",
    components: { ILongFormText }
  }
</script>
