<template>
    <div>
        <div class="indi-container indi-margin-top-xl">
            <div class="indi-row header-row">
                <i-app-header slot="app-header"  id="header" name="header">
                    <div slot="title" class="header-font">
                        Representative Accountability Database
                    </div>
                </i-app-header>
                <router-view/>
                <i-app-footer :legal-links="[
                    {
                      id:  '/paperworkReductionActNotice',
                      href: '#/paperworkReductionActNotice',
                      text: 'Paperwork Reduction Act Notice'
                    },
                    {
                      id: '/termsAndConditions',
                      href: '#/termsAndConditions',
                      text: 'Terms & Conditions'
                    },
                    {
                      id: '/privacyStatement',
                      href: '#/privacyStatement',
                      text: 'Privacy Statement'
                    }
                  ]" :utility-links="
                  [
                    {
                       text: 'OMB Control Number: 3060-0819'
                    }
                  ]" :logos="[
                    {
                      src: 'static/img/fcc-logo_black.png',
                      alt: 'FCC Logo'
                    },
                    {
                      src: 'static/img/usac_logo.png',
                      alt: 'USAC Logo'
                    },
                  ]" />
            </div>
        </div>
      <!-- -->
    </div>
</template>
<script>
    import {
        IAppHeader, IPageShell,IButton } from 'indigo-component-library';
    import IAppFooter from "../components/AppFooter/AppFooter";
    export default {
        name: 'App',
        components: {IAppFooter, IAppHeader, IPageShell,IButton},
        mounted: function() {
            document.getElementsByClassName('indi-app-header__title-wrap')[0].setAttribute('style','width:65%')
        },
    }
</script>
<style>
    @media screen and (max-width: 1024px) {
        .indi-app-header__title {
            width: 600px !important;
        }
    }
    @media screen and (max-width: 768px) {
        .indi-app-header__title {
            width: 600px !important;
        }
        .header-font {
            font-size: 24px;
        }
    }

    @media screen and (max-width: 475px) {
        .indi-app-header__title {
            width: 100px !important;
        }

        .indi-app-header__title-wrap {
            display: none;
        }

        .indi-app-header__logo {
            padding: 15px
        }

        .indi-app-header__inner {
            background-image: none !important;
            background-color: white!important;
            background-image: url(./../../public/static/img/usac_logo.png) !important;
            background-position: unset !important;
            height: 100px !important;
            background-size:200px !important;
        }
        .indi-app-header__logo{
            display: none !important;
        }
        .indi-app-footer__logo{
            display: none !important;
        }
    }
</style>