<template>
    <div class="indi-long-form-text">

    <div v-show="isSuccess">
        <i-long-form-text>
            <h1 tabindex="0">Success!</h1>
            <br><br>
            <i-notification tabindex="0" variant="success" message="You have successfully updated your information."/>
            <i-fieldset>
                <p class="indi-long-form-text">You successfully updated your RAD information.
                    Confirmation will also be sent to your RAD email address. You may now close out this window.</p>
                <p class="indi-long-form-text">Thank you for keeping your information up to date!</p>
            </i-fieldset>
        </i-long-form-text>
    </div>

    <div v-show="!isSuccess">

    <i-long-form-text>
            <h1 tabindex="0">Update Information Review</h1>
        </i-long-form-text>

        <div id="notifications" tabindex="0">
        <i-notification style="margin-bottom: 10px" variant="error" v-show="duplicateEmailError" message="The email address that you provided is already associated with a Representative ID."/>
        <i-notification  style="margin-bottom: 10px" v-show="address1" message="You changed the address associated with your RAD registration."/>
        <i-notification  style="margin-bottom: 10px" v-show="emailInput" message="You changed the email address associated with your RAD registration."/>
        <i-notification  style="margin-bottom: 10px" v-show="securityQ1" message="You changed the security questions associated with your RAD registration."/>
        </div>

        <i-fieldset tabindex="0">
            <i-grid container="fluid" v-show="address1">
                <div class="indi-col-xs-3" style="padding-left: 110px;">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-weight: lighter">Address :</p>
                    </div>
                </div>
                <div class="indi-col-xs-9" style="padding-left: 0px;">
                    <p>{{address1}}<br>{{address2}}<br>{{city}}, {{state}} {{zipCode}}<br>{{urbanCode}} </p>
                </div>
            </i-grid>
            <br>
            <i-grid container="fluid" v-show="emailInput">
                <div class="indi-col-xs-3" style="padding-left: 125px;">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-weight: lighter">Email :</p>
                    </div>
                </div>
                <div class="indi-col-xs-9" style="padding-left: 0px;">
                    <p>{{emailInput}}</p>
                </div>
            </i-grid>
            <br>
            <div id="SecQuestions" v-show="securityA1">
            <i-grid container="fluid" >
                <div class="indi-col-xs-3" style="padding-left: 35px;">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-weight: lighter">Security Question 1 :</p>
                    </div>
                </div>
                <div class="indi-col-xs-9"  style="padding-left: 0px;">
                    <p>{{securityQ1}}</p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-3" style="padding-left: 80px;">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-weight: lighter">Your Answer :</p>
                    </div>
                </div>
                <div class="indi-col-xs-9" style="padding-left: 0px;">
                    <p>{{securityA1}}</p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-3" style="padding-left: 35px;">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-weight: lighter">Security Question 2 :</p>
                    </div>
                </div>
                <div class="indi-col-xs-9" style="padding-left: 0px;">
                    <p>{{securityQ2}}</p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-3" style="padding-left: 80px;">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-weight: lighter">Your Answer :</p>
                    </div>
                </div>
                <div class="indi-col-xs-9" style="padding-left: 0px;">
                    <p>{{securityA2}}</p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-3" style="padding-left: 35px;">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-weight: lighter">Security Question 3 :</p>
                    </div>
                </div>
                <div class="indi-col-xs-9" style="padding-left: 0px;">
                    <p>{{securityQ3}}</p>
                </div>
            </i-grid>
            <i-grid container="fluid">
                <div class="indi-col-xs-3" style="padding-left: 80px;">
                    <div class="indi-long-form-text">
                        <p class="indi-long-form-text__p" style="font-weight: lighter">Your Answer :</p>
                    </div>
                </div>
                <div class="indi-col-xs-9" style="padding-left: 0px;">
                    <p>{{securityA3}}</p>
                </div>
            </i-grid>
            </div>
        </i-fieldset>
        <i-grid>
            <div class="indi-col-sm-6 indi-form__input-group">
                <i-button label="Edit"  variant="tertiary" @click="$router.go(-1)"/>
            </div>
            <div class="indi-col-sm-6 indi-form__input-group--text-align-right indi-form__input-group" style="text-align: right;">
                <i-button label="Submit"  variant="primary" @click="submitUpdateInformation"/>
            </div>
        </i-grid>
        <br>
        <i-loader variant="overlay" v-if="loading"/>
    </div>
    </div>
</template>
<script>
    import router from '../router'
    import {HTTP} from '../http-common'
    import { EventBus } from "../event-bus.js";
    import {
        ILongFormText, IFieldset, IInputGroup,IInput,IGrid, ILabel,ILoader, IButton, INotification
    } from 'indigo-component-library';
    export default {
        name: "UpdateInformationReview",

        created: function () {
            this.loading = false;
        },
        data: function () {
            return {
                loading: false,
                state: '',
                address1: '',
                address2: '',
                city: '',
                zipCode: '',
                urbanCode: '',
                emailInput:'',
                email:'',
                securityQ1: '',
                securityQ2: '',
                securityQ3: '',
                securityA1: '',
                securityA2: '',
                securityA3: '',
                questions :[],
                isSuccess: false,
                duplicateEmailError: false
            }
        },
        components: {
            ILongFormText, IFieldset, IInputGroup,IInput,IGrid, ILabel,ILoader, IButton, INotification
        },
        methods: {
            submitUpdateInformation() {
                this.loading = true;
                let rc = this.$router.currentRoute.query.rc

                HTTP.post('/rad-svc/representative/update', sessionStorage.getItem(rc)).then(response => {
                    this.duplicateEmailError = false;

                    if( response.data.status === 400 ) {
                        this.loading = false;
                        sessionStorage.setItem('errorCode', response.data.code);
                        router.go(-1);
                    } else if( response.data.code === 'EMAIL_ALREADY_EXISTS' ) {
                        this.loading = false;
                        this.duplicateEmailError = true;
                    } else if( response.data.code === 'SUCCESSFUL_UPDATED_REPRESENTATIVE' ) {
                        this.loading = false;
                        this.isSuccess = true;
                        document.title = "Update Information - Success"
                        EventBus.$emit('email-updated', this.email );
                        sessionStorage.clear();
                    } else {
                        this.loading = false;
                        console.log('Got Error while : '+window.location.href);
                        router.push({name: "error"});
                    }

                }).catch(err => {
                    this.loading = false;
                    console.log('Got Error while : '+window.location.href);
                    console.log(err);
                    router.push({name: "error"});
                    sessionStorage.clear();
                });
                this.$gtm.trackEvent({category: 'SubmitUpdateInformation', action: 'click', label: 'Update Information Form Submit'});
            }
        },
        beforeMount() {
            if( sessionStorage.length === 0  ) {
                router.push({ name: "updateInformation"});
            }

            let rc = this.$router.currentRoute.query.rc;
            let data = JSON.parse(sessionStorage.getItem(rc));
            if( data ) {
                this.repId = data.repId;
                this.address1 = data.address1;
                this.address2 = data.address2;
                this.urbanCode = data.urbanizationCode
                this.state = data.state;
                this.city = data.city;
                this.zipCode = data.zipCode;
                this.emailInput = data.emailInput;
                this.email = data.signEmail;
                this.questions = JSON.parse(sessionStorage.allQuestions);
                if(data.question_answer !== undefined) {
                    this.securityQ1 = (this.questions.filter(function (jsonObject) {
                        return (jsonObject['id'] == data.question_answer[0].qid)
                    })[0]).question;
                    this.securityQ2 = (this.questions.filter(function (jsonObject) {
                        return (jsonObject['id'] == data.question_answer[1].qid)
                    })[0]).question;
                    this.securityQ3 = (this.questions.filter(function (jsonObject) {
                        return (jsonObject['id'] == data.question_answer[2].qid)
                    })[0]).question;
                    this.securityA1 = data.question_answer[0].answer;
                    this.securityA2 = data.question_answer[1].answer;
                    this.securityA3 = data.question_answer[2].answer;
                }
            }
        },
        mounted () {
            document.title = "Review Registration Information - Representative Accountability Database (RAD)";
        }
    }
</script>

