export default {
  en: {
    custom: {
      // the field name
      firstName: {
        // the rules
        required: "First Name is required.",
        regex: "Please enter a valid name.",
        min:"Please enter a valid name.",
        max:"Please enter a valid name."
      },
      middleName: {
        regex: "Please enter a valid name.",
        min:"Please enter a valid name.",
        max:"Please enter a valid name."
      },
      lastName: {
        // the rules
        required: "Last Name is required.",
        regex: "Please enter a valid name.",
        min:"Please enter a valid name.",
        max:"Please enter a valid name."
      },
      suffix: {
        regex: "Please enter a valid suffix.",
        max:"Please enter a valid suffix."
      },
      address1: {
        required: "Street Number and Name is required.",
        max:"Please enter a valid address."
      },
      address2: {
        regex: "Please enter a valid address.",
        max:"Please enter a valid address."
      },
      city: {
        required: "City is required.",
        max:"Please enter a valid city."
      },
      state: {
        required: "State is required.",
      },
      zipCode: {
        required: "Zip Code is required.",
        regex: "Please enter a valid zip code.",
      },
      dob: {
        required: "Date of Birth is required.",
        regex: "Please enter a valid name.",
        date_format:"Please enter a valid date of birth.",
        validDate:"Please enter a valid date of birth.",
        notUnder18:"Representative cannot be under 18.",
        notOver135:"Representative cannot be over 135."
      },
      l4ssn: {
        required: "Missing required field.",
        regex:"Please enter a valid last 4 SSN.",
        digits:"Please enter a valid last 4 SSN."
      },
      urbanizationCode:{
        required: "Please enter a valid address.",
        regex: "Please enter a valid address.",
        max:"Please enter a valid address."
      },
      securityQ1: {
        required: "Security Question 1 is required."
      },
      securityA1: {
        required: "Your Answer to Security Question 1 is required.",
        regex: "Please enter a valid answer.",
        max:"Please enter a valid answer."
      },
      securityQ2: {
        required: "Security Question 2 is required."
      },
      securityA2: {
        required: "Your Answer to Security Question 2 is required.",
        regex: "Please enter a valid answer.",
        max:"Please enter a valid answer."
      },
      securityQ3: {
        required: "Security Question 3 is required."
      },
      securityA3: {
        required: "Your Answer to Security Question 3 is required.",
        regex: "Please enter a valid answer.",
        max:"Please enter a valid answer."
      },
      certFlag:{
        required: "You must acknowledge both agreements.",
      },
      certFlag2:{
        required: "You must acknowledge both agreements.",
      },
      email:{
        required: "Missing required field.",
        regex: "Email format is invalid.",
        max:"Email format is invalid."
      }

    }
  }
}
