import axios from 'axios';

const API_URL = '/';

export const HTTP = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  }
})
export const email = ''