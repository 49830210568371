<template>
    <div>
        <div id="register" name="register" class="Hero-Image-with-words" tabindex="0">
            <div class="Hero-Image-with-word">
                <span class="Welcome-to-RAD">Welcome to RAD</span>
                <p/>
                <div class="The-Representative-Accountability-Database-RAD-v">The Representative Accountability Database (RAD) validates the identities of those who perform transactions in the National Lifeline Accountability Database (NLAD) and the National Verifier.</div>
                <p></p>
                <i-button label="Register" @click="register" variant="secondary" />
            </div>
        </div>
        <div class="row">
            <div class="column" style="background-color: #f7f7f7;color: #404040;" tabindex="0">
                <div class="menu-2" style="">Forgot Representative ID</div>
                <div class="menu-2-content">If you forgot your Representative ID, select the “Retrieve” button to receive an email with your ID.
                <br>
                <i-button label="Retrieve" variant="secondary" @click="clickForgotRepresentativeId" class="row-menu-button"style="border-color:#686868;color:#686868;" />
                </div>
            </div>
            <div class="column" style="background:#0039a3;border-color:#0039a3;color:#fff;" tabindex="0">
                <div class="menu-2" >Renew Annual Agreements</div>
                <div class="menu-2-content">
                    Representatives must complete the annual agreement at least once per year. Select the “Complete” button if you missed your deadline or if you want to complete the agreements now.
<br>
                    <i-button label="Complete" variant="primary" @click="clickAnnualAgreement" class="row-menu-button" style="background:#0039a3;border-color:white;" />
                </div>
            </div>
            <div class="column" style="background:#0039a3;border-color:#0039a3;color:#fff;" tabindex="0">
                <div class="menu-2">Update Information or Deactivate ID</div>
                <div class="menu-2-content">If your information has changed or if you made a mistake during registration, select the “Continue” button to learn how to make updates. You can also deactivate your Representative ID.
                <br>
                <i-button label="Continue" variant="primary" @click="updateRepresentative" class="row-menu-button" style="background:#0039a3;border-color:white;" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {IGrid, IButton,
        IAppHeader, IPageShell } from 'indigo-component-library';
    import router from '../../router'
    export default {
        name: 'App',
        components: {IAppHeader, IPageShell,IButton, IGrid},
        mounted: function() {
            document.getElementsByClassName('indi-app-footer__utility-link')[0].setAttribute('style','color:#343a40')
        },
        methods : {
            clickForgotRepresentativeId:function () {
                router.push({
                    name: "forgotRepresentative"
                });
                this.$gtm.trackEvent({category: 'ForgotRepresentativeId', action: 'click', label: 'Forgot Representative ID'});
            },
            clickAnnualAgreement:function () {
                router.push({
                    name: "annualAgreement"
                });
                this.$gtm.trackEvent({category: 'AnnualAgreement', action: 'click', label: 'Annual Agreement'});
            },
            updateRepresentative:function () {
                router.push({
                    name: "updateInformation"
                });
                this.$gtm.trackEvent({category: 'UpdateRepresentative', action: 'click', label: 'Update Representative'});
            },
            register:function () {
                router.push({
                    name: "Register"
                });
                this.$gtm.trackEvent({category: 'Register', action: 'click', label: 'Register'});
            },
        }

    }
</script>

<style lang="scss" scoped>
    @import './Landing';
</style>

