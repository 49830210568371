<template>
  <v-select
    class="indi-multi-select"
    :clearSearchOnSelect="clearSearchOnSelect"
    :closeOnSelect="closeOnSelect"
    :disabled="disabled"
    :filterable="filterable"
    :inputId="inputId"
    :multiple="multiple"
    :noDrop="noDrop"
    :options="currentOptions"
    :placeholder="placeholder"
    :pushTags="pushTags"
    :reduce="reduce"
    :selectOnTab="selectOnTab"
    :value="value"
    :tabindex="tabindex"
    :taggable="taggable"
    @input="inputEventPassthrough"
    @search:blur="blurEventPassthrough"
    @search:focus="focusEventPassthrough"
    />
</template>

<script>
import vSelect from 'vue-select'
import { IconClose, IconCaretDown } from 'indigo-component-library/src/components/Icon/Icons'
import IIcon from 'indigo-component-library/src/components/Icon/Icon'

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement(IIcon, {props: {size: 'xsmall', iconTitle: 'De-Select'}}, [createElement(IconClose)]),
  },
  OpenIndicator: {
    render: createElement => createElement(IIcon, [createElement(IconCaretDown)]),
  },
});

export default {
  name: 'IMultiSelect',
  components: {
    vSelect
  },
  data() {
    return {
      currentOptions: this.options
    }
  },
  props: {
    /**
     * Enables/disables clearing the search text when an option is selected.
     */
    clearSearchOnSelect: {
    	type: Boolean,
    	default: true
    },
    /**
     * Close the dropdown panel after each selection
     */
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    /**
     * Disable the entire component
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * When true, existing options will be filtered by the search text. Should not be used in conjunction with taggable.
     */
     filterable: {
      type: Boolean,
      default: true
     },
    /**
     * Sets the id of the input element.
     */
     inputId: {
       type: String
     },
    /**
     * Allow multiple options to be selected
     */
    multiple: {
      type: Boolean,
      default: true,
    },
    /**
     * Disable the dropdown entirely.
     */
     noDrop: {
       type: Boolean,
       default: false
     },
    /**
    * An array of strings or objects to be used as dropdown choices. If you are using an array of objects, vue-select will look for a label key (ex. [{label: 'Canada', value: 'CA'}]). A custom label key can be set with the label prop.
    */
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    /**
     * Equivalent to the placeholder attribute on an <input>.
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * When true, newly created tags will be added to the options list, use in conjunction with taggable.
     */
     pushTags: {
       type: Boolean,
       default: false
     },
    /**
     * When working with objects, the reduce prop allows you to transform a given object to only the information you want passed to a v-model binding or @input event.
     */
    reduce: {
      type: Function,
      default: option => option
    },
    /**
     * When true, hitting the 'tab' key will select the current select value
     */
    selectOnTab: {
      type: Boolean,
      default: false,
    },
    /**
     * Set the tabindex for the input field.
     */
    tabindex: {
      type: Number,
      default: null
    },
    /**
     * Enable/disable creating options from searchInput.
     */
    taggable: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    updateOptions(selectedOptions) {
      let newOptions = [...this.options];
      newOptions = newOptions.filter(o => !selectedOptions.includes(o));
      this.currentOptions = newOptions;
    },
    inputEventPassthrough(value) {
      this.updateOptions(value);
      /**
      * Passes the current value of the multi-select as an array of strings or an array of objects
      * @type {Event}
      */
      this.$emit("input", value);
    },
    blurEventPassthrough() {
      /**
      * Triggered when the text input loses focus. The dropdown will close immediately before this event is triggered.
      * @type {Event}
      */
      this.$emit("search:blur");
    },
    focusEventPassthrough() {
      /**
      * Triggered when the text input gains focus. The dropdown will open immediately before this event is triggered.
      * @type {Event}
      */
      this.$emit("search:focus");
    }
  }
}
</script>

<style lang="scss">
  @import '~indigo-component-library/src/components/MultiSelect/MultiSelect';
</style>
