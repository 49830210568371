<template>
  <div
    :class="[
      'indi-form__datepicker',
      disabled ? 'indi-form__datepicker-disabled' : '',
      size ? `indi-form__datepicker--${size}` : '',
    ]"
  >
    <v-date-picker
      mode="single"
      :value="selectedValue"
      v-on:input="handleDatepickerChange"
      :showDayPopover='false'
    >
      <template slot-scope='props'>
        <i-input
          :placeholder='placeholder'
          :value="selectedValueString"
          :disabled='disabled'
          :invalid='invalid'
          :theme='theme'
          :name="name"
          :id="id"
          @keyup="onKeyup($event)"
        />
        <i-icon class="indi-form__input-icon" :size="size">
          <IconCalendar />
        </i-icon>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
  import Vue from 'vue'
  import VCalendar from 'v-calendar'
  import 'v-calendar/lib/v-calendar.min.css';
  import IInput from 'indigo-component-library/src/components/Form/Input/Input'
  import {IconCalendar} from 'indigo-component-library/src/components/Icon/Icons'
  import IIcon from 'indigo-component-library/src/components/Icon/Icon'

  Vue.use(VCalendar, {
    popoverVisibility: 'focus',
  })

  export default {
    name: 'IDatePicker',
    props: {
      /**
       * Default date. Preferred format - MM/DD/YYYY.
       */
      value: {type: String},
      /**
       * Placeholder text.
       */
      placeholder: {type: String, default: 'MM/DD/YYYY'},
      /**
       * Input name.
       */
      name: {type: String},
      /**
       * Input ID.
       */
      id: {type: String},
      /**
       * Boolean to disable datepicker.
       */
      disabled: { type: Boolean, default: false },
      /**
       * Adds aria-invalid and error style when true.
       */
      invalid: { type: Boolean, default: false },
      /**
       * The component theme.
       * @values [ 'light-background' ]
       */
      theme: {
        type: String,
        validator: function (value) {
          // The value must match one of these strings
          return ['light-background'].indexOf(value) !== -1
        }
      },
      /**
       * The size of the component.
       * @values ['small', 'medium']
       */
      size: {
        type: String,
        default: 'medium',
        validator: function (value) {
          // The value must match one of these strings
          return ['small', 'medium'].indexOf(value) !== -1
        }
      },
    },
    data() {
      return {
        selectedValue: this.value ? new Date(this.value) : '',
      };
    },
    components: {
      IInput,
      IIcon,
      IconCalendar,
    },
    computed: {
      selectedValueString() {

        return typeof(this.selectedValue) === 'object' ? this.formatDate(this.selectedValue) : '';
      }
    },
    watch: {
      value(newVal, oldVal) {
        if( newVal !== '')
            this.selectedValue = new Date(newVal);
        else {
          this.selectedValue = ''
        }
      }
    },
    methods: {
      formatDate(date) {

        return `${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}/${date.getFullYear()}`
      },
      handleDatepickerChange(date) {
        const formattedDate = this.formatDate(date);
        this.$emit('input', formattedDate);
        this.selectedValue = new Date(formattedDate);
      },
      onKeyup: function(e) {
        /**
       * Passthrough keyup event
       * @type {Event}
       */
        this.$emit('keyup', e);
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~indigo-component-library/src/components/Form/DatePicker/DatePicker.scss';
</style>
