<template>
  <div>
    <i-long-form-text tabindex="0">
      <h1> Privacy Act Statement</h1>
      <p class="indi-long-form-text__p--intro">This Privacy Act Statement explains how we are going to use the personal information you are entering into this form.</p>
      <p class="indi-long-form-text__p--small">The Privacy Act is a law that requires the Federal Communications Commission (FCC) and the Universal Service Administrative Company (USAC) to explain why we are asking individuals for personal information and what we are going to do with this information after we collect it.
      </p>
      <p class="indi-long-form-text__p--small">
        <strong>Authority:</strong> 47 U.S.C. §254; Consolidated Appropriations Act, 2021, Public Law 116–260, div. N, tit. IX, § 904; 47 CFR § 54.400 et seq.; 47 CFR § 54.1607.
      </p>
      <p class="indi-long-form-text__p--small">
        <strong>Purpose:</strong> We are collecting this personal information so that we can verify your identity and assign to you a Representative ID that we will use to monitor your activity in USAC’s electronic systems for Lifeline and similar programs that use income or consumer participation in certain government benefit programs as eligibility criteria, such as the Emergency Broadband Benefit Program, ensuring greater accountability for your actions when completing transactions related to such programs. We access, maintain, and use your personal information in the manner described in the Lifeline System of Records Notice (SORN), FCC/WCB-1, which was published in 86 Fed. Reg. 11526 (Feb. 25, 2021), and the Emergency Broadband Benefit Program SORN, FCC/WCB-3, which was published in 86 Fed. Reg. 11523 (Feb. 25, 2021).
      </p>
      <p class="indi-long-form-text__p--small">
        <strong>Routine Uses:</strong> We may share the personal information you enter into this form with other parties for specific purposes, such as:
      </p>
      <p class="indi-long-form-text__p--small">
        <ul>
          <li>With contractors that help us operate the Lifeline program and similar programs that use income or consumer participation in certain government benefit programs as eligibility criteria, such as the Emergency Broadband Benefit Program;</li>
          <li>With other federal, state and Tribal government agencies that help us determine your adherence to the Lifeline program rules and the rules governing similar programs that use income or consumer participation in certain government benefit programs as eligibility criteria, such as the Emergency Broadband Benefit Program;</li>
          <li>With the telecommunications companies and broadband providers that are linked to your Representative ID;</li>
          <li>With other federal agencies or to other administrative or adjudicative bodies before which the FCC is authorized to appear;</li>
          <li>With appropriate agencies, entities, and persons when the FCC suspects or has confirmed that there has been a breach of information; and</li>
          <li>With law enforcement and other officials investigating potential violations of Lifeline and other program rules.</li>
        </ul>
      </p>
      <p class="indi-long-form-text__p--small">
        A complete listing of the ways we may use your information is published in the Lifeline SORN and the Emergency Broadband Benefit Program SORN described in the "Purpose" paragraph of this statement.
      </p>
      <p class="indi-long-form-text__p--small">
        <strong>Disclosure:</strong> You are not required to provide the information we are requesting, but if you do not, you will not be eligible to receive a Representative ID.
      </p>
    </i-long-form-text>
  </div>
</template>
<script>
  import { ILongFormText } from 'indigo-component-library';
  export default {
    name: "PrivacyStatement",
    components: { ILongFormText }
  }
</script>
