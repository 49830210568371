<template>
    <div style="vertical-align: middle;">
<div style="color: #ffffff;
font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;font-weight: 400;text-align: right;" v-show="userEmail"> {{userEmail}}</div>
       <!-- is Logout used in RAD? or is this copied in? - Ans: this is used in the case of Internal user -->
   <i-button label="Log Out" style="margin-left: 120px;color: white; background: transparent; !important" v-show="enableLogOut" @click="openModal"></i-button>
    <i-modal header="Confirm Log Out?" body='You are about to leave the Lifeline RAD System. Click on Continue if you wish to proceed.'
             tertiary-button-label="Cancel" primary-button-label="Continue"  ref="modal" aria-label="Close Modal" v-on:on-primary-button-click="logout">
    </i-modal>
</div>
</template>
<script>
    import { EventBus } from "../event-bus.js";
    import {IButton, IModal } from 'indigo-component-library'
    import axios from 'axios';
    export default {
        name: "EmailHeader",
        data: function () {
            return {
                userEmail: '',
                enableLogOut:false,

            }
        },
        methods: {
            setEmail: function(email) {
                this.userEmail = email;
            },
            setLogOutBtn:function(sessionId) {
                this.enableLogOut = sessionId != null ? true:false;
            },
            logout:function() {
                this.$cookie.delete('OKTA-SESSIONID');
                this.$cookie.delete('userRole');
                let url = this.$cookie.get('oktaUrl');

                axios({
                    method: 'DELETE',
                    url: url+"/api/v1/sessions/me",
                    withCredentials: true,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                }).then(response => {
                    EventBus.$emit('sessionId', null);
                    this.$cookie.delete('oktaUrl');
                    window.location = url
                }).catch(err => {
                    this.$cookie.delete('oktaUrl');
                    window.location = url
                });
            },
            openModal:function() {
                this.$refs.modal.toggleOpen();
            },
        },
        mounted() {
            EventBus.$on('email-updated', p_email => this.setEmail(p_email));
            EventBus.$on('sessionId', p_sessionId=> this.setLogOutBtn(p_sessionId));
            let sessionId = this.$cookie != undefined ? this.$cookie.get('OKTA-SESSIONID') : null ;
            if( sessionId != null ) {
                this.enableLogOut = true
            } else {
                this.enableLogOut = false
            }
        },
        watch:{
            $route (to, from){
                let sessionId = this.$cookie != undefined ? this.$cookie.get('OKTA-SESSIONID') : null ;
                if( sessionId != null ) {
                    this.enableLogOut = true
                } else {
                    this.enableLogOut = false
                }
            }
        },
        components: { IButton, IModal}
    }

</script>