<template>
  <div>
    <div v-show="!isSuccess">
      <i-long-form-text>
        <h1 tabindex="0">Annual Agreement</h1>
      </i-long-form-text>
      <div v-show="showRepIdEmailInput">
        <p class="indi-long-form-text__p--small" tabindex="0">Provide your Representative ID or the email address you used during RAD registration to receive a link to complete the Annual Agreements.</p>
        <i-fieldset>
          <i-grid>
            <i-input-group label="Representative ID or Email" label-for="repIdemail" class="indi-col-lg-8">
              <i-input aria-label="Representative ID or Email" id="repIdorEmail" name="repIdoremail"  maxlength="100" v-model="repIdorEmail" @change="isRepIdorEmailValid" type="text"  :invalid="inputError !== ''"/>
              <i-help-text text="123abc456 or example@email.com" />
              <i-input-notification v-bind:text="inputError" aria-live="assertive" aria-atomic="true"/>

            </i-input-group>
          </i-grid>
        </i-fieldset>
        <i-grid>
          <div class="indi-col-xs-3">
            <Recaptcha ref="recaptchaRef" @AxiosReturned = 'AxiosReturned'></Recaptcha>
          </div>
          <div :class="(recaptchaActive) ? 'recaptcha-button' : ''" class="indi-col-xs-offset-10 right-side-button">
            <i-button label="Submit"  variant="primary"  @click="checkRepOrEmail"></i-button>
          </div>
        </i-grid>
        <p/>
      </div>
    </div>
    <i-loader variant="overlay" v-if="loading" />
  </div>
</template>
<script>
  import {
    ILongFormText, IFieldset, IInputGroup,IInput,IGrid, ILabel,
    IButton,IInputNotification, IHelpText,ILoader, INotification } from 'indigo-component-library';
  import axios from 'axios'
  import router from '../router'
  import Recaptcha from "./Recaptcha"
  import {HTTP} from '../http-common'
  import { EventBus } from "../event-bus.js";
  export default {
    data: function () {
      return {
        isSuccess:false,
        recaptchaActive: false,
        loading : false,
        showRepIdEmailInput : true,
        email: '',
        repId: '',
        repIdorEmail: '',
        isError: false,
        isRecaptchaError:false,
        inputError: '',
        inputBoxClass: {
          inputBox: 'indi-form__input',
          inputBoxNormal: 'indi-form__input',
          inputBoxError: 'indi-form__input indi-form__input--has-error',
        },
        showRepEmailError:false,
        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,240}))$/,
        accountUnlockTime:'30'
      }
    },
    name: "AnnualAgreement",
    components: {
      Recaptcha, ILoader, ILabel,
      ILongFormText, IFieldset, IInputGroup,IInput,IGrid,
      IButton,IInputNotification, IHelpText, INotification
    },

    methods: {
      AxiosReturned: function(isRecaptchaActive){
        this.recaptchaActive = isRecaptchaActive;
      },
      checkRepOrEmail : function() {
        let recaptchatoken;
        if (this.$refs.recaptchaRef.isRecaptchaActive) {
          this.$refs.recaptchaRef.checkIfRecaptchaVerified();
          recaptchatoken = this.$refs.recaptchaRef.recaptchaToken;
          this.isRecaptchaError = this.$refs.recaptchaRef.isRecaptchaActive && !this.$refs.recaptchaRef.recaptchaVerified;
        }
        this.isRepIdorEmailValid();
        if ( (!this.isError && !this.isRecaptchaError) || this.emailInput.endsWith('smoketest@smoketest.org')) {
          this.loading = true;
          axios({
            method: 'post',
            url: '/rad-svc/representative/generateAnnualAgreementLink',
            params: {
              'email': this.email,
              'repId': this.repId,
              'g-recaptcha-response': recaptchatoken
            }
          }).then(response => {
            this.loading = false;
            if (response.data.status === 200 && response.data.pass === true) {
              router.push({
                name: "success",
                query: {re: response.data.re, rc: response.data.rc},
                params: {isRouted: true, annualAgreement: true}
              });
              EventBus.$emit('email-updated', response.data.email );
            } else if (response.data.code === 'UNRESOLVED_ERRORS') {
              this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
              this.inputError = response.data.headerMessage;
              this.loading = false;
            } else if (response.data.code === 'NOT_FOUND') {
              this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
              this.inputError = 'Representative ID or email not found.';
              this.loading = false;
            }
            else {
              this.$refs.modal.toggleOpen();
            }
          }).catch(err => {
            this.loading = false;
            let responseError = err.response == undefined ? null : err.response.data;
            if (responseError !== null && responseError.status === 400) {
              let emailerror = responseError.errors.filter(el => el.field === 'email')
              let recaptchaerror = responseError.errors.filter(el => el.field === 'recaptcha')
              if (emailerror.length > 0) {
                this.inputError = emailerror[0].description;
                this.isError = true;
              }
              if (recaptchaerror.length > 0) {
                this.$refs.recaptchaRef.setRecaptchaError(recaptchaerror[0].description)
              }
            } else {
              console.log('Got Error while : '+window.location.href);
              router.push({name: "error"});
            }
            this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on Landing page'});
          });

        }
        this.$gtm.trackEvent({category: 'AnnualAgreement', action: 'click', label: 'Annual Agreement'});
      },
      isRepIdorEmailValid: function () {
        if (this.repIdorEmail === "") {
          this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
          this.inputError = 'Representative ID or email is required.';
          this.isError = true;
        } else if (!this.reg.test(this.repIdorEmail) || this.repIdorEmail.length > 240) {
          if (!/\D/.test(this.repIdorEmail) || !/[^a-zA-Z]/.test(this.repIdorEmail) || /\W/.test(this.repIdorEmail) || this.repIdorEmail.length > 9 || this.repIdorEmail.length < 9 ) {
            this.inputError = 'Please enter a valid Representative ID or email.';
            this.isError = true;
          } else {
            this.repId= this.repIdorEmail;
            this.inputError = '';
            this.isError = false;
          }
        } else {
          this.email= this.repIdorEmail;
          this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxNormal;
          this.inputError = '';
          this.isError = false;
        }
      }
    }
  }
</script>

