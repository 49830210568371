<template>
    <i-page-shell :full-bleed=true>
        <i-app-header slot="app-header"  id="header" name="header">
            <div slot="title">
                Representative Accountability Database
            </div>
            <div slot="actions">
                <EmailHeader></EmailHeader>
            </div>
        </i-app-header>
        <div id="app-margins" style="margin-right: 50px;margin-left: 50px;">
            <router-view/>
        </div>
        <AppFooter slot="app-footer" :legal-links="footerLinks" legal="OMB Control Number: 3060-0819"></AppFooter>
    </i-page-shell>
</template>
<script>
     import {
       IAppHeader, IPageShell,IButton, IModal } from 'indigo-component-library'

    import AppFooter from "./../components/AppFooter/AppFooter";
    import EmailHeader from './../components/EmailHeader'
    const default_layout = "default";
    export default {
        name: 'App',
        data: function () {
            return {
                showEmail:false,
                isModalVisible:false,
                isLogOut:false,
                enableLogOut:false,
                session:'',
                footerLinks:[]
            }
        },
        beforeMount: function() {
            let url = document.location.origin+ document.location.pathname
            this.footerLinks.push({href: url+'#/paperworkReductionActNotice',text: 'Paperwork Reduction Act Notice'})
            this.footerLinks.push({href: url+'#/termsAndConditions',text: 'Terms & Conditions'})
            this.footerLinks.push({href: url+'#/privacyStatement',text: 'Privacy Statement'})

        },
        mounted: function() {
            document.getElementsByClassName('indi-app-header__title-wrap')[0].setAttribute('style','width:65%')
            var app = this;
            if ("-ms-scroll-limit" in document.documentElement.style && "-ms-ime-align" in document.documentElement.style) {
                window.addEventListener("hashchange",
                    function () {
                        var currentPath = window.location.hash.slice(1);
                        if (app.$route.path !== currentPath) {
                            app.$router.replace(currentPath);
                        }
                    }, false);
            }
        },
        components: { IAppHeader, AppFooter, IPageShell,IButton, IModal, EmailHeader}
    }
</script>
<style>
    .indi-app-footer__legal-link{
        color: #0070f4 !important;
    }
    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        .emailInHeader{
            color: #FFFFFF;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            font-weight: 400;
            text-align: right;
            vertical-align: middle;
            margin-top:-125px;
            height: 125px;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        .emailInHeader{
            color: #FFFFFF;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            font-weight: 400;
            text-align: right;
            vertical-align: middle;
            margin-top:-125px;
            height: 125px;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .emailInHeader{
            color: #FFFFFF;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            font-weight: 400;
            text-align: right;
            vertical-align: middle;
            margin-top:-125px;
            height: 125px;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        .emailInHeader{
            color: #FFFFFF;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            font-weight: 400;
            text-align: right;
            vertical-align: middle;
            margin-top:-125px;
            height: 125px;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        .emailInHeader{
            color: #FFFFFF;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            font-weight: 400;
            text-align: right;
            vertical-align: middle;
            margin-top:-175px;
            height: 125px;
        }
    }

    @media only screen and (max-width: 475px) {
      .right-side-button {
        margin-left: 50% !important;
        padding: 0px 5px;
      }
      .recaptcha-button {
        margin-top: 90px;
      }
      .review_label {
        text-align: left !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        min-width: 55px !important;
      }
      .review_value {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
      #app-margins {
        margin-left: 15px;
        margin-right: 15px !important;
      }
    }
</style>