<template>
    <label
            :class="[
      'indi-form__checkbox',
      invalid ? 'indi-form__checkbox--has-error' : '',
      disabled ? 'indi-form__checkbox--disabled' : '',
      isfocused ? 'indi-form__checkbox--has-focus' : '',
      size ? `indi-form__checkbox--${size}` : '',
      hiddenLabel ? `indi-form__checkbox--hidden-label` : '',
    ]"
            :for="checkboxId"
    >
        <input
                type="checkbox"
                class="indi-form__checkbox-input"
                v-bind="$attrs"
                :aria-invalid="invalid"
                :id="checkboxId"
                :name="name"
                :value="value"
                :required="required"
                :disabled="disabled"
                :checked="checked"
                @change="onChange($event.target.checked)"
                @focus="onFocus($event.target.checked); toggleFocus($event);"
                @blur="onBlur($event.target.checked); toggleFocus($event);"
        />
        <span class="indi-form__checkbox-icon"></span>
        <span class="indi-form__checkbox-text" :class="{'indi-form__checkbox-text--a11y-hidden': hiddenLabel}">
              <!-- @slot Slot for alternative label layout -->
                <slot name="label">{{ label }}</slot>
        </span>
    </label>
</template>

<script>
    export default {
        name: 'ICheckbox',
        inheritAttrs: false,
        model: {
            prop: 'checked',
            event: 'change'
        },
        props: {
            /**
             * The label of the checkbox instance.
             */
            label: { type: String },
            /**
             * The id for the checkbox instance.
             */
            id: { type: String },
            /**
             * The name for the checkbox instance.
             */
            name: { type: String },
            /**
             * The value for the checkbox instance.
             */
            value: { type: String },
            /**
             * Boolean to check checkbox by default.
             */
            checked: { type: Boolean, default: false },
            /**
             * Boolean to disable checkbox.
             */
            disabled: { type: Boolean, default: false },
            /**
             * Sets required=required by default.
             */
            required: { type: Boolean, default: false },
            /**
             * The size of the component.
             * @values ['small', 'medium']
             */
            size: {
                type: String,
                default: 'medium',
                validator: function (value) {
                    // The value must match one of these strings
                    return ['small', 'medium'].indexOf(value) !== -1
                }
            },
            /**
             * Adds aria-invalid and error style when true.
             */
            invalid: { type: Boolean, default: false },
            /**
             * Make the label accessibly hidden
             */
            hiddenLabel: { type: Boolean, default: false },
        },
        data(){
            return {
                isfocused: false
            }
        },
        methods: {
            onChange: function(e) {
                /**
                 * Passthrough change event
                 * @type {Event}
                 */
                this.$emit('change', e);
            },
            onFocus: function(e) {
                /**
                 * Passthrough focus event
                 * @type {Event}
                 */
                this.$emit('focus', e);
            },
            onBlur: function(e) {
                /**
                 * Passthrough blur event
                 * @type {Event}
                 */
                this.$emit('blur', e);
            },
            toggleFocus: function(e) {
                if (e.type === 'focus') {
                    this.isfocused = true
                }
                if (e.type === 'blur') {
                    this.isfocused = false
                }
            }
        },
        computed: {
            checkboxId: function () {
                return this.id ? this.id : 'checkbox-' + this._uid
            }
        }
    }
</script>
<style scoped lang="scss">
    @import '~indigo-component-library/src/components/Form/Checkbox/Checkbox';
</style>
