import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VeeValidate ,{ Validator }  from 'vee-validate'
import messages from './radMessages'
import VueCookie  from 'vue-cookie'
import vSelect from 'vue-select'
import VueGtm from '@gtm-support/vue2-gtm'

Vue.component('v-select', vSelect)

import Default from "./layouts/Default.vue";
import Landing from "./layouts/Landing.vue";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VeeValidate);
Validator.localize(messages);

Vue.component("default-layout", Default);
Vue.component("landing-layout", Landing);

new Vue({
    router: router,
    render: h => h(App)
}).$mount('#app');

Validator.localize(messages);

// Tell Vue to use the plugin
Vue.use(VueCookie);

Validator.extend('notUnder18', (value) => {
    let age = 18;
    let myDate = new Date(value);
    let currDate = new Date();

    myDate.setDate(myDate.getDate() - 1);
    currDate.setFullYear(currDate.getFullYear() - age);
    currDate.setHours(0, 0, 0, 0);
    return currDate > myDate;
});

Validator.extend('matchSignature', {
    params: ['fullLegalName'],
    validate(value, {fullLegalName}) {
        return fullLegalName !== '' && value && fullLegalName.toUpperCase() === value.toUpperCase();
    }
});

Validator.extend('validDate', (value) => {

    if(value.split('/')[0] === '00' || value.split('/')[1] === '00'|| value.split('/')[2] === '0000' ) {
        return false
    }
    // First check for the pattern
    if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value))
        return false;

    // Parse the date parts to integers
    var parts = value.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if(year < 1000 || year > 3000 || month == 0 || month > 12)
        return false;

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
});

Validator.extend('notOver135', (value) => {
    let age = 135;
    let myDate = new Date(value);
    let currDate = new Date();

    myDate.setDate(myDate.getDate() - 1);
    currDate.setFullYear(currDate.getFullYear() - age);
    currDate.setHours(0, 0, 0, 0);
    return currDate < myDate;
});

Vue.use(VueGtm, {
        id:'GTM-M24GZD6',
        defer: false,
        enabled: true,
        debug: false,
        loadScript: true,
        vueRouter: router,
        trackOnNextTick: false
    }
);
