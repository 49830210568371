<template>
  <div >
    <i-long-form-text>
      <h1 tabindex="0">Representative ID Review</h1>
    </i-long-form-text>
    <i-fieldset>
      <i-grid>
        <i-label text="Representative ID" class="indi-col-lg-3" style="padding-top:15px;"/>
        <i-input-group class="indi-col-lg-5">
          <i-input id="representativeId" aria-label="Representative Id" v-model="representativeId" @change="isRepresentativeIdValid" type="text" :invalid="inputError !== ''"/>
          <i-input-notification v-bind:text="inputError" aria-live="assertive" aria-atomic="true"/>
        </i-input-group>
        <i-button label="Search" variant="primary" @click="searchRepresentative" ></i-button>
      </i-grid>
    </i-fieldset>
    <LockOrUnLock ref="repLockout" :load="showLockOrUnLock"></LockOrUnLock>
    <RepResolution ref="repResolution" :load="showRepResolution"></RepResolution>
    <i-loader variant="overlay" v-if="loading"/>
  </div>
</template>

<script>
  import {HTTP} from '../http-common'
  import { EventBus } from "../event-bus.js";
  import router from '../router'
  import axios from 'axios'
  import LockOrUnLock from '../components/RepLockout'
  import RepResolution from '../components/RepResolution'
  import {
    ILongFormText, IFieldset, ILoader, IButton, ILabel, IInputGroup, IGrid, IInput, IInputNotification } from 'indigo-component-library';

  export default {
    data: function () {
      return {
        showRepResolution: false,
        showLockOrUnLock: false,
        showSearchREpresentative:false,
        representativeId: '',
        loading: false,
        inputError: '',
        isError: false,
        inputBoxClass: {
          inputBox: 'indi-form__input',
          inputBoxNormal: 'indi-form__input',
          inputBoxError: 'indi-form__input indi-form__input--has-error',
        }
      }
    },
    name: "SearchRepresentative",
    components: {
      ILongFormText, IFieldset, ILoader, IButton, ILabel,
      IInputGroup, IGrid, IInput, IInputNotification,
      LockOrUnLock, RepResolution
    },
    beforeMount() {
      this.loading = true;
      axios({
        method: 'get',
        url: '/rad-svc/props'
      }).then(response => {
        let oktaUrl = response.data.oktaUrl
        axios({
          method: 'get',
          url: oktaUrl+"/api/v1/sessions/me",
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }).then(response => {
          EventBus.$emit('sessionId', response.data.id );
          this.$cookie.set('OKTA-SESSIONID', response.data.id, { expires: '30m' });
          this.$cookie.set('oktaUrl', oktaUrl, { expires: '30m' });
          axios({
            method: 'get',
            url: '/rad-svc/user',
            withCredentials: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          }).then(response => {
            if( response.status === 200) {
              this.$cookie.set('userRole', response.data.userRole, { expires: '30m' });
              this.showSearchREpresentative=true;
              this.loading = false;
            }
          }).catch(err => {
            this.loading = false;
            console.log('Got Error while : '+window.location.href);
            router.push({name: "error"});
          });
        }).catch(err => {
          //router.push({name: "error"});
          sessionStorage.clear();
          this.loading = false;
          window.location.href = oktaUrl
        });

      }).catch(err => {
        console.log('Got Error while : '+window.location.href);
        router.push({name: "error"});
      });
    },
    methods: {
      searchRepresentative : function() {
        this.isRepresentativeIdValid();
        this.$refs.repResolution.clearErrors();
        this.$refs.repLockout.clearErrors();

        if(!this.isError) {
          this.loading = true;
          this.showRepResolution = false;
          this.showLockOrUnLock = false;
          HTTP.get('/rad-svc/representative/repid/' + this.representativeId
          ).then(response => {
            if (response.data.code === 'INVALID_REQUEST' || response.data.code === 'INVALID_DATA') {
              this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
              this.inputError = 'Invalid Representative ID.';
              this.loading = false;
            } else if( response.data.representative != undefined || response.data.representative != '' ) {
              this.loading = false;
              if( response.data.representative.status === 'ACTIVE' || response.data.representative.status === 'LOCKED' || response.data.representative.status === 'INACTIVE') {
                this.showLockOrUnLock = true;
                this.$refs.repLockout.clearErrors(response.data.representative);
                this.$refs.repLockout.loadRepData(response.data);
                document.title = "Representative ID Review - Representative Accountability Database (RAD)";
              } else {
                this.showRepResolution = true;
                this.$refs.repResolution.loadRepData(response.data)
              }
            } else {
              this.loading = false;
              this.inputError = 'Invalid Representative ID.';
            }
          }).catch( err => {
            let responseError = err.response == undefined ? null : err.response.data;
            this.loading = false;
            if (responseError !== null && responseError.status === 400 && responseError.message === 'REPRESENTATIVE_NOT_FOUND') {
              this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
              this.inputError = 'Representative ID not found.';
            } else {
              console.log('Got Error while : '+window.location.href);
              router.push({name: "error"});
            }
          });
        }
        this.$gtm.trackEvent({category: 'SearchRepresentative', action: 'click', label: 'Search Representative'});
      },
      isRepresentativeIdValid: function () {
        if (this.representativeId === "") {
          this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
          this.inputError = 'Missing required field.';
          this.isError = true;
        } else if (!/\D/.test(this.representativeId) || !/[^a-zA-Z]/.test(this.representativeId) || /\W/.test(this.representativeId) || this.representativeId.length > 9 || this.representativeId.length < 9 ) {
          this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
          this.inputError = 'Invalid Representative ID.';
          this.isError = true;
        } else {
          this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxNormal;
          this.inputError = '';
          this.isError = false;
        }
      },
    }
  }
</script>


