<template>
  <div>
    <div v-show="!isSuccess">
      <i-long-form-text  tabindex="0">
        <h1 tabindex="0">Update Information or Deactivate ID</h1>
      </i-long-form-text>
      <div v-show="showRepIdEmailInput">

        <div v-show="showRepError">
          <i-notification variant="error" >Your answers to your security questions did not match our records. <b>You will not be able to attempt to verify your identity again for {{accountUnlockTime}} minutes.</b>
            Please visit the Update Information page to attempt to verify your identity again after {{accountUnlockTime}} minutes.</i-notification>
        </div>

        <p class="indi-long-form-text__p--small" tabindex="0">Provide your Representative ID or the email address you used during RAD registration to update your address, email address, or security questions. You can also deactivate your Representative ID. If you need to update any other information please contact USAC at <a style="color: #0052eb;" href="mailto:LifelineProgram@usac.org"> LifelineProgram@usac.org</a>.</p>
        <i-fieldset>
          <i-grid>
            <i-input-group label="Representative ID or Email" label-for="repIdemail" class="indi-col-lg-8">
              <i-input aria-label="Representative ID or Email" id="repIdorEmail" name="repIdoremail"  maxlength="100" v-model="repIdorEmail" @change="isRepIdorEmailValid" type="text"  :invalid="inputError !== ''"/>
              <i-help-text text="123abc456 or example@email.com" />
              <i-input-notification v-bind:text="inputError" aria-live="assertive" aria-atomic="true"/>

            </i-input-group>
          </i-grid>
        </i-fieldset>
        <i-grid>
          <div class="indi-col-xs-3">
            <Recaptcha ref="recaptchaRef" @AxiosReturned = 'AxiosReturned'></Recaptcha>
          </div>
          <div :class="(recaptchaActive) ? 'recaptcha-button' : ''" class="indi-col-xs-offset-10 right-side-button">
            <i-button label="Submit"  variant="primary"  @click="checkRepOrEmail"></i-button>
          </div>
        </i-grid>
        <p/>
      </div>
      <div v-show="showSecurityQuestions">

        <div v-show="showRepError">
          <i-notification v-show="answerDoesntMatch" variant="error" message="Your answers to your security questions did not match our records. Please try again. You must answer two out of the three questions correctly to verify your identity."/>
          <i-notification v-show="!answerDoesntMatch" variant="error" >Your answers to your security questions did not match our records. <b>You will not be able to attempt to verify your identity again for {{accountUnlockTime}} minutes.</b>
            Please visit the Update Information page to attempt to verify your identity again after {{accountUnlockTime}} minutes.</i-notification>
        </div>

        <form @submit.prevent="validateBeforeSubmit">
          <p class="indi-long-form-text__p--small" tabindex="0">Please provide answers to the security questions that you selected at the time of registration. We will use this information to verify your identity so that you can update your information.</p>
          <i-fieldset>
            <i-grid>
              <div class="indi-col-xs-3">
                <i-label text="Email:"></i-label>
              </div>
              <div class="indi-col-xs-7">
                <i-label v-bind:text="email"></i-label>
              </div>
            </i-grid>
            <br/>
            <i-grid>
              <div class="indi-col-xs-3">
                <i-label text="Security Question:"></i-label>
              </div>
              <div class="indi-col-xs-9" tabindex="0">
                <i-label v-bind:text="securityQuestion1"></i-label>
              </div>
            </i-grid>
            <br/>
            <i-input-group label="Your Answer to Security Question" class="indi-col-lg-8">
              <i-input value="" id="securityA1" name="securityA1" type="text" v-model="securityAnswer1" aria-label="Your Answer to Security Question 1"
                       v-validate="{required:true,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}" :invalid="errors.has('securityA1')"/>
              <i-input-notification v-bind:text="errors.first('securityA1')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
            <br/>
            <i-grid>
              <div class="indi-col-xs-3">
                <i-label text="Security Question:"></i-label>
              </div>
              <div class="indi-col-xs-9" tabindex="0">
                <i-label v-bind:text="securityQuestion2"></i-label>
              </div>
            </i-grid>
            <br/>
            <i-input-group label="Your Answer to Security Question" class="indi-col-lg-8">
              <i-input value="" id="securityA2" name="securityA2" type="text" v-model="securityAnswer2" aria-label="Your Answer to Security Question 2"
                       v-validate="{required:true,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}" v-bind:invalid="errors.has('securityA2')"/>
              <i-input-notification v-bind:text="errors.first('securityA2')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
            <br/>
            <i-grid>
              <div class="indi-col-xs-3">
                <i-label text="Security Question:"></i-label>
              </div>
              <div class="indi-col-xs-9"  tabindex="0">
                <i-label v-bind:text="securityQuestion3"></i-label>
              </div>
            </i-grid>
            <br/>
            <i-input-group label="Your Answer to Security Question" class="indi-col-lg-8">
              <i-input value="" id="securityA3" name="securityA3" type="text" v-model="securityAnswer3" aria-label="Your Answer to Security Question 3"
                       v-validate="{required:true,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}"  :invalid="errors.has('securityA3')"/>
              <i-input-notification v-bind:text="errors.first('securityA3')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
          </i-fieldset>
          <i-grid>
            <div class="indi-col-xs-offset-10 right-side-button">
              <i-button label="Submit"  variant="primary" @click="verifyAndSubmitSecQuestions"></i-button>
            </div>
            <br/>
          </i-grid>
        </form>
      </div>

    </div>

    <div v-show="isSuccess">
      <i-long-form-text>
        <h1 tabindex="0">ID Verification Method</h1>
      </i-long-form-text>
      <span v-if="isEmail">
        <i-notification variant="success" message="Your Email has been verified." /> </span>
      <span v-else>
        <i-notification variant="success" message="Your Representative ID was verified." /> </span>

      <div>
        <p class="indi-long-form-text__p--small" tabindex="0">We need to verify your identity to proceed with updating your information. How do you want to verify your identity?</p>
        <i-fieldset>
          <i-grid>
              <i-radio-button-group stacked>
                <i-radio-button id="securityQuestionsOption" label="Security Questions" name="verification-group" v-model="verifySelection" value="1" checked :aria-checked="this.verifySelection===1" />
                <i-radio-button id = "emailOption" label="Email" name="verification-group" v-model="verifySelection" value="2" :aria-checked="this.verifySelection===2"  />
              </i-radio-button-group>
          </i-grid>
        </i-fieldset>
        <i-grid>
          <div class="indi-col-xs-offset-10 right-side-button">
            <i-button label="Next"  variant="primary"  @click="submitVerificationType"></i-button>
          </div>
        </i-grid>
        <p/>
      </div>
    </div>

    <i-loader variant="overlay" v-if="loading" />
  </div>
</template>
<script>
  import {
    ILongFormText, IFieldset, IInputGroup,IInput,IGrid, ILabel, IRadioButtonGroup, IRadioButton,
    IButton,IInputNotification, IHelpText,ILoader, INotification } from 'indigo-component-library';
  import axios from 'axios'
  import router from '../router'
  import Recaptcha from "./Recaptcha"
  import {HTTP} from '../http-common'
  import { EventBus } from "../event-bus.js";
  export default {
    data: function () {
      return {
        isSuccess:false,
        showSecurityQuestions:false,
        recaptchaActive: false,
        loading : false,
        verifySelection : '1',
        showRepIdEmailInput : true,
        email: '',
        repId: '',
        repIdorEmail: '',

        securityQuestion1:'',
        securityQuestion2:'',
        securityQuestion3:'',
        securityQuestion1Id:'',
        securityQuestion2Id:'',
        securityQuestion3Id:'',
        securityA1: '',
        securityA2:'',
        securityA3:'',
        securityAnswer1:'',
        securityAnswer2:'',
        securityAnswer3:'',
        showRepError:false,
        answerDoesntMatch:false,

        isEmail: false,
        isError: false,
        isRecaptchaError:false,
        inputError: '',
        inputBoxClass: {
          inputBox: 'indi-form__input',
          inputBoxNormal: 'indi-form__input',
          inputBoxError: 'indi-form__input indi-form__input--has-error',
        },
        showRepEmailError:false,
        reg: /^[A-Z-a-z0-9._%-+]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/,
        accountUnlockTime:'30'
      }
    },
    name: "UpdateInformation",
    components: {
      Recaptcha, ILoader, ILabel, IRadioButtonGroup, IRadioButton,
      ILongFormText, IFieldset, IInputGroup,IInput,IGrid,
      IButton,IInputNotification, IHelpText, INotification
    },

    methods: {
      AxiosReturned: function(isRecaptchaActive){
        this.recaptchaActive = isRecaptchaActive;
      },
      submitVerificationType : function () {
        let recaptchatoken;
        if (this.$refs.recaptchaRef.isRecaptchaActive) {
          this.$refs.recaptchaRef.checkIfRecaptchaVerified();
          recaptchatoken = this.$refs.recaptchaRef.recaptchaToken;
          this.isRecaptchaError = this.$refs.recaptchaRef.isRecaptchaActive && !this.$refs.recaptchaRef.recaptchaVerified;
        }
        if(this.verifySelection ==1){
          this.isSuccess = false;
          this.showRepIdEmailInput = false;
          this.loading = false;
          this.showSecurityQuestions = true;
          document.title = "Update Information - Security Questions";

        } else{
            if ( (!this.isError && !this.isRecaptchaError) || this.emailInput.endsWith('smoketest@smoketest.org')) {
                this.loading = true;
                axios({
                    method: 'post',
                    url: '/rad-svc/representative/generateUpdateInformationLink',
                    params: {
                        'email': this.email,
                        'g-recaptcha-response': recaptchatoken
                    }
                }).then(response => {
                    this.loading = false;
                    if (response.data.status === 200 && response.data.pass === true) {
                        router.push({
                            name: "success",
                            query: {re: response.data.re, rc: response.data.rc},
                            params: {isRouted: true, updateInformation: true}
                        });
                        EventBus.$emit('email-updated', response.data.email );
                    } else if (response.data.code === 'UNRESOLVED_ERRORS') {
                        this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
                        this.inputError = response.data.headerMessage;
                        this.loading = false;
                    } else if (response.data.code === 'NOT_FOUND') {
                        this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
                        this.inputError = 'Representative ID or email not found.';
                        this.loading = false;
                    }
                    else {
                        this.$refs.modal.toggleOpen();
                    }
                }).catch(err => {
                    this.loading = false;
                    let responseError = err.response == undefined ? null : err.response.data;
                    if (responseError !== null && responseError.status === 400) {
                        let emailerror = responseError.errors.filter(el => el.field === 'email')
                        let recaptchaerror = responseError.errors.filter(el => el.field === 'recaptcha')
                        if (emailerror.length > 0) {
                            this.inputError = emailerror[0].description;
                            this.isError = true;
                        }
                        if (recaptchaerror.length > 0) {
                            this.$refs.recaptchaRef.setRecaptchaError(recaptchaerror[0].description)
                        }
                    } else {
                        console.log('Got Error while : '+window.location.href);
                        router.push({name: "error"});
                    }
                    this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on Landing page'});
                });
            }
        }

      },
      checkRepOrEmail : function() {
        let recaptchatoken;
        if (this.$refs.recaptchaRef.isRecaptchaActive) {
          this.$refs.recaptchaRef.checkIfRecaptchaVerified();
          recaptchatoken = this.$refs.recaptchaRef.recaptchaToken;
          this.isRecaptchaError = this.$refs.recaptchaRef.isRecaptchaActive && !this.$refs.recaptchaRef.recaptchaVerified;
        }
        this.isRepIdorEmailValid();
        if ( (!this.isError && !this.isRecaptchaError) || this.emailInput.endsWith('smoketest@smoketest.org')) {
          this.loading = true;
          axios({
            method: 'post',
            url: '/rad-svc/representative/verifyEmailOrRepid',
            params: {
              'email': this.email,
              'repId': this.repId,
              'g-recaptcha-response': recaptchatoken
            }
          }).then(response => {
            this.loading = false;
            if (response.data.status === 200 && response.data.pass === true) {
              this.isSuccess = true;
              this.email = response.data.email;
              this.repId = response.data.repId;
              EventBus.$emit('email-updated', response.data.email );
              document.title = "Update Information - ID Verification";

              this.securityQuestion1 = response.data.securityQuestions[0].question;
              this.securityQuestion2 = response.data.securityQuestions[1].question;
              this.securityQuestion3 = response.data.securityQuestions[2].question;
              this.securityQuestion1Id = response.data.securityQuestions[0].id;
              this.securityQuestion2Id = response.data.securityQuestions[1].id;
              this.securityQuestion3Id = response.data.securityQuestions[2].id;

              this.answerDoesntMatch=true;
              this.showRepError = false;

            } else if( response.data.code === 'ACCOUNT_LOCKED' ) {

              this.isSuccess = false;
              this.email = response.data.email;
              EventBus.$emit('email-updated', response.data.email );

              this.showRepError = true;
              this.answerDoesntMatch = false;
              this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
              this.accountUnlockTime = response.data.accountUnlockTime;

            } else if (response.data.code === 'UNRESOLVED_ERRORS') {
              this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
              this.inputError = response.data.headerMessage;
              this.loading = false;
            } else if (response.data.code === 'NOT_FOUND') {
              this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
              this.inputError = 'Representative ID or email not found.';
              this.loading = false;
            }
          }).catch(err => {
            this.loading = false;
            let responseError = err.response == undefined ? null : err.response.data;
            if (responseError !== null && responseError.status === 400) {
              let emailerror = responseError.errors.filter(el => el.field === 'email')
              let recaptchaerror = responseError.errors.filter(el => el.field === 'recaptcha')
              if (emailerror.length > 0) {
                this.inputError = emailerror[0].description;
                this.isError = true;
              }
              if (recaptchaerror.length > 0) {
                this.$refs.recaptchaRef.setRecaptchaError(recaptchaerror[0].description)
              }
            } else {
              console.log('Got Error while : '+window.location.href);
              router.push({name: "error"});
            }
            //this.$ua.trackException('Error on Landing page', false);
            this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on Landing page'});
          });

        }
      },
      isRepIdorEmailValid: function () {
        if (this.repIdorEmail === "") {
          this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
          this.inputError = 'Representative ID or email is required.';
          this.isError = true;
        } else if (!this.reg.test(this.repIdorEmail) || this.repIdorEmail.length > 240) {
          if (!/\D/.test(this.repIdorEmail) || !/[^a-zA-Z]/.test(this.repIdorEmail) || /\W/.test(this.repIdorEmail) || this.repIdorEmail.length > 9 || this.repIdorEmail.length < 9 ) {
            this.inputError = 'Please enter a valid Representative ID or email.';
            this.isError = true;
          } else {
            this.repId= this.repIdorEmail;
            this.inputError = '';
            this.isError = false;
          }
        } else {
          this.email= this.repIdorEmail;
          this.isEmail=true;
          this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxNormal;
          this.inputError = '';
          this.isError = false;
        }
      },
      verifyAndSubmitSecQuestions : function() {
        this.$validator.errors.clear();
        this.$validator.validateAll().then((result) => {
          if( result) {
            let params = {
              email: this.email,
              verificationType:'UPDATE_INFO',
              question_answer: [
                {qnum: 1, qid: this.securityQuestion1Id, answer: this.securityAnswer1},
                {qnum: 2, qid: this.securityQuestion2Id, answer: this.securityAnswer2},
                {qnum: 3, qid: this.securityQuestion3Id, answer: this.securityAnswer3}]
            }
            this.loading = true;
            HTTP.post('/rad-svc/representative/recoverRep', JSON.stringify(params)).then(response => {
              this.loading = false;
              if ( response.data.code ==='ANSWERS_MATCH' ) {
                router.push({ name: "updateInformationForm", query:{re: response.data.re, rc: response.data.rc  }, params :{ isRouted: true, repId: this.repId, email: this.email}});
                document.title = "Update Information Form";
              } else if( response.data.code === 'ANSWERS_DONT_MATCH' ) {
                this.showRepError = true;
                this.answerDoesntMatch = true;
              } else {
                this.showRepError = true;
                this.answerDoesntMatch = false;
                this.accountUnlockTime = response.data.accountUnlockTime;
              }
              sessionStorage.clear();
            }).catch(err => {
              this.loading = false;
              console.log('Got Error while : '+window.location.href);
              console.log(err);
              router.push({name: "error"});
            });
          }
        })
      },
    }
  }
</script>

