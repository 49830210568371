<template>
  <div>
    <div v-show="!isSuccess">
      <i-long-form-text tabindex="0">
        <h1>Forgot Representative ID?</h1>
        <div v-show="showRepError">
          <i-notification v-show="answerDoesntMatch" variant="error" message="Your answers to your security questions did not match our records. Please try again. You must answer two out of the three questions correctly to retrieve your Representative ID."/>
          <i-notification v-show="!answerDoesntMatch" variant="error" >Your answers to your security questions did not match our records. <b>You will not be able to retrieve your Representative ID for {{accountUnlockTime}} min.</b>
            Please visit the Forgot Representative ID page to attempt to retrieve your Representative ID again after {{accountUnlockTime}} minutes.</i-notification>
        </div>
      </i-long-form-text>
      <div v-show="showEmailInput">
        <p class="indi-long-form-text__p--small" tabindex="0">Please provide the email address that you used when you registered for a Representative ID.</p>
        <i-fieldset>
          <i-grid>
            <i-input-group label="Email Address" label-for="email" class="indi-col-lg-8">
              <i-input aria-label="Email Address" id="emailAddress" name="emailAddress" type="email" v-model="email"
                       @change="isEmailValid" :invalid="showRepError"/>
              <i-help-text text="example@email.com" />
              <i-input-notification v-bind:text="inputError" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
          </i-grid>
        </i-fieldset>
        <i-grid>
          <div class="indi-col-xs-3">
            <Recaptcha ref="recaptchaRef" @AxiosReturned = 'AxiosReturned'></Recaptcha>
          </div>
          <div :class="(recaptchaActive) ? 'recaptcha-button' : ''" class="indi-col-xs-offset-10 right-side-button">
            <i-button label="Next"  variant="primary"  @click="checkRep"></i-button>
          </div>
        </i-grid>
        <p/>
      </div>
      <div v-show="!showEmailInput">
        <form @submit.prevent="validateBeforeSubmit">
          <p class="indi-long-form-text__p--small">Please provide answers to the security questions that you selected at the time of registration. We will use this information to recover your Representative ID. </p>
          <i-fieldset>
            <i-grid>
              <div class="indi-col-xs-3">
                <i-label text="Email :"></i-label>
              </div>
              <div class="indi-col-xs-7">
                <i-label v-bind:text="email"></i-label>
              </div>
            </i-grid>
            <br/>
            <i-grid>
              <div class="indi-col-xs-3">
                <i-label text="Security Question :"></i-label>
              </div>
              <div class="indi-col-xs-9">
                <i-label v-bind:text="securityQuestion1"></i-label>
              </div>
            </i-grid>
            <br/>
            <i-input-group label="Your Answer to Security Question" class="indi-col-lg-8">
              <i-input value="" id="securityA1" name="securityA1" type="text" v-model="securityAnswer1" aria-label="Your Answer to Security Question 1"
                       v-validate="{required:true,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}" :invalid="errors.has('securityA1')"/>
              <i-input-notification v-bind:text="errors.first('securityA1')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
            <br/>
            <i-grid>
              <div class="indi-col-xs-3">
                <i-label text="Security Question :"></i-label>
              </div>
              <div class="indi-col-xs-9">
                <i-label v-bind:text="securityQuestion2"></i-label>
              </div>
            </i-grid>
            <br/>
            <i-input-group label="Your Answer to Security Question" class="indi-col-lg-8">
              <i-input value="" id="securityA2" name="securityA2" type="text" v-model="securityAnswer2" aria-label="Your Answer to Security Question 2"
                       v-validate="{required:true,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}" v-bind:invalid="errors.has('securityA2')"/>
              <i-input-notification v-bind:text="errors.first('securityA2')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
            <br/>
            <i-grid>
              <div class="indi-col-xs-3">
                <i-label text="Security Question :"></i-label>
              </div>
              <div class="indi-col-xs-9">
                <i-label v-bind:text="securityQuestion3"></i-label>
              </div>
            </i-grid>
            <br/>
            <i-input-group label="Your Answer to Security Question" class="indi-col-lg-8">
              <i-input value="" id="securityA3" name="securityA3" type="text" v-model="securityAnswer3" aria-label="Your Answer to Security Question 3"
                       v-validate="{required:true,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}"  :invalid="errors.has('securityA3')"/>
              <i-input-notification v-bind:text="errors.first('securityA3')" aria-live="assertive" aria-atomic="true"/>
            </i-input-group>
          </i-fieldset>
          <i-grid>
            <div class="indi-col-xs-offset-10 right-side-button">
              <i-button label="Submit"  variant="primary" @click="verifyRepresentative"></i-button>
            </div>
            <br/>
          </i-grid>
        </form>
      </div>
    </div>
    <div v-show="isSuccess">
      <div class="indi-long-form-text">
        <div class="indi-long-form-text">
          <h1>Thank You</h1>
          <p></p>
        </div>
        <i-notification v-show="resentEmail">
          Email sent. Please check your email again, and be sure to check your spam folder. If you have not received the email within 10 minutes, please contact USAC at <a style="color:#005bdf" href="mailto:LifelineSupport@usac.org">LifelineSupport@usac.org</a>
        </i-notification>
        <i-fieldset>
          <i-long-form-text>
            <p class="indi-long-form-text__p--small">Thank you. Your Representative ID has been sent to the email provided upon registration.</p>
            <p class="indi-long-form-text__p--small" v-if="resentEmail">Didn't get an email? Click here to resend </p>
            <p v-else class="indi-long-form-text__p--small">Didn't get an email? Click <a href="javascript:return false" @click="resendRepresentativeLink"> here </a> to resend.

            </p>
          </i-long-form-text>
        </i-fieldset>
      </div>
    </div>
    <i-loader variant="overlay" v-if="loading" />
  </div>
</template>
<script>
  import {
    ILongFormText, IFieldset, IInputGroup,IInput,IGrid, ILabel,
    IButton,IInputNotification, IHelpText,ILoader, INotification } from 'indigo-component-library';
  import axios from 'axios'
  import router from '../router'
  import Recaptcha from "./Recaptcha"
  import {HTTP} from '../http-common'
  export default {
    data: function () {
      return {
        isSuccess:false,
        recaptchaActive: false,
        loading : false,
        showEmailInput : true,
        isForgotRepVisible: true,
        email: '',
        isError: false,
        isRecaptchaError:false,
        inputError: '',
        securityQuestion1:'',
        securityQuestion2:'',
        securityQuestion3:'',
        securityQuestion1Id:'',
        securityQuestion2Id:'',
        securityQuestion3Id:'',
        securityA1: '',
        securityA2:'',
        securityA3:'',
        securityAnswer1:'',
        securityAnswer2:'',
        securityAnswer3:'',
        inputBoxClass: {
          inputBox: 'indi-form__input',
          inputBoxNormal: 'indi-form__input',
          inputBoxError: 'indi-form__input indi-form__input--has-error',
        },
        showRepError:false,
        answerDoesntMatch:false,
        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,240}))$/,
        resentEmail:false,
        accountUnlockTime:'30'
      }
    },
    name: "ForgotRepresentative",
    components: {
      Recaptcha, ILoader, ILabel,
      ILongFormText, IFieldset, IInputGroup,IInput,IGrid,
      IButton,IInputNotification, IHelpText, INotification
    },
    mounted: function () {
      this.$gtm.trackView('resendRepresentativeLink');
      document.title = "Forgot Representative ID - Representative Accountability Database (RAD)";
      // 508 fixes
      document.getElementsByClassName('indi-icon').forEach(function( svg ) {
        //svg.setAttribute("aria-hidden", "false");
      })
    },
    beforeMount: function () {

      if (this.$router.currentRoute.query.rc != undefined && this.$router.currentRoute.query.re != undefined) {
        this.loading = true;
        HTTP.get('/rad-svc/representative/verifyRepresentativeEmail', {
            params: {
              rc: this.$router.currentRoute.query.rc,
              re: this.$router.currentRoute.query.re
            }
          }
        ).then(response => {
          this.loading = false;
          if (response.data.pass === true) {
            this.isSuccess = true;
            document.title = "Thank You - Representative Accountability Database (RAD)";
          } else {
            this.loading = false;
            console.log('Got Error while : '+window.location.href);
            router.push({name: "error"});
          }
        }).catch(err => {
          this.loading = false;
          console.log('Got Error while : '+window.location.href);
          console.log(err);
          router.push({name: "error"});
        });
      }
    },

    methods: {
      AxiosReturned: function(isRecaptchaActive){
        this.recaptchaActive = isRecaptchaActive;
      },
      resendConformationLink:function() {},
      verifyRepresentative : function() {
        this.$validator.errors.clear();
        this.$validator.validateAll().then((result) => {
          if( result) {
            let params = {
              email: this.email,
              verificationType:'RECOVER_REP_ID',
              question_answer: [
                {qnum: 1, qid: this.securityQuestion1Id, answer: this.securityAnswer1},
                {qnum: 2, qid: this.securityQuestion2Id, answer: this.securityAnswer2},
                {qnum: 3, qid: this.securityQuestion3Id, answer: this.securityAnswer3}]
            }
            this.loading = true;
            HTTP.post('/rad-svc/representative/recoverRep', JSON.stringify(params)).then(response => {
              this.loading = false;
              if ( response.data.code ==='ANSWERS_MATCH' ) {
                this.isSuccess = true;
                router.push({ name: "forgotRepresentative",  query:{re: response.data.re, rc: response.data.rc  }, params :{ isRouted: true}});
                document.title = "Thank You - Representative Accountability Database (RAD)";
              } else if( response.data.code === 'ANSWERS_DONT_MATCH' ) {
                this.showRepError = true;
                this.answerDoesntMatch = true;
              } else {
                this.showRepError = true;
                this.answerDoesntMatch = false;
                this.accountUnlockTime = response.data.accountUnlockTime;
              }
              sessionStorage.clear();
            }).catch(err => {
              this.loading = false;
              console.log('Got Error while : '+window.location.href);
              console.log(err);
              router.push({name: "error"});
            });
          }
        })
      },
      resendRepresentativeLink : function(){
        axios({
          method: 'post',
          url: '/rad-svc/representative/resendEmail',
          params :{ re:this.$router.currentRoute.query.re,rc:this.$router.currentRoute.query.rc},
         }
        ).then(() => {
          this.resentEmail = true;
          document.title = "Thank You - Representative Accountability Database (RAD)";
        }).catch(err => {
            console.log('Got Error while : '+window.location.href);
            console.log(err);
            router.push({name: "error"});
            this.$gtm.trackEvent({category: 'exception', action: 'click', label: 'Error on Forgot Representative on Re-Send Representative Link'});
        });
        this.$gtm.trackEvent({category: 'ResendRepresentativeLink', action: 'click', label: 'Re-send the Representative Link'});
      },
      validateBeforeSubmit() {},
      isEmailValid: function () {
        this.showRepError = false;
        this.answerDoesntMatch = false;
        if (this.email === "") {
          this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
          this.inputError = 'Missing required field.';
          this.isError = true;
        } else if (!this.reg.test(this.email) || this.email.length > 240) {
          this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
          this.inputError = 'Invalid email format.';
          this.isError = true;
        } else {
          this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxNormal;
          this.inputError = '';
          this.isError = false;
        }
      },
      checkRep: function() {
        let recaptchatoken;
        if (this.$refs.recaptchaRef.isRecaptchaActive) {
          this.$refs.recaptchaRef.checkIfRecaptchaVerified();
          recaptchatoken = this.$refs.recaptchaRef.recaptchaToken;
          this.isRecaptchaError = this.$refs.recaptchaRef.isRecaptchaActive && !this.$refs.recaptchaRef.recaptchaVerified;
        }
        this.isEmailValid();
        if (!this.isError && !this.isRecaptchaError) {
          this.loading = true;
          axios({
            method: 'post',
            url: '/rad-svc/representative/verifyRep',
            params: {
              'email': this.email,
              'g-recaptcha-response': recaptchatoken
            }
          }).then(response => {
            this.loading = false;
            if (response.data.status === 200 && response.data.pass === true) {
              this.showEmailInput = false;
              this.securityQuestion1 = response.data.securityQuestions[0].question;
              this.securityQuestion2 = response.data.securityQuestions[1].question;
              this.securityQuestion3 = response.data.securityQuestions[2].question;
              this.securityQuestion1Id = response.data.securityQuestions[0].id;
              this.securityQuestion2Id = response.data.securityQuestions[1].id;
              this.securityQuestion3Id = response.data.securityQuestions[2].id;
            } else if( response.data.code === 'ACCOUNT_LOCKED' ) {
              this.showRepError = true;
              this.answerDoesntMatch = false;
              this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
              this.accountUnlockTime = response.data.accountUnlockTime;
            } else {
              this.inputBoxClass.inputBox = this.inputBoxClass.inputBoxError;
              this.inputError = 'Email not found. If you are unsure of the email address you used during registration, please contact USAC at (800) 234-9473.';
              this.isError = true;
            }
          }).catch(err => {
            this.loading = false;
            let responseError = err.response == undefined ? null : err.response.data;
            if (responseError !== null && responseError.status === 400) {
              //let emailerror = responseError.errors.filter(el => el.field === 'email')
              let recaptchaerror = responseError.errors.filter(el => el.field === 'recaptcha')

              if (recaptchaerror.length > 0) {
                this.$refs.recaptchaRef.setRecaptchaError(recaptchaerror[0].description)
              }
            } else {
                console.log('Got Error while : '+window.location.href);
                router.push({name: "error"});
            }
          });
        }
      },
    }
  }
</script>

