<template>
  <footer class="indi-app-footer">
    <div class="indi-app-footer__inner">
      <span v-if="utilityLinks" class="indi-app-footer__utility-links">
        <a class="indi-app-footer__utility-link" v-for="link in utilityLinks" role=link tabindex="0" :href="link.href">{{ link.text }}</a>
      </span>
      <span v-if="logos" class="indi-app-footer__logos">
        <img class="indi-app-footer__logo" v-for="logo in logos" :src="logo.src" :alt="logo.alt ? logo.alt : logo.src"/>
      </span>
      <span class="indi-app-footer__legal" v-if="legal">
        {{this.legal}}
      </span>
      <span class="indi-app-footer__legal" v-else>
<!--        &copy; 2020 Universal Service Administrative Company. All rights reserved.-->
      </span>
      <span v-if="legalLinks" class="indi-app-footer__legal-links">
        <a class="indi-app-footer__legal-link" v-for="link in legalLinks"  :id="link.id" :href="link.href">{{ link.text }}</a>
      </span>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'IAppFooter',
  props: {
    /**
     * Legal information (copyright)
     */
    legal: {
      type: String,
      default: ``
    },
    /**
    * An array of objects containing link information. [{href: #, text: 'Hello'}, {href: #, text: 'World'}]
    */
    legalLinks: {
      type: Array
    },
    /**
     * An array of image paths to be displayed in the footer
     */
    logos: {
      type: Array
    },
    /**
     * An array of objects containing link information. [{href: #, text: 'Hello'}, {href: #, text: 'World'}]
     */
    utilityLinks: {
      type: Array
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '~indigo-component-library/src/components/AppFooter/AppFooter';
</style>
