<template>
  <div v-if="isRecaptchaActive" >
    <vue-recaptcha @verify="markRecaptchaAsVerified"
                   ref="recaptchaRef"
                   v-bind:class="recaptchaClass"
                   v-bind:sitekey="siteKey" id="re-captcha" name="re-captcha" @render ="recaptchaRendered" >
    </vue-recaptcha>
    <div id="recaptcha" name="recaptcha" class="g-recaptcha"></div>
    <i-input-notification v-bind:text="pleaseTickRecaptchaMessage"/>
  </div>
</template>
<script>
  import VueRecaptcha from 'vue-recaptcha';
  import axios from 'axios'
  import router from '../router'
  import {
    IInputNotification,
  } from 'indigo-component-library';

  export default {
    name: "Recaptcha",
    components: {VueRecaptcha, IInputNotification},
    data: function () {
      return {
        pleaseTickRecaptchaMessage: '',
        recaptchaVerified: false,
        recaptchaClass: '',
        siteKey: '',
        recaptchaToken: '',
        loading : false,
        isRecaptchaActive:false,
      }
    },
    beforeMount: function () {
      this.loading = true;
      axios({
        method: 'get',
        url: '/rad-svc/props',
      }).then(response => {
        this.loading = false;
        this.siteKey = response.data.recaptchaSiteKey;
        this.isRecaptchaActive = response.data.recaptchaActive;
        this.$emit('AxiosReturned', this.isRecaptchaActive);
      }).catch(() => {
        this.loading = false;
          console.log('Got Error while : '+window.location.href);
        router.push({name: "error"});
      });
    },
    methods: {
      markRecaptchaAsVerified(response) {
        this.recaptchaClass = 'g-recaptcha recaptca-login';
        this.pleaseTickRecaptchaMessage = '';
        this.recaptchaVerified = true;
        this.recaptchaToken = response;
      },
      checkIfRecaptchaVerified() {
        if (!this.recaptchaVerified) {
          this.recaptchaClass = 'g-recaptcha recaptca-login recaptca-error';
          this.pleaseTickRecaptchaMessage = 'CAPTCHA required.';
          return true; // prevent form from submitting
        }
      },
      setRecaptchaError(errorMessage) {
        this.recaptchaClass = 'g-recaptcha recaptca-login recaptca-error';
        this.pleaseTickRecaptchaMessage = errorMessage;
      },
      recaptchaRendered(id){
        let g = document.getElementById('re-captcha').getElementsByTagName('iframe')[0];
        g.setAttribute("aria-label", "do not use");
        g.setAttribute("aria-readonly", "true");
        if(id!==null){
          this.$parent.loading = false;
        }
      }
    }
  }
</script>

<style scoped>
  .warningMessage {
    font-family: "Source Sans Pro";
    color: #b80014;
  }

  .recaptca-login {
    width: 308px;
    height: 78px;
  }

  .recaptca-error {
    color: #b80014;
    border: 1px solid;
    padding: 5px;
  }
</style>
