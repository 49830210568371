<template>
    <div>
        <div v-show="!isSuccess">
            <i-long-form-text>
                <h1 tabindex="0">Annual Agreement</h1><br>

                <p class="indi-long-form-text__p--small" tabindex="0">To begin or continue performing National Lifeline Accountability Database (NLAD) and/or Lifeline National Eligibility Verifier (National Verifier) transactions, review and agree to the following:</p>
                <br>
                <div v-show="checkBoxesError" aria-live="assertive" aria-atomic="true">
                    <i-notification variant="error" message="All statements must be checked in order to submit these Agreements."/>
                </div>

                <i-checkbox :invalid="errors.has('infoAccurateFlag')"  v-validate="'required:true'"  name="infoAccurateFlag" id="infoAccurateFlag" v-model="infoAccurateFlag"
                            label="The information associated with my representative ID is current and accurate." />

                <i-checkbox :invalid="errors.has('updateContactFlag')" v-validate="'required:true'" name="updateContactFlag" id="updateContactFlag" v-model="updateContactFlag"
                            label="I will always update my contact information in RAD within 30 days of a change." />

                <i-checkbox :invalid="errors.has('useRepIdFlag')" v-validate="'required:true'" name="useRepIdFlag" id="useRepIdFlag" v-model="useRepIdFlag"
                            label="I will always use my Representative ID to perform transactions in NLAD and the National Verifier." />

                <i-checkbox :invalid="errors.has('secureRepIDFlag')" v-validate="'required:true'" name="secureRepIDFlag" id="secureRepIDFlag" v-model="secureRepIDFlag"
                            label="My Representative ID is my unique identification. No one else may use my Representative ID. I will not provide my Representative ID to anyone except the service provider(s) I work for." />

                <i-checkbox :invalid="errors.has('noFraudFlag')" v-validate="'required:true'" name="noFraudFlag" id="noFraudFlag" v-model="noFraudFlag"
                            label="I will not use or provide any fraudulent, misleading, or inaccurate information when performing Lifeline transactions."/>

                <i-checkbox :invalid="errors.has('useForPurposeFlag')" v-validate="'required:true'" name="useForPurposeFlag" id="useForPurposeFlag" v-model="useForPurposeFlag"
                            label="I acknowledge that I will only use NLAD and the National Verifier for their specified purposes."/>

                <RadCheckbox :invalid="errors.has('agreeNVFlag')" v-validate="'required:true'" name="agreeNVFlag" id="agreeNVFlag" v-model="agreeNVFlag" >
                    <span slot="label">
                        I understand and agree to the <a style="color:#0070f4" href='https://nationalverifier.servicenowservices.com/lifeline?id=nv_terms_conditions&sp=true' target="_balnk">National Verifier terms and conditions.</a>
                    </span>
                </RadCheckbox>

                <RadCheckbox :invalid="errors.has('agreeNLADFlag')" v-validate="'required:true'" name="agreeNLADFlag" id="agreeNLADFlag" v-model="agreeNLADFlag" >
                    <span slot="label">
                        I understand and agree to the <a style="color:#0070f4" href='https://www.usac.org/wp-content/uploads/lifeline/documents/nlad/NLAD-Access-Agreement.pdf' target="_balnk">NLAD terms and conditions.</a>
                    </span>
                </RadCheckbox>

                <RadCheckbox :invalid="errors.has('agreeRADFlag')" v-validate="'required:true'" name="agreeRADFlag" id="agreeRADFlag" v-model="agreeRADFlag" >
                    <span slot="label">
                        I understand and agree to the <a v-bind:href="termsAndConditions" style="color:#0070f4" target="_balnk"> RAD terms and conditions.</a>
                    </span>
                </RadCheckbox>

                <i-checkbox :invalid="errors.has('acknowledgeFlag')" v-validate="'required:true'" name="acknowledgeFlag" id="acknowledgeFlag" v-model="acknowledgeFlag"
                            label="I acknowledge that I am providing information to the Universal Service Administrative Company (USAC), a U.S.-based entity created by the Federal Communications Commission (FCC) that performs activities on behalf of the FCC."/>
                <i-checkbox :invalid="errors.has('failFlag')" v-validate="'required:true'" name="failFlag" id="failFlag" v-model="failFlag"
                            label="If I fail to comply with any of the above requirements, USAC’s guidance, the FCC’s program rules, or any applicable laws, I understand that my access to NLAD and/or National Verifier may be suspended or terminated for unauthorized and/or unlawful use and the service provider may be subject to FCC enforcement action and law enforcement prosecution as a result of my actions."/>
                    <br>
                <br>
                <br>
                <i-fieldset>

                    <i-grid>

                        <i-label text="Your Signature" class="indi-col-lg-8" style="padding-top:5px;padding-bottom:15px;font-size: 32px;"/>
                        <i-input-group label="Type your full legal name below." label-for="fullLegalName" class="indi-col-lg-8">

                            <i-input :invalid="errors.has('fullLegalName')" v-validate="{ required: true, matchSignature:{fullLegalName: this.helpLegalName} }" id="fullLegalName" aria-label="Type your full legal name below"  v-model="fullLegalName"
                                      name="fullLegalName" type="text" style="text-transform: uppercase;"/>
                            <div class="indi-form__help-text">{{this.helpLegalName}}</div>
                            <i-input-notification v-show="errors.has('fullLegalName')" text="Please type your signature" aria-live="assertive" aria-atomic="true"/>
                        </i-input-group>


                    </i-grid>

                    <i-long-form-text>

                        <i-input-group>
                            <i-checkbox :invalid="errors.has('signatureFlag')" v-validate="'required:true'" name="signatureFlag" id="signatureFlag" v-model="signatureFlag"
                                        label="I understand this is a digital signature, and is the same as if I signed my name by pen."/>
                            <i-input-notification v-show="errors.has('signatureFlag')"  invalid text="You must acknowledge this digital signature understanding before you can submit these Agreements." aria-live="assertive" aria-atomic="true"/>
                        </i-input-group>
                    </i-long-form-text>
                </i-fieldset>

                <i-grid>
                    <div v-if="this.registration">
                        <i-button class="indi-col-xs-offset-9" label="Back"  variant="tertiary" @click="$router.go(-1)"/>
                        <i-button class="indi-col-xs-offset-3" label="Next"  variant="primary" @click="submitRepresentative"/> </div>
                    <div v-else>
                        <i-button class="indi-col-xs-offset-10 right-side-button" label="Submit"  variant="primary" @click="validateAndSubmit"></i-button> </div>
                </i-grid>
                <br>
            </i-long-form-text>
        </div>
        <div v-show="isSuccess">
            <i-long-form-text>

                <h1>Success!</h1>
                <br>
                <br>
                <i-notification variant="success" message="You have successfully completed the RAD Annual Agreements."/>
                    <p class="indi-long-form-text">You will need to complete the RAD Annual Agreements again next year by {{annualAgreementDeadlineDate}}.
                        We will send you a reminder next year before the deadline. You can now close this window in your browser.</p>

                    <p class="indi-long-form-text">Thank you for completing the Annual Agreements!</p>
                </i-long-form-text>
        </div>
        <i-loader variant="overlay" v-if="loading" />
    </div>
</template>
<script>

    import {ICheckbox,IFieldset,ILabel,ILoader,
        ILongFormText, IInputGroup,IInput,IGrid,
        IButton,IInputNotification, IHelpText, INotification } from 'indigo-component-library';/* Do we need all these*/

    import router from '../router'
    import {HTTP} from '../http-common'
    import RadCheckbox from '../components/Checkbox/Checkbox'
    import { EventBus } from "../event-bus.js";


    export default {
        data: function () {
            return {
                certifiedAnnualAgreement: false,
                registration : false,
                isSuccess: false,
                loading: false,
                helpLegalName: '',
                fullLegalName: '',

                annualAgreementDeadlineDate: '',
                /** This date will be new 'annual_agreement_deadline_date' from REPRESENTATIVE table */
                termsAndConditions:'',
                infoAccurateFlag: false,
                updateContactFlag: false,
                useRepIdFlag: false,
                secureRepIDFlag: false,
                noFraudFlag: false,
                useForPurposeFlag: false,
                agreeNVFlag: false,
                agreeNLADFlag: false,
                agreeRADFlag: false,
                acknowledgeFlag: false,
                failFlag: false,
                signatureFlag: false,
                inputError: '',
                checkBoxesError: false
            }
        },
        name: "CertifyAnnualAgreement",
        components: {
            ICheckbox, IFieldset, ILabel, ILoader,
            ILongFormText, IInputGroup, IInput, IGrid,
            IButton, IInputNotification, IHelpText, INotification, RadCheckbox
        },
        beforeMount: function () {
            this.termsAndConditions = document.location.origin + document.location.pathname + '#/termsAndConditions';

            if(this.$route.params.registration) {
                this.registration = true;
                let rc = this.$router.currentRoute.query.rc
                let data = JSON.parse(sessionStorage.getItem(rc))
                this.loading = false;
                this.helpLegalName = data.firstName.toUpperCase() + " " + data.lastName.toUpperCase();
                this.email = data.email;
                EventBus.$emit('email-updated', this.email);

            } else {
                if (this.$router.currentRoute.query.rc != undefined && this.$router.currentRoute.query.re != undefined) {
                    this.loading = true;
                    HTTP.get('/rad-svc/representative/verifyAnnualAgreement', {
                            params: {
                                rc: this.$router.currentRoute.query.rc,
                                re: this.$router.currentRoute.query.re
                            }
                        }
                    ).then(response => {
                        this.loading = false;
                        if (response.data.pass === true) {
                            this.helpLegalName = response.data.firstName.toUpperCase() + " " + response.data.lastName.toUpperCase();
                            this.email = response.data.email;
                            EventBus.$emit('email-updated', response.data.email);
                            console.log('Got the email...' + response.data.email);
                        } else {
                            this.loading = false;
                            console.log('Got Error while : ' + window.location.href);
                            router.push({name: "error"});
                        }
                    }).catch(err => {
                        this.loading = false;
                        console.log('Got Error while : ' + window.location.href);
                        console.log(err);
                        router.push({name: "error"});
                    });
                }
            }
        },
        mounted: function () {
            document.title = "Complete Annual User Agreements - Representative Accountability Database (RAD)";

        }, methods: {

            validateAndSubmit() {

                this.$validator.errors.clear();
                this.checkBoxesError = false;

                this.$validator.validateAll().then((result) => {
                    if (result) {
                        if (this.registration) {
                            // call review registration when request from registration process
                            router.push({
                                name: "reviewRegistration",
                                query: {rc: this.$router.currentRoute.query.rc, re: this.$router.currentRoute.query.re}
                            });
                        } else {
                            console.log("Submitting................email = " + this.email);

                            let params = {
                            rc: this.$router.currentRoute.query.rc,
                            re: this.$router.currentRoute.query.re,
                            certFlag1: this.infoAccurateFlag,
                            certFlag2: this.updateContactFlag,
                            certFlag3: this.useRepIdFlag,
                            certFlag4: this.secureRepIDFlag,
                            certFlag5: this.noFraudFlag,
                            certFlag6: this.useForPurposeFlag,
                            certFlag7: this.agreeNVFlag,
                            certFlag8: this.agreeNLADFlag,
                            certFlag9: this.agreeRADFlag,
                            certFlag10: this.acknowledgeFlag,
                            certFlag11: this.failFlag,
                            certFlag12: this.signatureFlag,
                            signature: this.fullLegalName
                            };

                            this.loading = true;
                            HTTP.post('/rad-svc/annualAgreement/certifyAnnualAgreement', JSON.stringify(params)).then(response => {

                            this.loading = false;
                            if (response.data.status === 200 && response.data.pass === true) {
                                this.isSuccess = true;
                                this.annualAgreementDeadlineDate=response.data.annualAgreementDeadlineDate;
                               /* router.push({
                                    name: "certifyAnnualAgreement",
                                    query: {re: response.data.re, rc: response.data.rc},
                                    params: {isRouted: true, annualAgreement: true}
                                });*/
                                document.title = "Complete Annual User Agreements Success - Representative Accountability Database (RAD)";
                                EventBus.$emit('email-updated', response.data.email );
                            }

                            }).catch(err => {
                            this.loading = false;
                            console.log('Got Error while : ' + window.location.href);
                            console.log(err);
                            router.push({name: "error"});
                            sessionStorage.clear();
                            });
                        }

                    } else {
                        this.checkBoxesError = true;
                    }
                });
            },
            submitRepresentative() {
                this.$gtm.trackEvent({category: 'ReviewRepresentative', action: 'click', label: 'Registration Form Review Representative'});
                this.validateAndSubmit();

            }
        }
    }
</script>