import Vue from 'vue'
import Router from 'vue-router'
import Register from '../components/Register'
import Status from '../components/Status'
import TermsAndConditions from '../components/TermsAndConditions'
import UpdateInformation from '../components/UpdateInformation'
import UpdateInformationForm from '../components/UpdateInformationForm'
import UpdateInformationReview from '../components/UpdateInformationReview'
import ForgotRepresentative from '../components/ForgotRepresentative'
import AnnualAgreement from '../components/AnnualAgreement'
import CertifyAnnualAgreement from '../components/CertifyAnnualAgreement'
import PaperworkReductionActNotice from '../components/PaperworkReductionActNotice'
import PrivacyStatement from "../components/PrivacyStatement";
import Registration from "../components/Registration";
import ReviewRegistration from "../components/ReviewRegistration"
import SearchRepresentative from "../components/SearchRepresentative"
import Landing from "../components/Landing/Landing"
import { EventBus } from "../event-bus.js";

Vue.use(Router);
const router =  new Router({
    //mode: 'history',
    routes: [
      { path: '/verifyRepresentative', name: 'Register', component: Register, meta: {title: 'Begin Registration – Representative Accountability Database (RAD)'} },
      { path: '/Landing', name: 'Landing', component: Landing, meta: {layout:'landing',title: 'Representative Accountability Database (RAD)'} },
      { path: '/error', name: 'error', component: Status, meta: {title: 'Error - Representative Accountability Database (RAD)'} },
      { path: '/termsAndConditions', name: 'termsAndConditions', component: TermsAndConditions , meta: {title: 'Terms and Conditions - Representative Accountability Database (RAD)'}},
      { path: '/recoverRepresentative', name: 'forgotRepresentative', component: ForgotRepresentative, meta: {title: 'Forgot Representative ID - Representative Accountability Database (RAD)'} },
      { path: '/annualAgreement', name: 'annualAgreement', component: AnnualAgreement, meta: {title: 'AnnualAgreement - Representative Accountability Database (RAD)'} },
      { path: '/certifyAnnualAgreement', name: 'certifyAnnualAgreement', component: CertifyAnnualAgreement, meta: {title: 'Complete Annual User Agreements - Representative Accountability Database (RAD)'} },
      { path: '/updateInformation', name: 'updateInformation', component: UpdateInformation, meta: {title: 'Update Information - Representative Accountability Database (RAD)'} },
      { path: '/updateInformationForm', name: 'updateInformationForm', component: UpdateInformationForm, meta: {title: 'Update Information Form - Representative Accountability Database (RAD)'} },
      { path: '/updateInformationReview', name: 'updateInformationReview', component: UpdateInformationReview, meta: {title: 'Update Information Review - Representative Accountability Database (RAD)'} },
      { path: '/success', name: 'success', component: Status, meta: {title: 'Status - Representative Accountability Database (RAD)'} },
      { path: '/paperworkReductionActNotice', name: 'paperworkReductionActNotice',
        component: PaperworkReductionActNotice, meta: {title: 'Paperwork Reduction Act Notice - Representative Accountability Database (RAD)'} },
      { path: '/privacyStatement', name: 'privacyStatement', component: PrivacyStatement, meta: {title: 'Privacy Statement - Representative Accountability Database (RAD)'} },
      { path: '/verify', name: 'verify', component: Status , meta: {title: 'Status - Representative Accountability Database (RAD)'}  },
      { path: '/verifyAnnualAgreement', name: 'verifyAnnualAgreement', component: Status , meta: {title: 'Status - Representative Accountability Database (RAD)'}  },
      { path: '/verifyUpdateInformation', name: 'verifyUpdateInformation', component: Status , meta: {title: 'Status - Representative Accountability Database (RAD)'}  },
      { path: '/registerRepresentative', name: 'registration', component: Registration, meta: {title: 'Registration Form - Representative Accountability Database (RAD)'}  },
      { path: '/reviewRegisterRepresentative', name: 'reviewRegistration', component: ReviewRegistration,
        meta: {title: 'Review Registration Information - Representative Accountability Database (RAD)'} },
      { path: '/searchRepresentative', name: 'searchRepresentative', component: SearchRepresentative,
        meta: {title: 'Search Representatives - Representative Accountability Database (RAD)'} },
      { path: '/*', redirect: '/Landing', meta: { title: ''} },

    ]
  });

router.beforeEach((to, from, next) => {
  //reset email to make sure it doesnt show up on pages its not supposed to
  EventBus.$emit('email-updated', '' );

  document.title = to.meta.title;
  next();
});
export default router;


