<template>
  <div>
    <i-long-form-text>
      <h1 tabindex="0">Representative Registration</h1>
      <br>
      <i-notification v-if="this.error" variant="error">{{this.error}}</i-notification>
      <p class="indi-long-form-text__p--small" tabindex="0" label-for="First Name">The information below will be used to validate your identity.
      </p>
    </i-long-form-text>
    <form @submit.prevent="validateBeforeSubmit">
      <i-fieldset>
        <i-label text="Full Legal Name" tabindex="0"/>
        <br>
        <p class="indi-long-form-text__p--small" tabindex="0">The name you use on official documents, like your Social Security Card or State ID. Not a nickname.</p>
        <br>
        <i-grid>
          <i-input-group label="First Name" label-for="First Name" class="indi-col-lg-6"><i-input id="firstName" name="firstName" type="text" v-model="fNameInput" aria-label="First Name"  maxlength="50"
                     :invalid="errors.has('firstName')" v-validate="{ required: true, regex:  /^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s'`-]*$/,min :2,max:50 }"/>
            <i-input-notification v-bind:text="errors.first('firstName')" aria-live="assertive" aria-atomic="true"/>
          </i-input-group>
          <i-input-group class="indi-col-lg-6">
            <i-label text="Middle Name" optional aria-live="assertive" aria-atomic="true" />
            <i-input id="middleName" name="middleName" type="text" v-model="mNameInput" aria-label="Middle Name" maxlength="50"
                     :invalid="errors.has('middleName')" v-validate="{regex:  /^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.'`-s]*$/,max:50 }"/>
            <i-input-notification v-bind:text="errors.first('middleName')" aria-live="assertive" aria-atomic="true"/>
          </i-input-group>
        </i-grid>
        <i-grid>
          <i-input-group label="Last Name" label-for="Last Name" class="indi-col-lg-6">
            <i-input id="lastName" name="lastName" type="text" v-model="lNameInput" aria-label="Last Name" maxlength="50"
                     :invalid="errors.has('lastName')" v-validate="{ required: true, regex:  /^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s'`-]*$/,min :2,max:50 }"  tabindex="0"/>
            <i-input-notification v-bind:text="errors.first('lastName')"  aria-live="assertive" aria-atomic="true"/>
          </i-input-group>
          <i-input-group class="indi-col-lg-6">
            <i-label text="Suffix" optional tabindex="0"/>
            <i-input id="suffix" name="suffix" type="text" v-model="suffixInput" aria-label="Suffix" maxlength="3"
                     :invalid="errors.has('suffix')" v-validate="{regex:  /^[a-zA-Z.]*$/ ,max:3}" />
            <i-input-notification v-bind:text="errors.first('suffix')"  aria-live="assertive" aria-atomic="true"/>
          </i-input-group>
        </i-grid>
        <!-- Address -->
        <i-label text="Address"  tabindex="0"/>
        <br>
        <p class="indi-long-form-text__p--small" tabindex="0">Please provide your residential or business address.</p>
        <br>
        <i-grid>
          <i-input-group label="Street Address" label-for="Street Address" class="indi-col-lg-8">
            <i-input id="address1" name="address1" type="text" v-model="streetInput" aria-label="Street Address"
                   :invalid="errors.has('address1')" v-validate="{required: true,max:50 }" maxlength="50" />
            <i-input-notification v-bind:text="errors.first('address1')"  aria-live="assertive" aria-atomic="true"/>
          </i-input-group>
          <i-input-group class="indi-col-lg-4">
            <i-label text="Apt., Unit, etc." label-for="Appartment number, Unit, etc." optional tabindex="0"/>
            <i-input id="address2" name="address2" type="text" v-model="aptUnitInput" aria-label="Appartment number, Unit, etc."
                   :invalid="errors.has('address2')" v-validate="{max:50 }" maxlength="50"/>
            <i-input-notification v-bind:text="errors.first('address2')"  aria-live="assertive" aria-atomic="true"/>
          </i-input-group>
        </i-grid>
        <i-grid>
          <i-input-group label="City" label-for="City" class="indi-col-lg-4">
            <i-input id="city" name="city" type="text" v-model="cityInput" aria-label="City"
                     :invalid="errors.has('city')" v-validate="{ required: true,max:50}" maxlength="50"/>
            <i-input-notification v-bind:text="errors.first('city')" aria-live="assertive" aria-atomic="true"/>
          </i-input-group>
          <i-input-group label="State" label-for="State" class="indi-col-lg-4" required>
            <i-select v-bind:options="states" v-on:input="onChange" v-model="stateSelect" id="state" name="state" aria-label="State" v-validate="'required'" :invalid="errors.has('state')"/>
            <i-input-notification v-bind:text="errors.first('state')"  aria-live="assertive" aria-atomic="true"/>
          </i-input-group>
          <!-- Zip Code -->
          <i-input-group label="Zip Code" label-for="Zip Code" class="indi-col-lg-4" required>
            <i-input id="zipCode" name="zipCode" type="text" v-model="zipCodeInput" aria-label="Zip Code"
                     :invalid="errors.has('zipCode')" v-validate="{required: true,regex:/^(\d{5}(-\d{4})?)$/}" v-mask="'#####-####'" />
            <i-input-notification v-bind:text="errors.first('zipCode')"  aria-live="assertive" aria-atomic="true"/>
          </i-input-group>
        </i-grid>
        <!-- Urbanization Code -->
        <i-grid v-if="isPrState">
          <i-input-group class="indi-col-lg-5" tabindex="0">
            <i-label text="Urbanization Code" label-for="Urbanization Code" optional/>
            <i-input id="urbanizationCode" name="urbanizationCode" optional type="text" v-model="urbanCodeInput" aria-label="Urbanization Code"
                     :invalid="errors.has('urbanizationCode')"  v-validate="{regex:  /^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.'`-s]*$/,max:50 }" maxlength="50" />
            <i-input-notification v-bind:text="errors.first('urbanizationCode')"  aria-live="assertive" aria-atomic="true"/>
          </i-input-group>
        </i-grid>
        <!-- Date of Birth -->
        <i-grid>
          <i-input-group label="Date of Birth" label-for="Date of Birth" class="indi-col-lg-4">
            <i-input id="dob" name="dob" type="text" v-model="dobInput"
                     v-validate="'required|validDate|notUnder18|notOver135'"
                     v-mask="'##/##/####'" aria-label="Date of Birth" :invalid="errors.has('dob')"/>
            <i-help-text text="MM/DD/YYYY" />
            <i-input-notification v-bind:text="errors.first('dob')"  aria-live="assertive" aria-atomic="true"/>
          </i-input-group>
        </i-grid>
        <!-- The last 4 digits of Social Security Number (SSN) -->
        <i-grid v-if="!isItState">
          <i-input-group class="indi-col-lg-8" style="margin-bottom: 0px;">
            <i-label text="Last 4 digits of Social Security Number (SSN)" label-for="Last 4 digits of Social Security Number (SSN)" tabindex="0" :optional="ssn4Optional">
              <i-tooltip slot="annotation" tabindex="0" v-show="ssn4Optional" text="If you don't provide the last four digits of your Social Security Number, you will be required to submit documentation to prove your identity,
                       which will delay your approval. You may also still be asked to provide this information at a later time"
                       aria-label="If you don't provide the last four digits of your Social Security Number, you will be required to submit documentation to prove your identity,
                       which will delay your approval. You may also still be asked to provide this information at a later time"/>
            </i-label>
          </i-input-group>
        </i-grid>
        <i-grid  v-if="!isItState">
          <i-input-group class="indi-col-lg-2">
             <i-input id="l4ssn" name="l4ssn" type="text" v-model="l4ssnInput" aria-label="The last 4 digits of Social Security Number (SSN)"
                     :invalid="errors.has('l4ssn')" v-on:input="onSSNEmpty" v-validate="{required: this.l4ssnRequire,regex:  /^(?!0000)\d{4}?$/}" v-mask="'####'"/>
            <div v-if="this.dupeError">
              <i-input-notification v-bind:text="'Missing required field.'"  aria-live="assertive" aria-atomic="true"/> </div>
            <div v-else>
            <i-input-notification v-bind:text="errors.first('l4ssn')"  aria-live="assertive" aria-atomic="true"/> </div>
          </i-input-group>
        </i-grid>
        <!-- Email -->
        <i-grid >
          <i-input-group label-for="Email" class="indi-col-lg-7" tabindex="0">
            <slot class="indi-long-form-text__p">
              <i-long-form-text><h5 class="indi-long-form-text__p">Email : {{this.email}} </h5></i-long-form-text>
            </slot>
          </i-input-group>
        </i-grid>
      </i-fieldset>
      <i-fieldset>
        <p class="indi-long-form-text__p--small" tabindex="0">Help us secure your information. Select three security questions to which only you know the answers.
          Make sure that you can remember your answers. These questions will help you retrieve your Representative ID if you forget it.</p>
        <br>
        <i-input-group label="Security Question 1" label-for="Security Question 1" class="indi-col-lg-4">
          <i-select v-bind:options="questions1" :invalid="errors.has('securityQ1')" id="securityQ1" name="securityQ1" v-model="securityQ1Select" v-validate="'required'"
                    v-on:input="onQuestionChange()" aria-label="Security Question 1" />
          <i-input-notification v-bind:text="errors.first('securityQ1')" aria-live="assertive" aria-atomic="true"/>
        </i-input-group>
        <i-input-group label="Your Answer to Security Question 1" label-for="Your Answer to Security Question 1" class="indi-col-lg-4">
          <i-input ype="text" id="securityA1" name="securityA1" v-model="securityA1Input" v-validate="{required:true,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}"
                   aria-label="Your Answer to Security Question 1" :invalid="errors.has('securityA1')" />
          <i-input-notification v-bind:text="errors.first('securityA1')" aria-live="assertive" aria-atomic="true"/>
        </i-input-group>
        <i-input-group label="Security Question 2" label-for="Security Question 2" class="indi-col-lg-4">
          <i-select v-bind:options="questions2" :invalid="errors.has('securityQ2')" id="securityQ2" name="securityQ2" v-model="securityQ2Select" v-validate="'required'"
                    v-on:input="onQuestionChange()" aria-label="Security Question 2" />
          <i-input-notification v-bind:text="errors.first('securityQ2')" aria-live="assertive" aria-atomic="true"/>
        </i-input-group>
        <i-input-group label="Your Answer to Security Question 2" label-for="Your Answer to Security Question 2" class="indi-col-lg-4" >
          <i-input ype="text" id="securityA2" name="securityA2" v-model="securityA2Input" v-validate="{required:true,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}"
                   aria-label="Your Answer to Security Question 2" :invalid="errors.has('securityA2')" />
          <i-input-notification v-bind:text="errors.first('securityA2')" aria-live="assertive" aria-atomic="true"/>
        </i-input-group>
        <i-input-group label="Security Question 3" label-for="Security Question 3" class="indi-col-lg-4">
          <i-select v-bind:options="questions3" :invalid="errors.has('securityQ3')" id="securityQ3" name="securityQ3" v-model="securityQ3Select" v-validate="'required'"
                    v-on:input="onQuestionChange()" aria-label="Security Question 3" />
          <i-input-notification v-bind:text="errors.first('securityQ3')" aria-live="assertive" aria-atomic="true"/>
        </i-input-group>
        <i-input-group label="Your Answer to Security Question 3" label-for="Your Answer to Security Question 3" class="indi-col-lg-4">
          <i-input ype="text" id="securityA3" name="securityA3" v-model="securityA3Input" v-validate="{required:true,regex:  /^[0-9a-zA-ZáéíñóúüÁÉÍÑÓÚÜ\s.:'`-]*$/,max:70}"
                   aria-label="Your Answer to Security Question 3" :invalid="errors.has('securityA3')" />
          <i-input-notification v-bind:text="errors.first('securityA3')" aria-live="assertive" aria-atomic="true"/>
        </i-input-group>

        <i-input-group notification-text="Please read and acknowledge the above statement by clicking the checkbox.">
          <i-checkbox v-bind:invalid="validCertFlag" name="certFlag" id="certFlag" v-model="certFlag" v-on:change="togglecertFlag"
                      label="By checking this box you agree that all of the information you are providing may be collected, used, shared, and retained by USAC for the purposes of applying for and receiving a Representative ID." />
        </i-input-group>
        <i-input-group notification-text="Please read and acknowledge the above statement by clicking the checkbox." >
          <i-checkbox v-bind:invalid="validCertFlag2" name="certFlag2" id="certFlag2" v-model="certFlag2" v-on:change="togglecertFlag2"
                      label="I certify, under penalty of perjury, that I am providing my own information to apply for a Representative ID and that all requested identification information has been provided and is accurate. I know that I must comply with all rules and regulations for the federal Lifeline program. I know that willingly giving false information or engaging in fraudulent behavior to qualify or enroll individuals in the Lifeline Program is punishable by law, including imprisonment. I understand that, once received, a Representative Identification number is issued for a specific individual and is not transferable." />
          <i-input-notification v-if="validCertFlag || validCertFlag2" text="You must acknowledge both agreements." aria-live="assertive" aria-atomic="true"/>
        </i-input-group>
        <i-grid>
          <i-button class="indi-col-xs-offset-10 right-side-button" label="Next"  variant="primary" @click="certifyAnnualAgreement"/>
        </i-grid>
      </i-fieldset>
    </form>

    <i-modal header="Social Security Number Not Provided" ref="modal" aria-label="Close Modal"
             v-on:on-primary-button-click="closeModal" v-on:on-tertiary-button-click="submitWithoutSSN">
      <slot>
        <i-long-form-text>
          <p class="indi-long-form-text__p--small" tabindex="0">
            If you don't provide the last four digits of your Social Security Number (SSN), you will be required to submit documentation to prove your identity, which will delay your approval.
            You may also still be asked to provide this information at a later time.
          </p>
        </i-long-form-text>
        <i-grid>
          <i-button class="indi-col-xs-8" label="Don't Add SSN" style="padding-left: 225px; padding-top: 20px;"
                    variant="tertiary" @click="submitWithoutSSN"/>
          <i-button class="indi-col-xs-3" label="Add SSN"  variant="primary" @click="closeModal"/>
        </i-grid>
      </slot>
    </i-modal>
    <i-loader variant="overlay" v-if="loading"/>
  </div>
</template>

<script>
  import {
    ILongFormText, IFieldset, IInputGroup,IInput,IGrid,IModal, ILabel,
    IButton,ITooltip,IInputNotification, IHelpText, ISelect, ILoader, ICheckbox, INotification } from 'indigo-component-library';
  import {HTTP} from '../http-common'
  import router from '../router'
  import {mask} from 'vue-the-mask';

  export default {
    name: "Registration",
    components: {
      ILongFormText, IFieldset, IInputGroup, IInput, IGrid, IModal, ILoader,
      IButton, IInputNotification, ITooltip, IHelpText, ILabel, ISelect, ICheckbox, INotification
    },
    data: function () {
      return {
        isPrState: false,
        isItState: false,
        states: [],
        stateSelect: null,
        fNameInput: '',
        mNameInput: '',
        lNameInput: '',
        suffixInput: '',
        streetInput: '',
        aptUnitInput: '',
        cityInput: '',
        zipCodeInput: '',
        urbanCodeInput: '',
        dobInput: '',
        l4ssnInput: '',
        email: '',
        certFlag: false,
        certFlag2: false,
        loading: false,
        ssn4Optional: false,
        validCertFlag2:false,
        validCertFlag:false,
        error: '',
        dupeError:false,
        l4ssnRequire:false,
        allQuestions:[],
        questions1: [],
        questions2: [],
        questions3: [],
        selectedQuestions: {
          1: '',
          2: '',
          3: ''
        },
        securityQ1Select: '',
        securityQ2Select: '',
        securityQ3Select: '',
        securityQ1: '',
        securityQ2:'',
        securityQ3:'',
        securityA1: '',
        securityA2:'',
        securityA3:'',
        securityA1Input: '',
        securityA2Input:'',
        securityA3Input:''
      }
    },
    beforeMount: function () {
      this.email = this.$route.params.email;
      if (this.email === undefined || this.email === "") {
        this.loading = true;
        HTTP.get('/rad-svc/representative/verify', {
            params: {
              rc: this.$router.currentRoute.query.rc,
              re: this.$router.currentRoute.query.re
            }
          }
        ).then(response => {
          this.loading = false
          if (response.data.pass === false) {
            router.push({name: "verify", query: {re: response.data.re, rc: response.data.rc}});
          } else {
            this.email = response.data.email;
          }
        }).catch(err => {
          this.loading = false;
            console.log('Got Error while : '+window.location.href);
            router.push({name: "error"});
        });
      }

      HTTP.get('/rad-svc/props'
        ).then(response => {
            if(response.data.ssn4Optional === '1'){
                this.ssn4Optional = true;
            }
        }).catch(err => {
            router.push({name: "error"});
        });

    },
    mounted() {
      //this.showRepForm = true;
        HTTP.get('/rad-svc/representative/states').then(response => {
            //this.states.push( response.data)
            this.states = Object.assign(response.data, this.states)
            this.states.unshift({id:"", name: "", label: ""})
        })
        HTTP.get('/rad-svc/representative/securityQuestion').then(response => {
            this.allQuestions = Object.assign(response.data, this.allQuestions);
            this.allQuestions.unshift({id: "-1", question: "", label: "Select one", value:""})
            this.questions1 = this.allQuestions;
            this.questions2 = this.allQuestions;
            this.questions3 = this.allQuestions;
        })
      let rc = this.$router.currentRoute.query.rc
      if (sessionStorage.getItem(rc)) {
        let data = JSON.parse(sessionStorage.getItem(rc))
        if (data.firstName) {
          this.fNameInput = data.firstName
        }
        if (data.middleName) {
          this.mNameInput = data.middleName
        }
        if (data.lastName) {
          this.lNameInput = data.lastName
        }
        if (data.suffix) {
          this.suffixInput = data.suffix
        }
        if (data.address1) {
          this.streetInput = data.address1
        }
        if (data.address2) {
          this.aptUnitInput = data.address2
        }
        if (data.state) {
          this.stateSelect = data.state
        }
        if (data.city) {
          this.cityInput = data.city
        }
        if (data.zipCode) {
          this.zipCodeInput = data.zipCode
        }
        if (data.dob) {
          this.dobInput = data.dob
        }
        if( data.urbanCode) {
          this.urbanCodeInput = data.urbanCode
        }
        if (data.l4ssn) {
          this.l4ssnInput = data.l4ssn
        }
        if (data.question_answer[0]) {
          this.securityQ1Select = data.question_answer[0].qid
          this.securityA1Input = data.question_answer[0].answer
          //this.addSelectedQuestion(data.question_answer[0].qid, 1)
        }
        if (data.question_answer[1]) {
          this.securityQ2Select = data.question_answer[1].qid
          this.securityA2Input = data.question_answer[1].answer
          //this.addSelectedQuestion(data.question_answer[1].qid, 2)
        }
        if (data.question_answer[2]) {
          this.securityQ3Select = data.question_answer[2].qid
          this.securityA3Input = data.question_answer[2].answer
          //this.addSelectedQuestion(data.question_answer[2].qid, 3)
        }
      }
      let errorCode = sessionStorage.getItem('errorCode');
      if( errorCode === "EMPTY_L4SSN") {
          this.dupeError = true;
          this.error = 'Your information matched an existing representative in our records. In order to continue with registration, ' +
                  'you must re-submit your form with the last 4 digits of your SSN. If you already have a Representative ID, please leave this form and visit the Forgot Representative ID page to retrieve your Representative ID.';
      }
      if( errorCode === "MULTIPLE_REPID") {
          this.error = 'MultipleRepId: A Rep ID is already linked to this account.' ;
      }
      sessionStorage.clear();
      this.$gtm.trackView('Registration');
    },
    directives: {mask},
    methods: {
      closeModal() {
        this.$refs.modal.toggleOpen();
      },
      submitWithoutSSN: function () {
        router.push({
          name: "certifyAnnualAgreement",
          query: {rc: this.$router.currentRoute.query.rc, re: this.$router.currentRoute.query.re},
          params: { registration : true}
        });
      },
      togglecertFlag(){
        this.validCertFlag = this.certFlag ? false : true;
      },
      togglecertFlag2(){
        this.validCertFlag2 = this.certFlag2 ? false : true;
      },
     /*shouldDisplay(questionID,index1,index2){
        return !(String(this.selectedQuestions[index1]).trim() === String(questionID).trim()|| String(this.selectedQuestions[index2]).trim() === String(questionID).trim())
      },
      addSelectedQuestion(securityQSelected,index){
        this.selectedQuestions[index]= String(securityQSelected).trim();
      },*/
      onQuestionChange() {
        this.questions1 = this.allQuestions.filter( question => question.id != this.securityQ2Select);
        this.questions1 = this.questions1.filter( question => question.id != this.securityQ3Select);

        this.questions2 = this.allQuestions.filter( question => question.id != this.securityQ1Select);
        this.questions2 = this.questions2.filter( question => question.id != this.securityQ3Select);

        this.questions3 = this.allQuestions.filter( question => question.id != this.securityQ2Select);
        this.questions3 = this.questions3.filter( question => question.id != this.securityQ1Select);
      },
      onSSNEmpty() {
          if( (this.l4ssnInput === '' && !this.ssn4Optional) || this.error != '') {
              this.l4ssnRequire = true;
          } else {
              this.dupeError = false;
          }
      },
      closeModal : function() {
        this.$refs.modal.toggleOpen();
      },
      onChange(val) {
        this.isPrState = (this.stateSelect === 'PR');
        this.isItState = (this.stateSelect === 'IT')
      },
      validateBeforeSubmit() {
        this.$validator.errors.clear();

        this.validCertFlag = this.certFlag ? false : true;
        this.validCertFlag2 = this.certFlag2 ? false : true;
        this.$validator.validateAll().then((result) => {
            if (result && !this.validCertFlag2 && !this.validCertFlag) {
                let params = {
                    rc: this.$router.currentRoute.query.rc,
                    re: this.$router.currentRoute.query.re,
                    firstName: this.fNameInput,
                    lastName: this.lNameInput,
                    middleName: this.mNameInput,
                    suffix: this.suffixInput,
                    address1: this.streetInput,
                    address2: this.aptUnitInput,
                    city: this.cityInput,
                    state: this.stateSelect,
                    zipCode: this.zipCodeInput,
                    urbanizationCode: this.urbanCodeInput,
                    dob: this.dobInput,
                    l4ssn: this.l4ssnInput,
                    email: this.email,
                    certFlag: this.certFlag,
                    certFlag2: this.certFlag2,
                    question_answer: [
                        {
                            qnum: 1,
                            qid: this.securityQ1Select,
                            answer: this.securityA1Input
                        },
                        {
                            qnum: 2,
                            qid: this.securityQ2Select,
                            answer: this.securityA2Input
                        },
                        {
                            qnum: 3,
                            qid: this.securityQ3Select,
                            answer: this.securityA3Input
                        }]
                }
                let rc = this.$router.currentRoute.query.rc;
                sessionStorage.setItem(rc, JSON.stringify(params));
                sessionStorage.allQuestions = JSON.stringify(this.allQuestions);
                if( this.l4ssnInput === '' && this.stateSelect !== 'IT') {
                  this.$refs.modal.toggleOpen();
                } else {
                    router.push({
                        name: "certifyAnnualAgreement",
                        query: {rc: this.$router.currentRoute.query.rc, re: this.$router.currentRoute.query.re},
                        params: { registration : true}
                    });
                }
            }
        });
      },
      certifyAnnualAgreement() {
        this.$gtm.trackEvent({category: 'CertifyAnnualAgreement', action: 'click', label: 'Registration Form Certify Annual Agreement'});
        this.validateBeforeSubmit();
      }
    },
  }
</script>
<style>
  .indi-form__label-optional-indicator{
   color:#717171 !important;
  }
</style>
