<template>
  <div id="app" >
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>
<script>
  const default_layout = "default";
  export default {
    name: 'App',
    data: function () {
      return {
      }
    },
    computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + "-layout";
      }
    },
  }
</script>
